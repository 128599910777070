import styled, { useTheme } from 'styled-components'

import { Type } from 'bl-common/src/elements/Typography/Typography'
import { Checkbox } from 'bl-common/src/form/Checkbox/Checkbox'
import { ErrorMessage } from 'bl-common/src/units/Form/styles'

import type {
  FlowCheckboxField,
  FlowInputComponent,
  FlowValue,
} from '../../types'
import { getFlowValue } from '../../utils'

type CheckboxFieldProps = FlowInputComponent &
  FlowCheckboxField['props'] & {
    error?: FlowValue
  }

const Label = styled.label({
  flexBasis: '100%',
  cursor: 'pointer',
  display: 'flex',
  userSelect: 'none',
  alignItems: 'center',
})

const CheckboxWrapper = styled.div({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
})

const ErrorWrapper = styled.div({
  marginBottom: 12,
})

export const CheckBoxField = ({
  screenTheme,
  control,
  labelPreset = 'text',
  ...props
}: CheckboxFieldProps) => {
  const theme = useTheme()
  const themeStyle = theme?.bookingEngine?.[screenTheme]?.checkboxField
  const errorColor = theme?.bookingEngine?.[screenTheme]?.errorMessage?.color

  return (
    <>
      {!!props.error && !control.screen.state[props.id] && (
        <ErrorWrapper>
          <ErrorMessage color={errorColor} style={{ textAlign: 'left' }}>
            {props?.error}
          </ErrorMessage>
        </ErrorWrapper>
      )}
      <CheckboxWrapper>
        <Label>
          <Checkbox
            id={props.id}
            name={props.id}
            checked={control.screen.state[props.id] || ''}
            error={!!props.error}
            onChange={event => {
              // TODO: fix type of onChange to send correct event through
              // eslint-disable-next-line
              // @ts-ignore
              const value = event.target.checked
              control.screen.setState({
                [props.id]: value,
              })

              props.onChange?.(value, control)
            }}
            fillWidth={false}
            labelVerticallyCentered={true}
            color={themeStyle?.checkbox?.color}
          />
          {!!props.label && (
            <Type
              as="span"
              preset={labelPreset}
              dangerouslySetInnerHTML={{
                __html: `${getFlowValue(props.label, control)}${
                  props.required ? '<span>*</span>' : ''
                }`,
              }}
            />
          )}
        </Label>
      </CheckboxWrapper>
    </>
  )
}
