import { useEffect, useMemo, useRef, useState } from 'react'
import ReactDOM from 'react-dom'
import { useRouter } from 'next/router'
import get from 'lodash/get'
import set from 'lodash/set'

import { sentryLogging } from 'sentry-utils/logging'
import { useBookingSessionContext } from 'bl-common/src/context/BookingSessionContext'
import ConfirmActionModal, {
  type ConfirmActionModalProps,
} from 'bl-common/src/elements/ConfirmActionModal/ConfirmActionModal'
import { Spinner } from 'bl-common/src/elements/Spinner'
import { SpinnerWrapper } from 'bl-common/src/elements/SpinnerWrapper'
import type { UrlStateSchema } from 'flow-builder/src/utils/urlState'

import { ErrorBoundary } from '../components/ErrorBoundary'
import { ButtonField, TextField } from '../fields'
import { useStateRef } from '../hooks/useStateRef'
import type {
  ConfirmModalOptions,
  FlowControl,
  FlowRoot,
  GoToScreenSettings,
  ScreenUiState,
} from '../types'
import { getFlowValue } from '../utils'
import {
  findNextAvailableScreen,
  getFieldsWithDefaultValue,
  getInitialScreenIndex,
  getNavigation,
  getScreenFields,
  getValidators,
  validateFlowState,
  validateScreenState,
} from './FlowRenderer-utils'
import { HookRenderer } from './HookRenderer'
import { ScreenRenderer } from './ScreenRenderer'

interface FlowRendererProps {
  flow: FlowRoot
  context: FlowControl['context']
  parentFlowControl?: FlowControl
  onNavigation?: (
    route: string,
    shouldReplace: boolean,
    screenId: string,
    queryParams?: Record<string, string>
  ) => void
  onQueryParamsChange?: (
    query: Record<string, string>,
    currentScreenId: string
  ) => void
  onUncaughtError?: (error: Error) => void
  uncaughtErrorContent?: {
    message: string
    title: string
    confirmLabel: string
  }
  currentScreenPath?: string
  stateFromUrlSchema?: UrlStateSchema
  onClearSessionStorage?: () => void
  initialFlowState?: Record<string, any>
  confirmModalStyle?: Partial<ConfirmActionModalProps>
}

interface ConfirmModalSettings extends Partial<ConfirmModalOptions> {
  message?: string
  resolve?: () => void
  reject?: () => void
}

const GO_TO_SCREEN_DEFAULT_SETTINGS: GoToScreenSettings = {
  resetErrorState: true,
  saveState: false,
}

export const FlowRenderer: React.FC<FlowRendererProps> = ({
  stateFromUrlSchema,
  flow,
  parentFlowControl,
  onNavigation,
  onQueryParamsChange,
  onUncaughtError,
  uncaughtErrorContent,
  currentScreenPath,
  context,
  initialFlowState = {},
  confirmModalStyle,
}) => {
  const router = useRouter()
  const navigation = useMemo(
    () => getNavigation(flow.children, context),
    [flow]
  )
  const validators = useMemo(() => getValidators(navigation), [navigation])
  const { sessionId } = useBookingSessionContext()

  const storageKeyFlow = `flow_${sessionId}_${flow.id}`

  const [currentScreenIndex, setCurrentScreenIndex] = useState(0)

  // State for the whole flow
  const [flowState, flowStateRef, setFlowState] =
    useStateRef<Record<string, any>>(initialFlowState)

  // State for a singular screen that is active
  const [screenState, screenStateRef, setScreenState] = useStateRef<
    Record<string, any>
  >({})

  const [flowStatus, flowStatusRef, setFlowStatus] = useStateRef<string>({
    status: 'initial',
  })

  const [screenUiState, screenUiStateRef, setScreenUiState] =
    useStateRef<ScreenUiState>({})

  const [showConfirmModal, setShowConfirmModal] = useState(false)
  const confirmModalSettings = useRef<ConfirmModalSettings>({
    message: null,
    resolve: null,
    reject: null,
    title: null,
    confirmLabel: null,
    cancelLabel: null,
  })

  const [screenErrorState, screenErrorStateRef, setScreenErrorState] =
    useStateRef<string>({})
  const [flowLoadingError, setFlowLoadingError] = useState<string | null>(null)

  const updateStateBeforeTransition = (fromIndex: number) => {
    const currentScreen = navigation.screenLookup[fromIndex]

    if (!currentScreen) {
      return
    }

    const savedScreenState = JSON.parse(JSON.stringify(screenStateRef.current))

    validateAndSetFlowState({
      [currentScreen.id]: savedScreenState,
    })
  }

  const parseUrlState = schema => {
    return Object.keys(schema).reduce((acc, key) => {
      const value = router?.query[key]

      if (!value) {
        return acc
      }

      const parsedValue = schema[key].parse(value)

      return {
        ...acc,
        [key]: parsedValue,
      }
    }, {})
  }

  const initialiseNextScreenState = (
    toIndex: number,
    resetErrorState = true,
    initialState?: Record<string, any>
  ) => {
    const nextScreen = navigation.screenLookup[toIndex]
    if (!nextScreen) {
      return
    }

    const initialScreenStateForNextScreen =
      initialState || flowStateRef.current[nextScreen.id] || {}

    setScreenState(initialScreenStateForNextScreen, true)

    const nextUiState: ScreenUiState = {}

    if (nextScreen.uiState) {
      const keys = Object.keys(nextScreen.uiState)

      for (const key of keys) {
        const value = nextScreen.uiState[key]
        nextUiState[key] =
          typeof value === 'function' ? getFlowValue(value, flowControl) : value
      }
    }

    setScreenUiState(nextUiState, true)
    if (resetErrorState) {
      setScreenErrorState({}, true)
    }
  }

  const validateAndSetFlowState: FlowControl['flow']['setState'] = (
    stateToUpdate,
    overwrite = false
  ) => {
    const { hasErrors } = validateFlowState(stateToUpdate, flowControl)

    if (hasErrors) {
      return false
    }

    setFlowState(stateToUpdate, overwrite)
  }

  const flowControl: FlowControl = useMemo(() => {
    const validateAndSetScreenErrors = (
      screenId: string,
      onlyDirty?: boolean
    ) => {
      const { errors, hasErrors } = validateScreenState(
        screenId,
        flowControl.screen.stateRef.current,
        flowControl,
        onlyDirty
      )

      if (hasErrors) {
        setScreenErrorState(errors, true)

        try {
          const firstErrorFieldId = Object.keys(errors)?.[0]

          if (firstErrorFieldId) {
            const element = document.getElementById(firstErrorFieldId)
            element?.scrollIntoView({
              behavior: 'smooth',
              block: 'center',
              inline: 'nearest',
            })
          }
        } catch {
          // pass
        }
      } else {
        setScreenErrorState({}, true)
      }

      return { hasErrors }
    }

    const nextScreen: FlowControl['nextScreen'] = (): boolean => {
      const { hasErrors } = validateAndSetScreenErrors(currentScreen.id)

      if (hasErrors) {
        return false
      }

      updateStateBeforeTransition(currentScreenIndex)

      const nextScreenIndex = findNextAvailableScreen(
        flowControl,
        currentScreenIndex + 1,
        'next'
      )

      if (nextScreenIndex === null) {
        return false
      }

      // https://reactjs.org/blog/2022/03/29/react-v18.html#new-feature-automatic-batching
      // This will not be neccessary after updating to React 18
      // But at the moment this will trigger two renders when
      // nextScreen is called from a promise, like when waiting for a modal
      ReactDOM.unstable_batchedUpdates(() => {
        initialiseNextScreenState(nextScreenIndex)
        setCurrentScreenIndex(nextScreenIndex)
      })

      return true
    }

    const previousScreen: FlowControl['previousScreen'] = () => {
      updateStateBeforeTransition(currentScreenIndex)

      const nextScreenIndex = findNextAvailableScreen(
        flowControl,
        currentScreenIndex - 1,
        'previous'
      )

      if (nextScreenIndex === null) {
        return
      }

      initialiseNextScreenState(nextScreenIndex)
      setCurrentScreenIndex(nextScreenIndex)
    }

    const goToScreen: FlowControl['goToScreen'] = (
      toIndex,
      settings = GO_TO_SCREEN_DEFAULT_SETTINGS
    ) => {
      if (!navigation.screens[toIndex]) {
        return
      }

      const nextScreen = navigation.screens[toIndex]

      if (
        typeof nextScreen.condition === 'function' &&
        nextScreen.condition(flowControl) === false
      ) {
        return
      }

      // If the screen is the same as the current screen, do nothing
      // Otherwhise this would cause an issue where
      // a screen lost its state, like the packages screen
      const isSameScreen = nextScreen.id === currentScreen.id

      // In some cases we return when the state is empty, which is not good.
      // We also need to check if screenState has any properties before we return.
      if (isSameScreen && screenState && Object.keys(screenState).length > 0) {
        return
      }

      if (settings.saveState) {
        updateStateBeforeTransition(currentScreenIndex)
      }

      initialiseNextScreenState(toIndex, settings.resetErrorState)
      setCurrentScreenIndex(toIndex)
    }

    // Reset the flow, takes optional state to set the flow to, otherwise it will reset to the initial state
    const reset: FlowControl['reset'] = (state: Record<string, any> = {}) => {
      const initialState = { ...getInitialState(true), ...state }
      const firstScreen = flowControl.navigation.screenLookup[0]

      // https://reactjs.org/blog/2022/03/29/react-v18.html#new-feature-automatic-batching
      // This will not be neccessary after updating to React 18
      // But at the moment this will trigger two renders when
      // nextScreen is called from a promise, like when waiting for a modal
      ReactDOM.unstable_batchedUpdates(() => {
        setCurrentScreenIndex(getInitialScreenIndex(flowControl, 0))
        setFlowState(initialState, true)
        initialiseNextScreenState(0, true, initialState[firstScreen.id])
      })
    }

    const confirm: FlowControl['confirm'] = (message, options) => {
      const promise = new Promise<boolean>(resolve => {
        ReactDOM.unstable_batchedUpdates(() => {
          setShowConfirmModal(true)
          confirmModalSettings.current = {
            title: options?.title,
            message,
            resolve: () => {
              setShowConfirmModal(false)
              resolve(true)
            },
            reject: () => {
              setShowConfirmModal(false)
              resolve(false)
            },
            confirmLabel: options?.confirmLabel,
            cancelLabel: options?.cancelLabel,
          }
        })
      })

      return promise
    }

    const result: FlowControl = {
      id: flow.id,
      navigation,
      validators,
      breadcrumbs: new Map(),
      currentScreenIndex,
      nextScreen,
      validateAndSetScreenErrors: (onlyDirty?: false) =>
        validateAndSetScreenErrors(currentScreen.id, onlyDirty),
      previousScreen,
      goToScreen,
      reset,
      completeFlow: () => {
        setFlowStatus({ status: 'completed' })
      },
      flow: {
        isComplete: flowStatus.status === 'completed',
        state: flowState,
        stateRef: flowStateRef,
        setState: validateAndSetFlowState,
        setStateWithoutValidation: setFlowState,
      },
      screen: {
        state: screenState,
        stateRef: screenStateRef,
        setState: setScreenState,
        uiState: screenUiState,
        uiStateRef: screenUiStateRef,
        setUiState: setScreenUiState,
      },
      screenErrors: {
        state: screenErrorState,
        stateRef: screenErrorStateRef,
        setState: setScreenErrorState,
      },
      parentFlowControl,
      confirm,
      context,
    }

    navigation.screens.forEach((screen, index) => {
      if (
        screen.breadcrumb === false ||
        (typeof screen.condition === 'function' &&
          screen.condition(result) === false)
      ) {
        return
      }

      const { breadcrumb } = screen

      const { title, value } = getFlowValue(breadcrumb, result)

      // TODO: Also make sure to check if the existing breadcrumb
      // is next to this one.
      // Ref: https://app.asana.com/0/1205240384235169/1206149775247374
      if (!result.breadcrumbs.has(title)) {
        result.breadcrumbs.set(title, {
          title,
          value,
          routes: [index.toString()],
          isDisabled:
            navigation.screenIdToIndexLookup[screen.id] > currentScreenIndex,
        })
      } else {
        result.breadcrumbs.get(title)?.routes.push(index.toString())
      }
    })

    return result
  }, [
    navigation,
    currentScreenIndex,
    setCurrentScreenIndex,
    flowState,
    flowStateRef,
    flowStatus,
    setFlowState,
    screenState,
    screenStateRef,
    setScreenState,
    screenUiState,
    screenUiStateRef,
    setScreenUiState,
    parentFlowControl,
    setShowConfirmModal,
    context,
  ])

  const currentScreen = flowControl.navigation.screenLookup[currentScreenIndex]

  // Handle incoming route changes from parent
  useEffect(() => {
    if (
      !flow.routerSettings.updateHistory ||
      typeof onNavigation !== 'function'
    ) {
      return
    }
    const screen = navigation.routeToScreen[currentScreenPath]
    if (!screen) {
      return
    }
    const isSameScreen = screen.id === currentScreen.id

    if (isSameScreen) {
      return
    }
    const toIndex = navigation.screenIdToIndexLookup[screen.id]

    // https://reactjs.org/blog/2022/03/29/react-v18.html#new-feature-automatic-batching
    // This will not be neccessary after updating to React 18
    // But at the moment this will trigger two renders when
    // nextScreen is called from a promise, like when waiting for a modal
    ReactDOM.unstable_batchedUpdates(() => {
      updateStateBeforeTransition(currentScreenIndex)

      initialiseNextScreenState(toIndex)
      setCurrentScreenIndex(toIndex)
    })
  }, [currentScreenPath, navigation])

  const [hasDoneInitialValidation, setHasDoneInitialValidation] =
    useState(false)

  // Effect that updates the query params when the screen state changes.
  // Each screen can have a query function that returns which
  // query params it wants to show in the URL.
  useEffect(() => {
    if (
      currentScreen?.queryParams &&
      onQueryParamsChange &&
      hasDoneInitialValidation
    ) {
      onQueryParamsChange(
        currentScreen.queryParams(flowControl),
        currentScreen.id
      )
    }
  }, [flowControl?.screen])

  // Handle outgoing route changes to parent
  useEffect(() => {
    const screen = navigation.screenLookup[currentScreenIndex]
    if (!screen || !hasDoneInitialValidation) {
      return
    }

    if (flow.routerSettings.updateHistory) {
      if (typeof onNavigation === 'function') {
        // A route can have dynamic route params,
        // so we need to use getFlowValue to get the value
        onNavigation(
          getFlowValue(screen?.route, flowControl)[context.locale] ?? screen.id,
          false,
          screen.id,
          screen.queryParams?.(flowControl)
        )
      }
    }
  }, [currentScreenIndex, navigation, hasDoneInitialValidation])

  const getInitialState = (shouldResetState: boolean) => {
    const initialState = shouldResetState
      ? {}
      : flowControl.flow.stateRef.current

    let stateFromUrl = {}
    if (stateFromUrlSchema) {
      stateFromUrl = parseUrlState(stateFromUrlSchema)
    }
    if (initialFlowState) {
      stateFromUrl = {
        ...stateFromUrl,
        ...initialFlowState,
      }
    }

    navigation.screens.forEach(screen => {
      const fields = getScreenFields(screen)

      fields.forEach(field => {
        // this function spreads default values of the field,
        // so if one field has 3 default values, this will return 3 fields
        const fieldsWithDefaultValues = getFieldsWithDefaultValue(field)
        fieldsWithDefaultValues.forEach(({ id, defaultValue }) => {
          const existingValue = get(initialState, `${screen.id}.${id}`)
          const queryValue = get(stateFromUrl, `${id}`)
          const hasExistingValue =
            existingValue !== undefined && existingValue !== null
          const hasQueryValue = queryValue !== undefined && queryValue !== null

          // Query value should have priority over both default and existing values
          // TODO Add screen id to query key, now it assumes unique field ids
          if (hasQueryValue) {
            set(
              initialState,
              `${screen.id}.${id}`,
              getFlowValue(stateFromUrl[id], flowControl)
            )
          } else if (!hasExistingValue) {
            // TODO: setFlowState so getFlowValue has all available data
            set(
              initialState,
              `${screen.id}.${id}`,
              getFlowValue(defaultValue, flowControl)
            )
          }
        })
      })
    })

    return initialState
  }

  // Handle initial state
  useEffect(() => {
    setFlowLoadingError(null)

    const shouldResetState =
      flow?.settings?.resetWhenOpened === true ||
      flowStatusRef?.current.status === 'completed'

    setFlowStatus({ status: 'initial' })

    let initialScreenIndex = getInitialScreenIndex(
      flowControl,
      shouldResetState ? 0 : currentScreenIndex,
      router
    )

    if (initialScreenIndex === null) {
      setFlowLoadingError('Something went wrong during initialisation')
      onUncaughtError?.(new Error('Could not find initial screen'))
      return
    }

    const initialState = getInitialState(shouldResetState)

    setFlowState(initialState)

    let foundErrors = false

    for (let i = 0; i < initialScreenIndex; i += 1) {
      const screen = navigation.screens[i]

      const { hasErrors, errors } = validateScreenState(
        screen.id,
        initialState[screen.id],
        flowControl
      )

      if (hasErrors) {
        foundErrors = true

        flowControl.goToScreen(i, {
          ...GO_TO_SCREEN_DEFAULT_SETTINGS,
          resetErrorState: false,
        })
        initialScreenIndex = i
        flowControl.screenErrors.setState(errors, true)
        break
      }
    }

    if (!foundErrors) {
      initialiseNextScreenState(initialScreenIndex)

      if (initialScreenIndex !== currentScreenIndex) {
        setCurrentScreenIndex(initialScreenIndex)
      }
    }

    setHasDoneInitialValidation(true)
  }, [])

  flowControl.flow.setupHook = flow?.setupHook?.(flow, flowControl)

  const notifyError = async error => {
    onUncaughtError?.(error)

    sentryLogging({
      message: 'FlowRenderer uncaught error',
      extras: { error, flowId: flow.id, screenId: currentScreen.id },
      team: 'team-frontend-infrastructure',
    })

    // If I try to use context.t here I get a weird webpack module error,
    // hence the hardcoded and non translated default message
    await flowControl.confirm(
      uncaughtErrorContent?.message ||
        'Unfortunately an unknown error has occurred and we need to reset your booking. If this error persists, please contact us at contact@bluelagoon.com',
      {
        title: uncaughtErrorContent?.title || 'Unknown error',
        confirmLabel: uncaughtErrorContent?.confirmLabel || 'Reset booking',
      }
    )
    // A flow can define how to clear session storage.
    // In some cases we might want to preserve some data.
    if (flow?.settings?.onClearSessionStorage) {
      flow.settings.onClearSessionStorage(storageKeyFlow)
    } else {
      // Remove flow related data from session storage
      if (window?.sessionStorage) {
        Object.keys(window.sessionStorage)
          .filter(key => key.startsWith(storageKeyFlow))
          .forEach(key => window.sessionStorage.removeItem(key))
      }
    }
    // Remove the query params from the URL to reset the state properly.
    const currentURL = window.location.href

    if (currentURL) {
      window.location.href = currentURL.substring(0, currentURL.indexOf('?'))
    } else {
      window.location.reload()
    }
  }

  if (flowLoadingError) {
    return (
      <div style={{ width: '80%', margin: '24px auto' }}>
        <TextField
          control={flowControl}
          type="headingSmall"
          value={flowLoadingError}
        />
        <ButtonField
          onClick={() => {
            flowControl.reset()
            location.reload()
          }}
          control={flowControl}
          label="Reset booking"
        />
      </div>
    )
  }

  if (flowControl.flow.setupHook?.isLoading || !hasDoneInitialValidation) {
    return (
      <SpinnerWrapper>
        <Spinner shouldAnimate />
      </SpinnerWrapper>
    )
  }

  return (
    <>
      <ErrorBoundary notifyError={notifyError}>
        <HookRenderer flow={flow} control={flowControl} />
        <ScreenRenderer
          item={currentScreen}
          control={flowControl}
          key={currentScreenIndex}
        />
      </ErrorBoundary>
      <ConfirmActionModal
        show={showConfirmModal}
        onHide={confirmModalSettings.current?.reject}
        onConfirm={confirmModalSettings.current?.resolve}
        onCancel={confirmModalSettings.current?.reject}
        title={confirmModalSettings.current?.title}
        message={confirmModalSettings.current?.message}
        buttonLabel={confirmModalSettings.current?.confirmLabel}
        secondaryButtonLabel={confirmModalSettings.current?.cancelLabel}
        fullScreenOnMobile={false}
        {...confirmModalStyle}
      />
    </>
  )
}
