import {
  type CSSValue,
  type Presets,
  Type,
} from 'bl-common/src/elements/Typography/Typography'
import { formatHtmlText } from 'bl-utils/src/formatting/formatHtmlText'
import { replaceLineBreaks } from 'bl-utils/src/replaceLineBreaks'

type Props = {
  value: string
  type:
    | 'heading'
    | 'headingMedium'
    | 'headingSmall'
    | 'subheading'
    | 'paragraph'
    | 'label'
    | 'html'
    | 'textLarge'
    | 'text'
  as?: React.ElementType
  color?: string
  preset?: Presets
  textAlign?: 'left' | 'center' | 'right'
  maxWidth?: CSSValue<'maxWidth'>
  mask?: boolean
}

const typeConfig = {
  heading: { as: 'h1', preset: 'headlineLarge', weight: 'bold' },
  headingMedium: {
    as: 'h1',
    preset: 'headline',
    weight: 'bold',
  },
  subheading: {
    as: 'h2',
    preset: 'subtitle',
    weight: 'light',
    multiline: true,
  },
  headingSmall: { as: 'h3', preset: 'headlineSmall', weight: 'bold' },
  textLarge: { as: 'h3', preset: 'textLarge', weight: 'bold' },
  text: { as: 'p', preset: 'text' },
  label: { preset: 'label' },
  html: null, // Special case, handled separately
} as const

/**
 * Text component
 *
 * The `Text` component is a versatile text rendering element that supports various presets and styles.
 * It can render different HTML elements (e.g., `h3`, `p`) based on the provided props and presets.
 *
 * @param {Object} props - The properties object.
 * @param {string} [props.as] - The HTML element to render (e.g., 'h3', 'p').
 * @param {string} [props.preset] - An optional preset name that applies predefined font size and weight settings.
 * @param {string} [props.weight] - An optional font weight.
 * @param {string} [props.value] - The text content to be rendered.
 * @param {number} [props.maxWidth=660] - The maximum width of the text container.
 * @param {Object} [props.style] - Additional CSS styles to apply to the text container.
 * @returns {JSX.Element|null} The rendered `Text` component or null if no value is provided.
 */
export const Text = (props: Props) => {
  const value = props.value

  if (value === undefined) {
    return null
  }

  const title = replaceLineBreaks(value)

  const commonProps = {
    maxWidth: props.maxWidth ?? 660,
    style: { wordBreak: 'break-word', whiteSpace: 'pre-line' },
    color: props.color,
    textAlign: props.textAlign,
    ...(props.mask && { className: 'ph-no-capture' }),
  }

  if (props.type === 'html') {
    return (
      <Type
        {...commonProps}
        as={props.as}
        preset={props.preset}
        maxWidth={props.maxWidth}
        dangerouslySetInnerHTML={{
          __html: formatHtmlText(title, undefined, '_self'),
        }}
      />
    )
  } else if (typeConfig[props.type]) {
    const { as, preset, weight, multiline } = typeConfig[props.type]
    return (
      <Type
        {...commonProps}
        as={as}
        preset={props.preset || preset}
        weight={weight}
        multiline={multiline}
      >
        {title}
      </Type>
    )
  }

  // Fallback for types not explicitly handled
  return (
    <p
      style={{
        textAlign: props.textAlign || 'inherit',
      }}
    >
      {title}
    </p>
  )
}
