import { useContext, useEffect, useState } from 'react'

import { colors } from 'bl-common/src/constants/colors'
import { CurrencyContext } from 'bl-common/src/context/Currency/CurrencyProvider'
import { Spinner } from 'bl-common/src/elements/Spinner'
import { SpinnerWrapper } from 'bl-common/src/elements/SpinnerWrapper'
import { theme } from 'bl-common/src/styles/theme'
import {
  buildAccordionField,
  buildButton,
  buildCustomField,
  buildDisclaimerField,
  buildFlow,
  buildHeading,
  buildItineraryField,
  buildOverviewField,
  buildPickerField,
  buildProgressButton,
  buildScreenErrorField,
  buildScreenWithImageLayout,
  type BuildSubFlow,
  type FlowControl,
  type FlowImageLayout,
  type FlowItineraryField,
  type SubFlowActivity,
  type SubFlowBookingInfo,
} from 'bl-flows-core'
import { setDateTimeISOString } from 'bl-utils/src/date'
import { formatDateInUTC } from 'bl-utils/src/formatting/formatDate'
import { logActivityUnavailable } from 'bl-utils/src/sentryLogs'

import { MassageCards } from '../../components/MassageCards'
import { globalBookingMessages } from '../../messages'
import { getItemsForMassageOverview } from '../../utils'
import { triggerMultiSelectItemEvent } from '../../utils/analytics'
import { getBookingDates } from '../../utils/getBookingDates'
import { isBookingTooClose } from '../../utils/isBookingTooClose'
import { massageMessages } from './messages/massageMessages'
import type { MassageState } from './types'

const imageLayoutProps: FlowImageLayout['props'] = {
  layoutId: 'massage-image-layout',
  layoutImageSrc:
    'https://images.ctfassets.net/w65k7w0nsb8q/4ieKAxkE00wUMoAK6a2wmI/9e83b172964822a62931e7b6e0f76c76/hero-relaxing-massage_2800w.jpg',
}

// TODO - remove once we have a proper cart
interface CartItem {
  id: string
  type: string
}

export interface MassageFlowInitialData {
  bookingInfo: SubFlowBookingInfo
  itineraryItems: FlowItineraryField['props']['items']
  previousMassageActivity?: SubFlowActivity
  // Todo: remove this feature flag when we stop using it
  enableGuestName?: boolean
  isHBE?: boolean
  isRetreatSpaBooking?: boolean
  isSPA?: boolean
  isRetreatSpaFlow?: boolean
  isHotelBooking?: boolean
  isMassageExceedingGuests?: boolean
  allGuestsBusy: (time: any, productId: any) => boolean
  noMassagesAvailable?: boolean
}

const getTotalPrice = (control: FlowControl) => {
  if (control.flow.setupHook?.edit) {
    return 0
  }
  return control.flow.stateRef.current?.time.massages.reduce(
    (acc: number, massage: MassageState) => {
      const price = massage?.massageInfo?.price || 0
      return acc + price
    },
    0
  )
}

export const buildMassageFlow: BuildSubFlow<CartItem> = (
  onAddToCart,
  onClose,
  initialData?: MassageFlowInitialData
) =>
  buildFlow({
    id: 'massageFlow',
    settings: {
      resetWhenOpened: true,
    },
    routerSettings: {
      updateHistory: false,
    },

    setupHook: () => {
      const { exchangeRates } = useContext(CurrencyContext)

      if (initialData?.noMassagesAvailable) {
        logActivityUnavailable({
          activityType: 'in-water massage',
          spaDate: initialData?.bookingInfo?.entryDate,
          hotelDates: getBookingDates(initialData?.bookingInfo),
        })
      }

      return {
        bookingInfo: initialData?.bookingInfo,
        previousMassageActivity: initialData?.previousMassageActivity,
        isHBE: initialData?.isHBE,
        isRetreatSpaBooking:
          initialData?.isRetreatSpaBooking || initialData?.isRetreatSpaFlow,
        // Todo: remove this feature flag when we stop using it
        enableGuestName: initialData?.enableGuestName,
        noMassagesAvailable: initialData?.noMassagesAvailable,
        exchangeRates,
      }
    },
    children: [
      buildScreenWithImageLayout({
        id: 'about',
        subType: 'form',
        layoutProps: imageLayoutProps,
        columnStyle: true,
        theme: initialData?.isRetreatSpaFlow ? 'retreat' : 'default',
        breadcrumb: control => {
          return {
            title: control.context.t(massageMessages.info.aboutBreadcrumb),
          }
        },
        fields: {
          main: [
            ...buildHeading({
              title: control =>
                control.context.t(massageMessages.info.aboutTitle),
              subTitle: control =>
                control.context.t(massageMessages.info.aboutSubtitle),
              includeBreadcrumb: true,
              imageSrc: imageLayoutProps.layoutImageSrc,
              imageLayout: { display: { bmd: 'none' } },
            }),

            // disclaimer for when there are already massages for every guest in the cart
            buildDisclaimerField({
              condition: () => !!initialData?.isMassageExceedingGuests,
              props: {
                value: control =>
                  control.context.t(massageMessages.warnings.noMoreMassages, {
                    guests:
                      initialData?.bookingInfo?.adults +
                      initialData?.bookingInfo?.children,
                    massages:
                      initialData?.bookingInfo?.adults +
                      initialData?.bookingInfo?.children,
                  }),
                color: colors.errorRed,
              },
              layout: {
                spacing: { mb: { xs: 2 } },
              },
            }),

            // disclaimer for no availability
            buildDisclaimerField({
              condition: control =>
                !!control.flow.setupHook?.noMassagesAvailable,

              props: {
                value: control =>
                  control.context.t(
                    massageMessages.warnings.noAvailabilityDisclaimer
                  ),
                color: colors.errorRed,
              },
              layout: {
                spacing: { mb: { xs: 2 }, mt: { xs: 2, md: 0 } },
              },
            }),

            buildButton({
              condition: control => !control.flow.setupHook?.loading,
              props: {
                label: control =>
                  control.flow.setupHook?.noMassagesAvailable
                    ? control.context.t(
                        globalBookingMessages.buttons.closeWindow
                      )
                    : control.context.t(massageMessages.info.aboutChooseButton),
                isDisabled: () => initialData?.isMassageExceedingGuests,
                onClick: control =>
                  control.flow.setupHook?.noMassagesAvailable
                    ? onClose(control)
                    : control.nextScreen(),
              },
              layout: {
                marginTopAuto: { xs: true },
                width: { xs: `calc(100% + ${theme.spacing[2]})` },
                spacing: {
                  ml: { xs: -1 },
                  mr: { xs: -1 },
                },
              },
            }),
            // Build spinner
            buildCustomField({
              defaultValue: null,
              condition: control => !!control.flow.setupHook?.loading,
              props: {
                component: () => {
                  return (
                    <SpinnerWrapper>
                      <Spinner shouldAnimate />
                    </SpinnerWrapper>
                  )
                },
              },
              layout: {
                marginTopAuto: { xs: true },
                spacing: {
                  ml: { xs: -1 },
                  mr: { xs: -1 },
                },
              },
            }),
          ],
        },
      }),
      buildScreenWithImageLayout({
        id: 'quantity',
        subType: 'form',
        layoutProps: imageLayoutProps,
        columnStyle: true,
        theme: initialData?.isRetreatSpaFlow ? 'retreat' : 'default',
        setupHook: () => {
          const [disclaimer, setDisclaimer] = useState(null)

          return { disclaimer, setDisclaimer }
        },
        breadcrumb: control => {
          return {
            title: control.context.t(massageMessages.info.quantityBreadcrumb),
          }
        },
        fields: {
          main: [
            ...buildHeading({
              title: control =>
                control.context.t(massageMessages.info.quantityTitle),
              subTitle: control =>
                control.context.t(massageMessages.info.quantitySubtitle),
              includeBreadcrumb: true,
            }),
            buildPickerField({
              id: 'quantity',
              defaultValue: 1,
              props: {
                label: control => {
                  return control.context.t(
                    massageMessages.info.quantityPickerLabel
                  )
                },
                min: 1,
                max:
                  initialData?.bookingInfo?.adults +
                  initialData?.bookingInfo?.children,
                onChange: (value, control) => {
                  if (
                    value <
                    initialData?.bookingInfo?.adults +
                      initialData?.bookingInfo?.children
                  ) {
                    control.screen.setupHook?.setDisclaimer?.(null)
                  }
                },
                onDisabledIncrementClick: (_, control) => {
                  // We have to use this useState from setupHook, instead of uiState or state
                  // because otherwise setTimeout doesn't know how to handle it, and starts showing
                  // the disclaimer for a random amount of time
                  control.screen.setupHook?.setDisclaimer?.(true)

                  setTimeout(() => {
                    control.screen.setupHook?.setDisclaimer?.(null)
                  }, 4000)
                },
              },
              layout: {
                spacing: {
                  mt: { xs: 3 },
                  mb: { xs: 2 },
                },
              },
            }),
            buildDisclaimerField({
              props: {
                value: control =>
                  control.context.t(
                    massageMessages.info.limitedMassageDisclaimer
                  ),
                color: colors.errorRed,
                preset: 'text',
                weight: 'bold',
                showDisclaimer: control =>
                  !!control.screen.setupHook?.disclaimer,
              },
            }),
            buildButton({
              props: {
                label: control =>
                  control.context.t(
                    massageMessages.info.quantityContinueButton
                  ),
                onClick: control => {
                  const currentQuantity =
                    control.screen.stateRef.current.quantity
                  const flowQuantity = control.flow.state.quantity.quantity
                  // Resets the massages state if the user changes the previously selected quantity.
                  // Otherwise we end up with redundant massages in the state.
                  if (currentQuantity !== flowQuantity) {
                    control.flow.setState({ time: { massages: [] } })
                  }
                  control.nextScreen()
                },
              },
              layout: {
                marginTopAuto: { xs: true },
                width: { xs: `calc(100% + ${theme.spacing[2]})` },
                spacing: {
                  ml: { xs: -1 },
                  mr: { xs: -1 },
                },
              },
            }),
          ],
        },
      }),
      buildScreenWithImageLayout({
        id: 'time',
        subType: 'form',
        layoutProps: imageLayoutProps,
        columnStyle: true,
        theme: initialData?.isRetreatSpaFlow ? 'retreat' : 'default',
        setupHook: control => {
          const [allGuestsBusy, setAllGuestsBusy] = useState(false)
          useEffect(() => {
            // check if there are already activities for all guests
            // at the same time as the currently chosen massage time
            const allBusy = control.screen.state?.massages?.some(massage => {
              const date = formatDateInUTC(massage?.date)
              const dateTimeISOString = setDateTimeISOString(
                date,
                massage?.time
              )

              return (
                massage?.time &&
                !!initialData?.allGuestsBusy &&
                initialData?.allGuestsBusy(
                  dateTimeISOString,
                  massage?.productNo
                )
              )
            })
            setAllGuestsBusy(allBusy)
          }, [control.screen.state])

          return { allGuestsBusy }
        },
        breadcrumb: control => {
          return {
            title: control.context.t(massageMessages.info.timeBreadcrumb),
          }
        },
        fields: {
          main: [
            ...buildHeading({
              title: control =>
                control.context.t(massageMessages.info.timeTitle),
              subTitle: control =>
                control.context.t(massageMessages.info.timeSubtitle),
              includeBreadcrumb: true,
            }),
            buildItineraryField({
              condition: () => !!initialData?.itineraryItems?.length,
              props: {
                items: initialData?.itineraryItems,
                isHotelBooking: !!initialData?.bookingInfo?.hotelDates,
                moreLabel: globalBookingMessages.buttons.plusItems,
              },
              layout: {
                spacing: {
                  pb: { xs: 0.5 },
                },
              },
            }),
            buildAccordionField({
              defaultValue: null,
              condition: () =>
                // Only show this if this is a dayvisit booking and there is at least 1 child on the booking
                !!initialData.bookingInfo?.entryDate &&
                initialData.bookingInfo?.children > 0 &&
                !isBookingTooClose(initialData.bookingInfo),
              props: {
                label: control =>
                  control.context.t(
                    globalBookingMessages?.disclaimers
                      ?.massageFloatChildrenDisclaimerLabel
                  ),
                children: control =>
                  control.context.t(
                    globalBookingMessages.disclaimers
                      .massageFloatChildrenDisclaimer
                  ),
              },
              layout: {
                spacing: {
                  mb: { xs: 1.5 },
                },
              },
            }),
            buildCustomField({
              defaultValue: { flow: 'massage' },
              props: {
                component: props => (
                  <MassageCards
                    {...props}
                    previousMassageActivity={
                      initialData?.previousMassageActivity
                    }
                  />
                ),
              },
              layout: { spacing: { mb: { xs: 4 } } },
            }),
            buildButton({
              props: {
                label: control =>
                  control.context.t(
                    massageMessages.info.quantityContinueButton
                  ),
                isDisabled: control =>
                  !control.screen.stateRef.current?.ready ||
                  control.screen.setupHook?.allGuestsBusy,
                onClick: control => {
                  const massages = control.screen.stateRef.current?.massages
                  const exchangeRates = control.flow.setupHook?.exchangeRates
                  const isHotelBooking = initialData?.isHBE
                  triggerMultiSelectItemEvent({
                    massages,
                    isHotelBooking,
                    exchangeRates,
                  })
                  control.nextScreen()
                },
              },
              layout: {
                marginTopAuto: { xs: true },
                position: { xs: 'sticky' },
                bottom: { xs: 24, bmd: 0 },
                width: { xs: `calc(100% + ${theme.spacing[2]})` },
                spacing: {
                  ml: { xs: -1 },
                  mr: { xs: -1 },
                },
              },
            }),
          ],
        },
      }),
      buildScreenWithImageLayout({
        id: 'overview',
        subType: 'form',
        breadcrumb: control => {
          return {
            title: control.context.t(
              globalBookingMessages.breadcrumbs.overview
            ),
          }
        },
        columnStyle: true,
        theme: initialData?.isRetreatSpaFlow ? 'retreat' : 'default',
        layoutProps: imageLayoutProps,
        fields: {
          main: [
            ...buildHeading({
              title: control =>
                control.context.t(globalBookingMessages.text.overviewTitle),
              subTitle: control =>
                control.context.t(massageMessages.info.overviewSubTitle),
              includeBreadcrumb: true,
            }),
            buildOverviewField({
              defaultValue: null,
              props: {
                items: control => getItemsForMassageOverview(control),
                isEditing: false,
                isHotelBooking: !!initialData?.bookingInfo?.hotelDates,
                noTableHeading: !!initialData?.isSPA,

                totalPrice: control => getTotalPrice(control),
              },
            }),
            buildProgressButton({
              props: {
                label: control =>
                  control.context.t(massageMessages.info.timeConfirmButton),
                isDisabled: control => control.screen.state.isAddingToCart,
                onClick: async control => {
                  const { hasErrors } = control.validateAndSetScreenErrors()

                  if (hasErrors) {
                    return false
                  }

                  control.screen.setState({
                    isAddingToCart: true,
                  })

                  const success = await onAddToCart(
                    {
                      id: '123',
                      type: 'massage',
                    },
                    control
                  )

                  control.screen.setState({
                    isAddingToCart: false,
                  })

                  if (!success) {
                    control.screenErrors.setState({
                      error: control.context.t(
                        massageMessages.errors.bookingFailedError
                      ),
                    })

                    return false
                  }

                  return true
                },
                onComplete(control) {
                  onClose(control)
                },
              },
              layout: {
                marginTopAuto: { xs: true },
                width: { xs: `calc(100% + ${theme.spacing[2]})` },
                spacing: {
                  ml: { xs: -1 },
                  mr: { xs: -1 },
                  mt: { xs: 1 },
                },
              },
            }),

            buildScreenErrorField({
              condition: control => !!control.screenErrors.state?.error,
              props: {
                fieldIdsToRender: ['error'],
              },
              layout: {
                spacing: {
                  mt: { xs: 1 },
                },
              },
            }),
          ],
        },
      }),
    ],
  })
