import { Alert } from 'bl-common/src/elements/Alert'
import type { PartialBookingEngine } from 'bl-common/src/styles/types'

import { DiscountType } from './types'

interface ActiveDiscountCodeProps {
  code: string
  onClose: () => void
  isLoading: boolean
  type: DiscountType
  text: string
  removeLabel: string
  themeStyle: PartialBookingEngine['discountAccordionField']['activeDiscountCode']
}
export const ActiveDiscountCode = (props: ActiveDiscountCodeProps) => {
  return (
    <Alert
      type="success"
      onClose={props.onClose}
      closeLabel={props.removeLabel}
      color={props.type === DiscountType.giftCard ? 'blue' : 'green'}
      themeStyle={props.themeStyle}
    >
      {props.text}
    </Alert>
  )
}

export default ActiveDiscountCode
