import { isBefore } from 'date-fns/isBefore'
import { sub } from 'date-fns/sub'

import type { SubFlowBookingInfo } from 'bl-flows-core/src/types'

const DEFAULT_CUTOFF_HOURS = 2
const DEFAULT_CUTOFF_HOURS_CONTACT_FORM = 16

export const isBookingTooClose = (
  bookingInfo: SubFlowBookingInfo,
  cutOffHours = DEFAULT_CUTOFF_HOURS
): boolean => {
  if (!bookingInfo?.entryDate && !bookingInfo?.hotelDates) {
    return false
  }
  // For day visits we allow upgrades up until 2 hours before entry in the lagoon
  if (bookingInfo?.entryDate) {
    return currentDateIsBeforeCutOff(
      new Date(bookingInfo?.entryDate),
      cutOffHours
    )
  } else {
    // For hotels we allow upgrades until checkout on departure date unless there is some custom cutOffHours declared
    if (cutOffHours !== DEFAULT_CUTOFF_HOURS) {
      return currentDateIsBeforeCutOff(
        bookingInfo?.hotelDates.arrivalDate,
        cutOffHours
      )
    }
    return isBefore(bookingInfo?.hotelDates.departureDate, new Date())
  }
}

export const isBookingTooCloseForContactForm = (
  bookingInfo: SubFlowBookingInfo,
  cutOffHours = DEFAULT_CUTOFF_HOURS_CONTACT_FORM
): boolean => {
  if (!bookingInfo?.entryDate && !bookingInfo?.hotelDates) {
    return false
  }
  // Cutoff date for SPA bookings is the entry date, but for hotels it's the checkout date
  const cutOffDate =
    bookingInfo?.entryDate || bookingInfo?.hotelDates.departureDate

  return currentDateIsBeforeCutOff(cutOffDate, cutOffHours)
}

const currentDateIsBeforeCutOff = (cutOffDate: Date, cutOffHours: number) => {
  return isBefore(sub(cutOffDate, { hours: cutOffHours }), new Date())
}
