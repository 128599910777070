import type { Cart } from 'bl-graphql'
import {
  type FloatProductId,
  floatProductIds,
  type MassageProductId,
  massageProductIds,
} from 'bl-utils/src/ProductIds'

export const getIsActivityExceedingGuests = ({
  guests,
  cart,
  isFloat,
}: {
  guests: number
  cart: Cart
  isFloat?: boolean
}) => {
  const numberOfExistingMassages = cart?.items?.reduce((acc, curr) => {
    if (massageProductIds.includes(curr.productId as MassageProductId)) {
      return acc + curr.qty
    }
    return acc
  }, 0)

  const numberOfExistingFloats = cart?.items?.reduce((acc, curr) => {
    if (floatProductIds.includes(curr.productId as FloatProductId)) {
      return acc + curr.qty
    }
    return acc
  }, 0)

  if (isFloat && numberOfExistingFloats >= guests) {
    return true
  }

  if (!isFloat && numberOfExistingMassages >= guests) {
    return true
  }

  return false
}
