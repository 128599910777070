import { sentryLogging } from 'sentry-utils/logging'
import { productIdToItemId, productIdToName } from 'bl-utils/src/analytics'
import type { Item } from 'bl-utils/src/analytics/types/google-analytics-events'
import { calcPrice } from 'bl-utils/src/currency/calcPrice'
import type { ProductId } from 'bl-utils/src/ProductIds'

export const getEcommerce = (options: {
  productId: ProductId
  price: number
  adults: number
  children: number
  youngAdults?: number
  youngAdultsPrice?: number
  exchangeRates: any
  itemBrand?: 'Blue Lagoon' | 'Highland Base'
  spaType:
    | 'retreat'
    | 'comfort'
    | 'premium'
    | 'seasonal'
    | 'signature'
    | 'subscription'
    | 'highlandbasebaths'
}) => {
  try {
    const indexByType = { comfort: 0, premium: 1, retreat: 2 }

    const index = indexByType[options.spaType] || 0

    if (!options.exchangeRates) {
      return null
    }

    const products: Item[] = [
      {
        item_id: productIdToItemId[options.productId],
        item_name: productIdToName[options.productId],
        currency: 'EUR',
        index,
        item_brand: options.itemBrand ?? 'Blue Lagoon',
        item_category: 'Day Visit',
        item_list_id: 'day_visit',
        item_list_name: 'Day Visit',
        price: calcPrice(options.price, options.exchangeRates?.EUR),
        quantity: options.adults,
      },
    ]

    if (options.youngAdults > 0) {
      products.push({
        item_id: productIdToItemId[options.productId],
        item_name: productIdToName[options.productId],
        currency: 'EUR',
        index,
        item_brand: options.itemBrand ?? 'Blue Lagoon',
        item_category: 'Day Visit',
        item_list_id: 'day_visit',
        item_list_name: 'Day Visit',
        price: calcPrice(options.youngAdultsPrice, options.exchangeRates?.EUR),
        quantity: options.youngAdults,
      })
    }

    if (options.children > 0) {
      products.push({
        item_id: productIdToItemId[options.productId],
        item_name: productIdToName[options.productId],
        currency: 'EUR',
        index,
        item_brand: options.itemBrand ?? 'Blue Lagoon',
        item_category: 'Day Visit',
        item_list_id: 'day_visit',
        item_list_name: 'Day Visit',
        price: 0,
        quantity: options.children,
      })
    }

    return {
      currency: 'EUR' as const,
      value: products.reduce(
        (sum, product) => sum + product.price * product.quantity,
        0
      ),
      items: products,
    }
  } catch (err) {
    sentryLogging({
      team: 'team-day-visit-svartsengi',
      error: new Error('Error creating ecommerce object', err),
    })
  }
}
