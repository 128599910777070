import isEqual from 'lodash/isEqual'

import type { FlowState } from '../types'

type StateKeys = keyof FlowState
type StateValues = FlowState[StateKeys]

const isDefined = (value: unknown) => typeof value !== 'undefined'
export const requiresReset = (
  previousValue: StateValues,
  nextValue: StateValues
) => isDefined(previousValue) && !isEqual(previousValue, nextValue)
