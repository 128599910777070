import { ProductCard as BLProductCard } from 'bl-common/src/booking/ProductCard/ProductCard'

import type { FlowComponent, FlowProductCardField } from '../../types'
import { getFlowValue } from '../../utils'

type ProductCardFieldProps = FlowComponent & FlowProductCardField['props']

export const ProductCard = (props: ProductCardFieldProps) => {
  return (
    <BLProductCard
      type="button"
      title={getFlowValue(props.title, props.control)}
      body={getFlowValue(props.description, props.control)}
      priceLabel={getFlowValue(props.price, props.control)}
      priceFormat={getFlowValue(props.priceFormat, props.control)}
      ariaLabel={getFlowValue(props.ariaLabel, props.control)}
      selectLabel="Select"
      onClick={() => props.onClick(props.control)}
    />
  )
}
