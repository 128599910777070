import { useContext, useEffect, useState } from 'react'

import { colors } from 'bl-common/src/constants/colors'
import { CurrencyContext } from 'bl-common/src/context/Currency/CurrencyProvider'
import { Spinner } from 'bl-common/src/elements/Spinner'
import { SpinnerWrapper } from 'bl-common/src/elements/SpinnerWrapper'
import { theme } from 'bl-common/src/styles/theme'
import {
  buildAccordionField,
  buildButton,
  buildCustomField,
  buildDisclaimerField,
  buildFlow,
  buildHeading,
  buildItineraryField,
  buildOverviewField,
  buildProgressButton,
  buildScreenErrorField,
  buildScreenWithImageLayout,
  type BuildSubFlow,
  type FlowControl,
  type FlowImageLayout,
  type FlowItineraryField,
  type SubFlowActivity,
  type SubFlowBookingInfo,
} from 'bl-flows-core'
import { setDateTimeISOString } from 'bl-utils/src/date'
import { formatDateInUTC } from 'bl-utils/src/formatting/formatDate'
import { logActivityUnavailable } from 'bl-utils/src/sentryLogs'

import { MassageCards } from '../../components/MassageCards'
import { globalBookingMessages } from '../../messages'
import { getItemsForFloatOverview } from '../../utils'
import { triggerMultiSelectItemEvent } from '../../utils/analytics'
import { getBookingDates } from '../../utils/getBookingDates'
import { isBookingTooClose } from '../../utils/isBookingTooClose'
import type { MassageState } from '../massage'
import { floatMessages } from './messages/floatMessages'

const imageLayoutProps: FlowImageLayout['props'] = {
  layoutId: 'float-image-layout',
  // Reducing quality slightly to improve performance, using q=70
  layoutImageSrc:
    'https://images.ctfassets.net/w65k7w0nsb8q/3Q5Sf19OZQfSxuPhZnR1UK/e459bd4e071e0010c88c42d6df246f30/BL_FLOATING_CRL_0423-UNL_162.jpg?q=70',
}

// TODO - remove once we have a proper cart
interface CartItem {
  id: string
  type: string
}

export interface FloatFlowInitialData {
  bookingInfo: SubFlowBookingInfo
  itineraryItems: FlowItineraryField['props']['items']
  previousFloatActivity: SubFlowActivity
  // Todo: remove this feature flags when we stop using them
  enableGuestName?: boolean
  isHBE?: boolean
  isRetreatSpaBooking?: boolean
  enableAddGroupFloat?: boolean
  isSPA?: boolean
  isRetreatSpaFlow?: boolean
  isFloatExceedingGuests?: boolean
  allGuestsBusy: (time: any, productId: any) => boolean
  noFloatsAvailable?: boolean
}

const getTotalPrice = (control: FlowControl) => {
  if (control.flow.setupHook?.edit) {
    return 0
  }
  return control.flow.stateRef.current?.time.massages.reduce(
    (acc: number, massage: MassageState) => {
      const price = massage?.massageInfo?.price || 0
      return acc + price
    },
    0
  )
}

export const buildFloatFlow: BuildSubFlow<CartItem> = (
  onAddToCart,
  onClose,
  initialData?: FloatFlowInitialData
) =>
  buildFlow({
    id: 'floatFlow',
    settings: {
      resetWhenOpened: true,
    },
    routerSettings: {
      updateHistory: false,
    },
    setupHook: () => {
      const { exchangeRates } = useContext(CurrencyContext)

      if (initialData?.noFloatsAvailable) {
        logActivityUnavailable({
          activityType: 'float',
          spaDate: initialData?.bookingInfo?.entryDate,
          hotelDates: getBookingDates(initialData?.bookingInfo),
        })
      }

      return {
        bookingInfo: initialData?.bookingInfo,
        previousFloatActivity: initialData?.previousFloatActivity,
        isRetreatSpaBooking:
          initialData?.isRetreatSpaBooking || initialData?.isRetreatSpaFlow,
        // Todo: remove these feature flags when we stop using them
        enableGuestName: initialData?.enableGuestName,
        enableAddGroupFloat: initialData?.enableAddGroupFloat,
        numberOfBookingGuests:
          Number(initialData?.bookingInfo?.adults || 0) +
          Number(initialData?.bookingInfo?.children || 0),
        noFloatsAvailable: initialData?.noFloatsAvailable,
        exchangeRates,
      }
    },
    children: [
      buildScreenWithImageLayout({
        id: 'about',
        subType: 'form',
        layoutProps: imageLayoutProps,
        columnStyle: true,
        theme: initialData?.isRetreatSpaFlow ? 'retreat' : 'default',
        breadcrumb: control => {
          return {
            title: control.context.t(floatMessages.info.aboutBreadcrumb),
          }
        },
        fields: {
          main: [
            ...buildHeading({
              title: control =>
                control.context.t(floatMessages.info.aboutTitle),
              subTitle: control =>
                control.context.t(floatMessages.info.aboutSubtitle),
              includeBreadcrumb: true,
              imageSrc: imageLayoutProps.layoutImageSrc,
              imageLayout: { display: { bmd: 'none' } },
            }),

            // disclaimer for when there are already floats for every guest in the cart
            buildDisclaimerField({
              condition: () => !!initialData?.isFloatExceedingGuests,

              props: {
                value: control =>
                  control.context.t(floatMessages.warnings.noMoreFloats, {
                    guests:
                      initialData?.bookingInfo?.adults +
                      initialData?.bookingInfo?.children,
                    floats:
                      initialData?.bookingInfo?.adults +
                      initialData?.bookingInfo?.children,
                  }),
                color: colors.errorRed,
              },
              layout: {
                spacing: { mb: { xs: 2 } },
              },
            }),

            // disclaimer for no availability
            buildDisclaimerField({
              condition: control => !!control.flow.setupHook?.noFloatsAvailable,

              props: {
                value: control =>
                  control.context.t(
                    floatMessages.warnings.noAvailabilityDisclaimer
                  ),
                color: colors.errorRed,
              },
              layout: {
                spacing: { mb: { xs: 2 }, mt: { xs: 2, md: 0 } },
              },
            }),

            buildButton({
              condition: control => !control.flow.setupHook?.loading,
              props: {
                label: control =>
                  control.flow.setupHook?.noFloatsAvailable
                    ? control.context.t(
                        globalBookingMessages.buttons.closeWindow
                      )
                    : control.context.t(globalBookingMessages.buttons.continue),
                isDisabled: () => initialData?.isFloatExceedingGuests,
                onClick: control =>
                  control.flow.setupHook?.noFloatsAvailable
                    ? onClose(control)
                    : control.nextScreen(),
              },
              layout: {
                marginTopAuto: { xs: true },
                width: { xs: `calc(100% + ${theme.spacing[2]})` },
                spacing: {
                  ml: { xs: -1 },
                  mr: { xs: -1 },
                },
              },
            }),
            // Build spinner
            buildCustomField({
              defaultValue: null,
              condition: control => !!control.flow.setupHook?.loading,
              props: {
                component: () => {
                  return (
                    <SpinnerWrapper>
                      <Spinner shouldAnimate />
                    </SpinnerWrapper>
                  )
                },
              },
              layout: {
                marginTopAuto: { xs: true },
                spacing: {
                  ml: { xs: -1 },
                  mr: { xs: -1 },
                },
              },
            }),
          ],
        },
      }),
      buildScreenWithImageLayout({
        id: 'time',
        subType: 'form',
        layoutProps: imageLayoutProps,
        columnStyle: true,
        theme: initialData?.isRetreatSpaFlow ? 'retreat' : 'default',
        setupHook: control => {
          const [allGuestsBusy, setAllGuestsBusy] = useState(false)
          useEffect(() => {
            // check if there are already activities for all guests
            // at the same time as the currently chosen float time
            const allBusy = control.screen.state?.massages?.some(massage => {
              const date = formatDateInUTC(
                new Date(massage?.date),
                'yyyy-MM-dd'
              )
              const dateTimeISOString = setDateTimeISOString(
                date,
                massage?.time
              )

              return (
                massage?.time &&
                !!initialData?.allGuestsBusy &&
                initialData?.allGuestsBusy(
                  dateTimeISOString,
                  massage?.productNo
                )
              )
            })
            setAllGuestsBusy(allBusy)
          }, [control.screen.state])

          return { allGuestsBusy }
        },
        breadcrumb: control => {
          return {
            title: control.context.t(floatMessages.info.timeBreadcrumb),
          }
        },
        fields: {
          main: [
            ...buildHeading({
              title: control => control.context.t(floatMessages.info.timeTitle),
              subTitle: control =>
                control.context.t(floatMessages.info.timeSubtitle),
              includeBreadcrumb: true,
            }),
            buildItineraryField({
              condition: () => !!initialData?.itineraryItems?.length,
              props: {
                items: initialData?.itineraryItems,
                isHotelBooking: !!initialData?.bookingInfo?.hotelDates,
                moreLabel: globalBookingMessages.buttons.plusItems,
              },
            }),
            buildAccordionField({
              defaultValue: null,
              condition: () =>
                // Only show this if this is a dayvisit booking and there is at least 1 child on the booking
                !!initialData.bookingInfo?.entryDate &&
                initialData.bookingInfo?.children > 0 &&
                !isBookingTooClose(initialData.bookingInfo),
              props: {
                label: control =>
                  control.context.t(
                    globalBookingMessages?.disclaimers
                      ?.massageFloatChildrenDisclaimerLabel
                  ),
                children: control =>
                  control.context.t(
                    globalBookingMessages.disclaimers
                      .massageFloatChildrenDisclaimer
                  ),
              },
              layout: {
                spacing: {
                  mb: { xs: 1.5 },
                },
              },
            }),
            buildDisclaimerField({
              id: 'disclaimer',
              props: {
                value: control => {
                  const uiState = control.screen.uiState
                  const disclaimer =
                    uiState?.floatsExceedNumberOfGuestsDisclaimer
                      ? floatMessages.errors.floatsExceedNumberOfGuests
                      : uiState?.showNotReadyDisclaimer
                        ? floatMessages.warnings.noTimeSelectedDisclaimer
                        : undefined

                  return !!disclaimer && control.context.t(disclaimer)
                },
                color: colors.errorRed,
                preset: 'text',
                scrollIntoView: true,
                showDisclaimer: control =>
                  !!control.screen?.uiState?.showNotReadyDisclaimer ||
                  !!control.screen?.uiState
                    ?.floatsExceedNumberOfGuestsDisclaimer,
              },
            }),
            buildCustomField({
              defaultValue: { flow: 'float' },
              props: {
                component: props => (
                  <MassageCards
                    {...props}
                    previousMassageActivity={initialData?.previousFloatActivity}
                    enableClearCard
                    useIncrementalCards
                    isFloat
                  />
                ),
              },
              layout: {
                spacing: {
                  mb: { xs: 4 },
                  mt: { xs: 1 },
                },
              },
            }),
            buildButton({
              props: {
                label: control =>
                  control.context.t(globalBookingMessages.buttons.continue),
                isDisabled: control => control.screen.setupHook?.allGuestsBusy,
                onClick: control => {
                  const massages = control.screen.stateRef.current?.massages
                  const exchangeRates = control.flow.setupHook?.exchangeRates
                  const isHotelBooking = initialData?.isHBE
                  // initialize disclaimers as false so old errors aren't blocking new ones
                  control.screen.setUiState({
                    showNotReadyDisclaimer: false,
                    floatsExceedNumberOfGuestsDisclaimer: false,
                  })
                  if (!control.screen.stateRef.current?.ready) {
                    control.screen.setUiState({
                      showNotReadyDisclaimer: true,
                    })
                    return false
                  }
                  const totalSelectedValidForCount =
                    massages.reduce(
                      (total: number, massage: MassageState) =>
                        total + Number(massage.massageInfo.validForCount) || 0,
                      0
                    ) || 0

                  if (
                    totalSelectedValidForCount >
                    control.flow.setupHook.numberOfBookingGuests
                  ) {
                    control.screen.setUiState({
                      floatsExceedNumberOfGuestsDisclaimer: true,
                    })
                    return false
                  }

                  triggerMultiSelectItemEvent({
                    massages,
                    isHotelBooking,
                    exchangeRates,
                  })

                  control.nextScreen()
                },
              },
              layout: {
                marginTopAuto: { xs: true },
                position: { xs: 'sticky' },
                bottom: { xs: 24, bmd: 0 },
                width: { xs: `calc(100% + ${theme.spacing[2]})` },
                spacing: {
                  ml: { xs: -1 },
                  mr: { xs: -1 },
                },
              },
            }),
            buildScreenErrorField({
              condition: control => !!control.screenErrors.state?.error,
              props: {
                fieldIdsToRender: ['error'],
              },
              layout: {
                spacing: {
                  mt: { xs: 1 },
                  mb: { xs: 1 },
                },
              },
            }),
          ],
        },
      }),
      buildScreenWithImageLayout({
        id: 'overview',
        subType: 'form',
        columnStyle: true,
        theme: initialData?.isRetreatSpaFlow ? 'retreat' : 'default',
        breadcrumb: control => {
          return {
            title: control.context.t(
              globalBookingMessages.breadcrumbs.overview
            ),
          }
        },
        layoutProps: imageLayoutProps,
        fields: {
          main: [
            ...buildHeading({
              title: control =>
                control.context.t(globalBookingMessages.text.overviewTitle),
              subTitle: control =>
                control.context.t(floatMessages.info.overviewSubTitle),
              includeBreadcrumb: true,
            }),
            buildOverviewField({
              defaultValue: null,
              props: {
                items: control => getItemsForFloatOverview(control),
                isEditing: false,
                isHotelBooking: !!initialData?.bookingInfo?.hotelDates,
                noTableHeading: !!initialData?.isSPA,
                totalPrice: control => getTotalPrice(control),
              },
            }),
            buildProgressButton({
              props: {
                label: control =>
                  control.context.t(floatMessages.info.timeConfirmButton),
                isDisabled: control => control.screen.state.isAddingToCart,
                onClick: async control => {
                  const { hasErrors } = control.validateAndSetScreenErrors()

                  if (hasErrors) {
                    return false
                  }

                  control.screen.setState({
                    isAddingToCart: true,
                  })

                  const success = await onAddToCart(
                    {
                      id: '123',
                      type: 'float',
                    },
                    control
                  )

                  control.screen.setState({
                    isAddingToCart: false,
                  })

                  if (!success) {
                    control.screenErrors.setState({
                      error: control.context.t(
                        floatMessages.errors.bookingFailedError
                      ),
                    })

                    return false
                  }

                  return true
                },
                onComplete(control) {
                  onClose(control)
                },
              },
              layout: {
                marginTopAuto: { xs: true },
                width: { xs: `calc(100% + ${theme.spacing[2]})` },
                spacing: {
                  ml: { xs: -1 },
                  mr: { xs: -1 },
                  mt: { xs: 1 },
                },
              },
            }),

            buildScreenErrorField({
              condition: control => !!control.screenErrors.state?.error,
              props: {
                fieldIdsToRender: ['error'],
              },
              layout: {
                spacing: {
                  mt: { xs: 1 },
                },
              },
            }),
          ],
        },
      }),
    ],
  })
