import { SimpleAccordion } from 'bl-common/src/booking/SimpleAccordion/SimpleAccordion'

import type { FlowAccordionField, FlowComponent } from '../../types'
import { getFlowValue } from '../../utils'

type AccordionFieldProps = FlowComponent & FlowAccordionField['props']

export const AccordionField = ({ control, ...props }: AccordionFieldProps) => {
  const label = getFlowValue(props.label, control)
  const children = getFlowValue(props.children, control)
  const labelColor = getFlowValue(props?.labelColor, control)
  const withBorder = getFlowValue(props?.withBorder, control)
  const startExpanded = getFlowValue(props?.startExpanded, control)

  return (
    <SimpleAccordion
      label={label}
      onClick={() => props?.onClick?.(control)}
      labelColor={labelColor}
      withBorder={withBorder}
      startExpanded={startExpanded}
    >
      {children}
    </SimpleAccordion>
  )
}
