import { addMinutes } from 'date-fns/addMinutes'
import { isBefore } from 'date-fns/isBefore'
import { parseISO } from 'date-fns/parseISO'

import type { SubFlowTransportation, TimeSlot } from 'bl-flows-core'
import { getTimeFromDateTimeString } from 'bl-utils/src/date'

const getRestaurantSelectionStartTime = (
  timeSlots: TimeSlot[],
  transportation: SubFlowTransportation
) => {
  if (!timeSlots || timeSlots.length === 0) {
    return ''
  }

  if (!transportation || !transportation?.pickupTime) {
    return timeSlots[0].time
  }

  const pickupTime = parseISO(`1990-10-09 ${transportation.pickupTime}`)
  const slotsBeforeDepartureAndDuration = timeSlots.filter(
    slot =>
      !isBefore(
        parseISO(`1990-10-09 ${slot.time}`),
        addMinutes(pickupTime, 20) // TODO: Get transfer duration
      )
  )

  return slotsBeforeDepartureAndDuration.length > 0
    ? slotsBeforeDepartureAndDuration[0].time
    : ''
}

const getRestaurantSelectionEndTime = (
  timeSlots: TimeSlot[],
  transportation: SubFlowTransportation
) => {
  if (!timeSlots || timeSlots.length === 0) {
    return ''
  }

  if (!transportation || !transportation?.departureTime) {
    return timeSlots[timeSlots.length - 1].time
  }

  const departureTime = parseISO(`1990-10-09 ${transportation.departureTime}`)

  const slotsBeforeDeparture = timeSlots.filter(slot =>
    isBefore(parseISO(`1990-10-09 ${slot.time}`), departureTime)
  )

  return slotsBeforeDeparture.length > 0
    ? slotsBeforeDeparture[slotsBeforeDeparture.length - 1].time
    : ''
}

export const getAvailableLavaSlots = (
  timeSlots: TimeSlot[],
  transportation?: SubFlowTransportation
) => {
  const filteredSlots = timeSlots?.filter(slot => slot.available > 0)

  if (!transportation) {
    return filteredSlots
  }

  const startTime = getRestaurantSelectionStartTime(
    filteredSlots,
    transportation
  )
  const endTime = getRestaurantSelectionEndTime(filteredSlots, transportation)

  const startIndex = filteredSlots.findIndex(slot => slot.time === startTime)
  const endIndex = filteredSlots.findIndex(slot => slot.time === endTime)

  return filteredSlots.slice(startIndex, endIndex + 1)
}

export const getAvailableSpaRestaurantSlots = (
  timeSlots: TimeSlot[],
  transportation?: SubFlowTransportation
) => {
  if (timeSlots?.length === 0) {
    return []
  }
  const filteredSlots = timeSlots
    .filter(slot => slot.available > 0)
    .map(slot => ({
      ...slot,
      date: slot.time.toString(),
      time: getTimeFromDateTimeString(slot.time, false), // Change time from YYYY-MM-DDTHH:mm:ss to HH:mm
    }))

  if (!transportation) {
    return filteredSlots
  }

  const startTime = getRestaurantSelectionStartTime(
    filteredSlots,
    transportation
  )
  const endTime = getRestaurantSelectionEndTime(filteredSlots, transportation)

  const startIndex = filteredSlots.findIndex(slot => slot.time === startTime)
  const endIndex = filteredSlots.findIndex(slot => slot.time === endTime)

  return filteredSlots.slice(startIndex, endIndex + 1)
}
