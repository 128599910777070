import { useTheme } from 'styled-components'

import { ProgressiveImage } from 'bl-common/src/elements/ProgressiveImage'

import { renderFields } from '../../renderers/renderUtils'
import type {
  FlowLayoutComponent,
  FlowSmallImageLayout,
  FlowSmallImageLayoutFields,
} from '../../types'
import {
  Container,
  ImageContainer,
  layoutAnimation,
  LeftContainer,
  RightContainer,
} from './styles'

type SmallImageLayout = FlowLayoutComponent<FlowSmallImageLayoutFields> &
  FlowSmallImageLayout['props']

export const SmallImageLayout: React.FC<SmallImageLayout> = ({
  layoutImageSrc,
  hideImageOnMobile,
  screenTheme,
  fields,
  control,
  columnStyle,
  ImageComponent,
}) => {
  const theme = useTheme()
  const screenThemes = theme?.bookingEngineScreenThemes

  return (
    <Container>
      {(layoutImageSrc || ImageComponent) && (
        <LeftContainer
          {...layoutAnimation}
          screentheme={screenTheme}
          screenthemes={screenThemes}
          hideImageOnMobile={hideImageOnMobile}
        >
          <ImageContainer>
            {ImageComponent || <ProgressiveImage image={layoutImageSrc} />}
          </ImageContainer>
        </LeftContainer>
      )}
      <RightContainer
        desktopWidth={layoutImageSrc || ImageComponent ? 50 : 100}
      >
        {renderFields(fields.main, control, screenTheme, false, columnStyle)}
      </RightContainer>
    </Container>
  )
}
