import { addMinutes } from 'date-fns/addMinutes'
import { areIntervalsOverlapping } from 'date-fns/areIntervalsOverlapping'

import type { ActivityItem } from 'bl-graphql'
import { getProductDurationInMinutes } from 'bl-utils/src/productUtils'

export const areActivitiesOverlapping = (
  m1: ActivityItem,
  m2: { time: string; productId: string }
) => {
  const start1 = new Date(m1?.meta?.arrivalTime)
  const end1 = addMinutes(start1, getProductDurationInMinutes(m1?.productId))

  const start2 = new Date(m2.time)
  const end2 = addMinutes(start2, getProductDurationInMinutes(m2?.productId))

  return areIntervalsOverlapping(
    {
      start: start1,
      end: end1,
    },
    {
      start: start2,
      end: end2,
    }
  )
}
