import { rgba } from 'polished'
import styled, { css } from 'styled-components'

import { colors } from '../../constants/colors'
import { zIndex } from '../../constants/zIndex'
import { Type } from '../../elements/Typography/Typography'
import { theme } from '../../styles/theme'
import type { PartialBookingEngine } from '../../styles/types'
import { media } from '../../utils/media'

type PaginationItemProps = {
  hasValue?: boolean
  themeStyle?: PartialBookingEngine['breadcrumbField']['pagination']
}

type DashProps = {
  disabled?: boolean
  themeStyle?: PartialBookingEngine['breadcrumbField']['pagination']
}

type TextProps = {
  disabled?: boolean
  themeStyle?: PartialBookingEngine['breadcrumbField']['pagination']
}

type ActiveIndicatorProps = {
  disabled?: boolean
  active?: boolean
  themeStyle?: PartialBookingEngine['breadcrumbField']['pagination']
}

export const Text = styled(Type)<TextProps>`
  transform-origin: 0 0;
  transition: transform 300ms;
  white-space: nowrap;
  transform: translate(0, -8px);

  opacity: ${props =>
    props?.disabled ? (props?.themeStyle?.textOpacity ?? 0.5) : 1};
`

export const Pagination = styled.ul`
  width: 100%;
  display: flex;
  position: relative;
  z-index: ${zIndex.above2};
`

export const PaginationItem = styled.li<PaginationItemProps>`
  flex: 1 1 auto;
  width: 100%;
  position: relative;
  overflow: hidden;

  & + & {
    padding-left: ${({ theme }) => theme.spacing[1]};
  }

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 4px;
    left: 80%;
    background: rgb(255, 255, 255);
    z-index: 2;
    background: linear-gradient(
      90deg,
      ${props =>
        rgba(
          props.themeStyle?.fadeColor
            ? props.themeStyle?.fadeColor
            : colors.backgroundWhite,
          0
        )}
        0%,
      ${props =>
        rgba(
          props.themeStyle?.fadeColor
            ? props.themeStyle?.fadeColor
            : colors.backgroundWhite,
          1
        )}
        100%
    );
  }

  ${({ hasValue, theme, themeStyle }) =>
    hasValue &&
    css`
      ${Text} {
        color: ${themeStyle?.upperTitleColor ?? colors.dark};
        opacity: 0.8;
        transform: translate(0, ${theme.spacing[-2]}) scale(0.85);

        ${media.md(css`
          transform: translate(0, ${theme.spacing[-2]}) scale(0.85);
        `)}
      }
    `}
`

export const Button = styled.button<{
  themeStyle?: any
  disabled?: boolean
}>(({ themeStyle, disabled }) => ({
  cursor: disabled ? 'default' : 'pointer',
  color: disabled
    ? (themeStyle?.inactiveTextColor ?? colors.dark)
    : (themeStyle?.textColor ?? colors.deepBlue),

  background: themeStyle?.background,

  paddingTop: theme.spacing[2],
  display: 'flex',

  flexWrap: 'wrap',
  width: '100%',
}))

export const Value = styled.div<{
  themeStyle?: PartialBookingEngine['breadcrumbField']['pagination']
}>`
  bottom: ${({ theme }) => theme.spacing[0.5]};
  position: absolute;
  text-align: left;
  width: 100%;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 30%;
    height: 100%;
    background: ${props => `linear-gradient(
      to right,
      rgba(${props?.themeStyle?.fadeColor ?? colors.white}, 0) 0%,
      white 80%,
      white 100%
    )`};
    pointer-events: none;
  }
`
export const Line = styled.div<DashProps>`
  display: flex;
  width: 100%;

  ${({ disabled, themeStyle }) =>
    disabled &&
    css`
      background: ${rgba(
        themeStyle?.inactiveDash ?? colors.dark,
        themeStyle?.dashOpacity ?? 0.3
      )};
    `}
`
export const Dash = styled.span<DashProps>`
  flex: 1 0 auto;
  background: ${props =>
    props?.themeStyle?.activeDash
      ? rgba(props?.themeStyle?.activeDash, 0.3)
      : rgba(82, 165, 184, 0.3)};
  height: 2px;
  position: relative;

  & + & {
    margin-left: 2px;
  }

  ${({ disabled, themeStyle }) =>
    disabled &&
    css`
      background: ${rgba(
        themeStyle?.inactiveDash ?? colors.dark,
        themeStyle?.dashOpacity ?? 0.3
      )};
    `}
`
export const ActiveIndicator = styled.span<ActiveIndicatorProps>`
  width: 100%;
  height: 2px;
  position: absolute;
  left: 0;
  top: 0;
  background: ${({ themeStyle }) => themeStyle?.activeDash ?? colors.deepBlue};
  transform: scaleX(0);
  transition: transform 300ms cubic-bezier(0.24, 0.28, 0.63, 1);
  transform-origin: ${({ disabled, active }) =>
    disabled && !active ? '100%' : '0%'};

  ${({ active }) =>
    active &&
    css`
      transform: scaleX(1);
    `}
`
