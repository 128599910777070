import { useTheme } from 'styled-components'

import { colors } from 'bl-common/src/constants/colors'
import { ProgressButton } from 'bl-common/src/elements/ProgressButton/ProgressButton'
import { themeToPresetsMap } from 'bl-common/src/elements/ProgressButton/ProgressButton'

import type { FlowComponent, FlowProgressButtonField } from '../../types'
import { getFlowValue } from '../../utils'

type ButtonProps = FlowComponent & FlowProgressButtonField['props']

export const ProgressButtonField = ({
  label,
  onClick,
  onComplete,
  isDisabled = false,
  preset,
  control,
  screenTheme,
}: ButtonProps) => {
  const theme = useTheme()
  const themeStyle = theme?.bookingEngine?.[screenTheme]?.buttonField

  const colorStyle: { color?: string } = {}
  const buttonPreset = preset ?? themeToPresetsMap[screenTheme]
  // For this senario, we want to use the lightGrey preset for the button but change the color to the screenTheme color
  if (screenTheme === 'blue' && buttonPreset === 'lightGrey') {
    colorStyle.color = colors.deepBlue
  }

  return (
    <>
      <ProgressButton
        type={'button'}
        onClick={() => onClick?.(control)}
        onComplete={() => onComplete?.(control)}
        paddingSize="small"
        preset={buttonPreset}
        style={{
          width: '100%',
          ...colorStyle,
          ...themeStyle?.button,
        }}
        disabled={getFlowValue(isDisabled, control)}
      >
        {getFlowValue(label, control)}
      </ProgressButton>
    </>
  )
}
