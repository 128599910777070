import { FieldRenderer } from '../../renderers'
import type { FlowComponent, FlowMultipleFields } from '../../types'

type MultipleFieldsProps = FlowComponent & FlowMultipleFields['props']

// The purpose of this field is to be able to render multiple fields in a flow
// within the same 'FieldRenderer' <div> to be able to add layout styles on them together
// An example use case is 2 buttons that need to be sticky together as a whole and not seperately
export const MultipleFields = ({
  fields,
  screenTheme,
  ...props
}: MultipleFieldsProps) => {
  if (!fields.length) {
    return null
  }

  return (
    <>
      {fields.map(field => (
        <FieldRenderer
          key={field.id}
          control={props?.control}
          screenTheme={screenTheme}
          item={field}
        />
      ))}
    </>
  )
}
