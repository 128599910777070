import { HB_PRODUCT_IDS, PRODUCT_IDS } from 'bl-utils/src/ProductIds'

export const isActivityInCart = (control, flowId) => {
  switch (flowId) {
    case 'skincare':
      return control.flow.setupHook?.cart?.items?.find(
        item => item?.productId === PRODUCT_IDS.SilicaMudMask75ml
      )
    case 'lava':
      return control.flow.setupHook?.cart?.items?.find(
        item => item?.productId === PRODUCT_IDS.RestaurantLava
      )
    case 'spa-restaurant':
      return control.flow.setupHook?.cart?.items?.find(
        item => item?.productId === PRODUCT_IDS.SpaRestaurant
      )
    case 'highlandBaseRestaurant':
      return control.flow.setupHook?.cart?.items?.find(
        item => item?.productId === HB_PRODUCT_IDS.HighlandBaseRestaurant
      )
    case 'massage':
      return control.flow.setupHook?.cart?.items?.find(
        item =>
          item?.productId === PRODUCT_IDS.Massage30 ||
          item?.productId === PRODUCT_IDS.Massage60 ||
          item?.productId === PRODUCT_IDS.Massage120
      )
    case 'float':
      return control.flow.setupHook?.cart?.items?.find(
        item =>
          item?.productId === PRODUCT_IDS.MassageFloatingOne ||
          item?.productId === PRODUCT_IDS.MassageFloatingTwo ||
          item?.productId === PRODUCT_IDS.MassageFloatingGroup ||
          item?.productId === PRODUCT_IDS.MassageFloatingMidnight
      )
    case 'privateTransfer':
      return control.flow.setupHook?.cart?.items?.find(
        item =>
          item?.productId === PRODUCT_IDS.PrivateTransferArrivalLarge ||
          item?.productId === PRODUCT_IDS.PrivateTransferArrivalSmall ||
          item?.productId === PRODUCT_IDS.PrivateTransferDepartureLarge ||
          item?.productId === PRODUCT_IDS.PrivateTransferDepartureSmall
      )
    case 'busTransfer':
      return control.flow.setupHook?.cart?.items?.find(
        item => item?.productId === PRODUCT_IDS.TransferTwoWay
      )
    case 'highlandBaseTransfer':
      return control.flow.setupHook?.cart?.items?.find(
        item =>
          item.productId === HB_PRODUCT_IDS.TransferSkjolHb ||
          item.productId === HB_PRODUCT_IDS.TransferHbSkjol ||
          item.productId === HB_PRODUCT_IDS.TransferHbRvk ||
          item.productId === HB_PRODUCT_IDS.TransferRvkHb
      )
    case 'highlandBaseBuggyRide':
      return control.flow.setupHook?.cart?.items?.find(
        item =>
          item?.productId === HB_PRODUCT_IDS.BuggySingleRide ||
          item?.productId === HB_PRODUCT_IDS.BuggyDoubleRide
      )
    case 'Snowmobile':
      return control.flow.setupHook?.cart?.items?.find(
        item =>
          item?.productId === HB_PRODUCT_IDS.SnowmobileDoubleRide ||
          item?.productId === HB_PRODUCT_IDS.SnowmobileSingleRide
      )
    case 'mossRestaurant':
      return control.flow.setupHook?.cart?.items?.find(
        item => item?.productId === PRODUCT_IDS.RestaurantMoss
      )
    default:
      break
  }
}
