import type { NextRouter } from 'next/router'
import get from 'lodash/get'

import type {
  FlowControl,
  FlowField,
  FlowLayout,
  FlowRootChild,
  FlowScreen,
  FlowValidator,
} from '../types'
import { UI_STATE_DIRTY_FIELDS } from './constants'

const getLastPathInRoute = (path: string) => path.split('/').pop()

export const getNavigation = (
  flow: FlowRootChild[],
  context: FlowControl['context']
): FlowControl['navigation'] => {
  let currentScreenIndex = 0

  const screens: FlowScreen[] = []
  const layouts: FlowLayout[] = []
  const screenLookup: Record<string, FlowScreen> = {}
  const screenIdToIndexLookup: Record<string, number> = {}
  const routeToScreen: Record<string, FlowScreen> = {}

  const addScreen = (screen: FlowScreen) => {
    screens.push(screen)
    screenLookup[currentScreenIndex] = screen
    screenIdToIndexLookup[screen.id] = currentScreenIndex

    // Route can be a function for dynamic routes or an object for static routes
    if (typeof screen?.route === 'function') {
      routeToScreen[
        getLastPathInRoute(screen.route(undefined)[context.locale])
      ] = screen
    } else if (screen?.route?.[context.locale]) {
      routeToScreen[getLastPathInRoute(screen.route[context.locale])] = screen
    }

    currentScreenIndex += 1
  }

  for (const screen of flow) {
    addScreen(screen)
  }

  return {
    screens,
    layouts,
    screenLookup,
    screenIdToIndexLookup,
    routeToScreen,
  }
}

const addValidator = (
  validator: FlowValidator,
  screenId: string,
  fieldId: string,
  screenIndex: number,
  validatorsByScreenId: Record<string, Record<string, FlowValidator>>,
  validatorsByScreenIndex: Record<string, Record<string, FlowValidator>>
) => {
  if (validatorsByScreenId[screenId] === undefined) {
    validatorsByScreenId[screenId] = {}
  }
  validatorsByScreenId[screenId][fieldId] = validator

  if (validatorsByScreenIndex[screenIndex] === undefined) {
    validatorsByScreenIndex[screenIndex] = {}
  }
  validatorsByScreenIndex[screenIndex][fieldId] = validator
}

const FieldRequiredValidator: FlowValidator = value => {
  if (!value) {
    return 'Required'
  }
}

export const getValidators = (
  navigation: FlowControl['navigation']
): FlowControl['validators'] => {
  const validators: FlowValidator[] = []
  const validatorsByScreenIndex: Record<
    string,
    Record<string, FlowValidator>
  > = {}
  const validatorsByScreenId: Record<string, Record<string, FlowValidator>> = {}

  navigation.screens.forEach((screen, index) => {
    const fields = getScreenFields(screen)
    for (const field of fields) {
      if (!field.validation) {
        if (field?.props?.required) {
          validators.push(FieldRequiredValidator)
          addValidator(
            FieldRequiredValidator,
            screen.id,
            field.id,
            index,
            validatorsByScreenId,
            validatorsByScreenIndex
          )
        }
        continue
      }

      const { validator } = field.validation

      const fieldId = field.id

      if (typeof validator === 'function') {
        validators.push(validator)

        addValidator(
          validator,
          screen.id,
          fieldId,
          index,
          validatorsByScreenId,
          validatorsByScreenIndex
        )
      }
    }
  })

  return {
    validators,
    validatorsByScreenIndex,
    validatorsByScreenId,
  }
}

export const validateScreenState = (
  screenId: string,
  state: Record<string, any> = {},
  control: FlowControl,
  onlyDirty?: boolean
): { errors: Record<string, string>; hasErrors: boolean } => {
  const errors: Record<string, string> = {}
  let hasErrors = false

  const screenValidators = control.validators.validatorsByScreenId[screenId]

  if (screenValidators === undefined) {
    return { errors, hasErrors }
  }

  const fieldIds = Object.keys(screenValidators)
  const filteredFieldIds = onlyDirty
    ? fieldIds.filter(
        id =>
          control.screen.uiStateRef.current?.[UI_STATE_DIRTY_FIELDS]?.[id] ===
          true
      )
    : fieldIds

  for (const fieldId of filteredFieldIds) {
    const validator = screenValidators[fieldId]
    const fieldValue = get(state, fieldId)

    if (typeof validator !== 'function') {
      continue
    }

    const error = validator(fieldValue, state, control)

    if (error) {
      errors[fieldId] = error as string
      hasErrors = true
    }
  }

  return { errors, hasErrors }
}

export const validateFlowState = (
  flowState: Record<string, Record<string, any>>,
  control: FlowControl
) => {
  const screenIds = Object.keys(flowState)

  let hasErrors = false
  const errors: Record<string, Record<string, string>> = {}

  for (const screenId of screenIds) {
    const screenValidators = control.validators.validatorsByScreenId[screenId]

    if (!screenValidators) {
      continue
    }

    const screenState = flowState[screenId]
    const screenValidation = validateScreenState(screenId, screenState, control)

    if (screenValidation.hasErrors) {
      hasErrors = true
      errors[screenId] = screenValidation.errors
    }
  }

  return { errors, hasErrors }
}

export const getScreenFields = (screen: FlowScreen) =>
  Object.keys(screen.fields).reduce((fields, slotKey) => {
    fields.push(...screen.fields[slotKey])

    return fields
  }, [])
interface FieldIdAndDefaultValue {
  id: string
  defaultValue: any
}

export const getFieldsWithDefaultValue = (
  field: FlowField
): FieldIdAndDefaultValue[] => {
  const result: FieldIdAndDefaultValue[] = []

  if (field.role === 'input') {
    result.push({
      id: field.id,
      defaultValue: field.defaultValue,
    })
  } else if (field.role === 'custom' && field.defaultValue != null) {
    result.push(
      ...Object.keys(field.defaultValue).map(key => ({
        id: key,
        defaultValue: field.defaultValue[key],
      }))
    )
  } else {
    result.push({
      id: field.id,
      defaultValue: undefined,
    })
  }

  return result
}

export const canNavigateToScreen = (
  control: FlowControl,
  screen: FlowScreen
) => {
  if (!screen.condition) {
    return true
  }

  return (
    typeof screen?.condition === 'function' &&
    screen.condition(control) === true
  )
}

const nextScreenIsWithinBounds = (
  control: FlowControl,
  direction: number,
  nextScreenIndex: number
) =>
  direction === 1
    ? nextScreenIndex < control.navigation.screens.length - 1
    : nextScreenIndex > 0

export const findNextAvailableScreen = (
  control: FlowControl,
  startScreenIndex: number,
  directionName: 'previous' | 'next'
): number | null => {
  const direction = directionName === 'previous' ? -1 : 1

  let nextScreenIndex = startScreenIndex
  let nextScreen = control.navigation.screens[nextScreenIndex]

  while (
    nextScreenIsWithinBounds(control, direction, nextScreenIndex) &&
    !canNavigateToScreen(control, nextScreen)
  ) {
    nextScreenIndex = nextScreenIndex + direction
    nextScreen = control.navigation.screens[nextScreenIndex]
  }

  if (!nextScreen) {
    return null
  }

  return canNavigateToScreen(control, nextScreen) ? nextScreenIndex : null
}

/**
 * This function checks if it is possible to start at the given initial screen
 * based on the screen's condition, if not it tries to go forward to the first
 * available screen
 */
export const getInitialScreenIndex = (
  control: FlowControl,
  indexToStartFrom: number,
  router?: NextRouter
): number | null => {
  let initialScreenIndex = indexToStartFrom

  // Map the slug to the screen index
  if (router?.query?.slug) {
    // Get slug to screenId.
    // Because of localization, we first need to get the screenId from routeToScreen.
    // For example: 'adgangur' does not exist in screenIdToIndexLookup but 'admission' does.
    // We use the last entry in the slug array, because later in some flows we have the admission
    // in the URL. e.g. /comfort/time
    const screenId =
      control.navigation.routeToScreen[
        router.query.slug?.[router.query.slug.length - 1]
      ]?.id

    if (
      screenId != null &&
      control.navigation.screenIdToIndexLookup?.[screenId] != null
    ) {
      // Get screenIndex from screenId:
      initialScreenIndex = control.navigation.screenIdToIndexLookup[screenId]
    }
  }

  // Reset if we are out of bounds
  if (
    initialScreenIndex < 0 ||
    initialScreenIndex >= control.navigation.screens.length
  ) {
    initialScreenIndex = 0
  }

  const initialScreen = control.navigation.screens[initialScreenIndex]

  if (!initialScreen) {
    return null
  }

  // Code below causes the products page to be skipped if the user refreshes on that page.
  // Leaving it commented out for now, but we should revisit this if we see any issue related.

  if (initialScreen.condition) {
    initialScreenIndex = findNextAvailableScreen(
      control,
      initialScreenIndex,
      'next'
    )
  }

  return initialScreenIndex
}
