import { useEffect, useState } from 'react'

import { sentryLogging } from 'sentry-utils/logging'

import {
  CardTypes,
  type SecureFieldsData,
  type SecureFieldsSuccessData,
} from './types'
import type { FormStrings, MappedCallbacks, SecureFields } from './types'

export const useSecureFieldsEvents = ({
  transactionId,
  secureFields,
  callbacks,
  formStrings,
}: {
  transactionId: string
  secureFields?: SecureFields
  callbacks?: MappedCallbacks
  formStrings: FormStrings
}) => {
  const [fieldErrors, setFieldErrors] = useState<{
    cardNumber: string | undefined
    cvv: string | undefined
  }>({
    cardNumber: undefined,
    cvv: undefined,
  })
  const [successData, setSuccessData] = useState<
    SecureFieldsSuccessData | undefined
  >(undefined)
  const [focusedField, setFocusedField] = useState<string | null>(null)
  const [generalError, setGeneralError] = useState<string | null>(null)
  const [isLoading, setIsLoading] = useState(true)
  const [paymentMethod, setPaymentMethod] = useState<CardTypes | undefined>(
    undefined
  )

  useEffect(() => {
    setSuccessData(undefined)
  }, [transactionId])

  const handleFieldEvents = (data: SecureFieldsData, validateEvent = false) => {
    const { fields, event } = data
    const isChangeEvent = event?.type === 'keyUp'
    const isBlurEvent = event?.type === 'blur'
    const isFocusEvent = event?.type === 'focus'

    const setErrorMessages = (field: 'cardNumber' | 'cvv') => {
      let newError: string | undefined = undefined

      const errorObject = {
        cardNumber: {
          notSupported: formStrings.cardNumber.notSupported,
          invalid: formStrings.cardNumber.invalid,
          required: formStrings.cardNumber.required,
        },
        cvv: {
          invalid: formStrings.cvv.invalid,
          required: formStrings.cvv.required,
        },
      }

      if (field === 'cardNumber' && fields.cardNumber?.paymentMethod) {
        const isSupported = Object.values(CardTypes).includes(
          fields.cardNumber.paymentMethod
        )
        if (!isSupported) {
          newError = errorObject.cardNumber.notSupported
        }
      }

      const fieldValidation = fields[field]
      if (fieldValidation && !fieldValidation.valid) {
        if (fieldValidation?.length && fieldValidation.length > 0) {
          newError = errorObject[field].invalid
        } else {
          newError = errorObject[field].required
        }
      }

      setFieldErrors(prev => ({
        ...prev,
        [field]: newError,
      }))
    }

    if (validateEvent) {
      setErrorMessages('cardNumber')
      setErrorMessages('cvv')
      return
    }

    if (isChangeEvent && event?.field === 'cardNumber') {
      setPaymentMethod(fields.cardNumber?.paymentMethod)
    }

    if (isFocusEvent) {
      setFocusedField(event.field)
    }

    if (
      event.field &&
      (isBlurEvent ||
        // This is a hack to handle the autofill event on cardNumber
        (isChangeEvent &&
          event.field === 'cardNumber' &&
          fields.cardNumber?.valid))
    ) {
      setErrorMessages(event.field)
      setFocusedField(null)
    }

    callbacks?.onValidate?.(data)
  }

  useEffect(() => {
    if (!secureFields) {
      return
    }

    secureFields.on('ready', () => {
      setIsLoading(false)
      callbacks?.onReady?.()
    })
    secureFields.on('change', data => {
      handleFieldEvents(data)
      callbacks?.onChange?.(data)
    })

    secureFields.on('validate', data => handleFieldEvents(data, true))
    secureFields.on('error', data => {
      if (typeof data === 'string') {
        setGeneralError(data)
        // If data is a string, it's an initialization error
        callbacks?.onError?.('initializationError')
        sentryLogging({
          message: `SecureFields onError callback: ${data}`,
          team: 'team-frontend-infrastructure',
          extras: { data, paymentMethod, transactionId },
        })
      } else {
        const errorString = `${data.action}: ${data.error}`
        setGeneralError(errorString)
        callbacks?.onError?.(errorString)
        sentryLogging({
          message: `SecureFields onError callback: ${errorString}`,
          team: 'team-frontend-infrastructure',
          extras: { data, paymentMethod, transactionId },
        })
      }
      setIsLoading(false)
    })
    secureFields.on('success', data => {
      setSuccessData(data)
      callbacks?.onSuccess?.(data)
    })
  }, [secureFields, paymentMethod])

  return {
    successData,
    fieldErrors,
    generalError,
    isLoading,
    focusedField,
    paymentMethod,
  }
}
