import {
  DoubleLayout,
  FullScreenLayout,
  ImageLayout,
  SidebarLayout,
} from '../layouts'
import { SmallImageLayout } from '../layouts/SmallImageLayout'
import type { FlowComponent, FlowLayout } from '../types'

interface LayoutRendererProps extends FlowComponent {
  item: FlowLayout | null
}

export const LayoutRenderer: React.FC<LayoutRendererProps> = ({
  item,
  control,
  screenTheme,
  columnStyle,
}) => {
  if (!item) {
    return null
  }

  const fields =
    typeof item.fields === 'function' ? item.fields(control) : item.fields

  switch (item.subType) {
    case 'image':
      return (
        <ImageLayout
          {...item.props}
          fields={fields}
          control={control}
          key={item.props.layoutId}
          screenTheme={screenTheme}
          columnStyle={columnStyle}
        />
      )
    case 'media':
      return (
        <SmallImageLayout
          {...item.props}
          fields={fields}
          control={control}
          key={item.props.layoutId}
          screenTheme={screenTheme}
          columnStyle={columnStyle}
        />
      )
    case 'fullscreen':
      return (
        <FullScreenLayout
          {...item.props}
          control={control}
          key={item.props.id}
          screenTheme={screenTheme}
          fields={fields}
          columnStyle={columnStyle}
        />
      )
    case 'double':
      return (
        <DoubleLayout
          {...item.props}
          control={control}
          key={item.props.id}
          screenTheme={screenTheme}
          fields={fields}
          columnStyle={columnStyle}
        />
      )
    case 'sidebar':
      return (
        <SidebarLayout
          {...item.props}
          control={control}
          key={item.props.id}
          screenTheme={screenTheme}
          fields={fields}
          columnStyle={columnStyle}
        />
      )
  }
}
