import type { MessageDescriptor } from 'react-intl'

import type { FlowValidator } from 'bl-flows-core'

export const createRequiredValidator: (
  message: MessageDescriptor
) => FlowValidator = message => (value, _, control) => {
  if (!value) {
    return control.context.t(message)
  }
}
