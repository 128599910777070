import { Spinner } from 'bl-common/src/elements/Spinner'
import { SpinnerWrapper } from 'bl-common/src/elements/SpinnerWrapper'
import { theme } from 'bl-common/src/styles/theme'
import {
  buildAddProductCardField,
  buildButton,
  buildConfirmationSummaryField,
  buildCustomField,
  buildFlow,
  buildHeading,
  buildProgressButton,
  buildScreenWithImageLayout,
  type BuildSubFlow,
  type FlowImageLayout,
} from 'bl-flows-core'
import { CartType, type Product, useProductsCatalogQuery } from 'bl-graphql'
import { PRODUCT_IDS } from 'bl-utils/src/ProductIds'

import { globalBookingMessages, productIdsMessages } from '../../messages'
import { skincareMessages } from './messages/skincareMessages'

const imageLayoutProps: FlowImageLayout['props'] = {
  layoutId: 'skincare-image-layout',
  layoutImageSrc:
    'https://images.ctfassets.net/w65k7w0nsb8q/6BUZVlxw4TKW9006bQ5NL0/9a037f1ccf3397b6095673d83ffe4a26/silica-mud-mask.jpeg',
}

interface CartItem {
  id: string
  type: string
}

export interface SkincareFlowInitialData {
  isRetreatSpaFlow?: boolean
}

export const buildSkincareFlow: BuildSubFlow<CartItem> = (
  onAddToCart,
  onClose,
  initialData?: SkincareFlowInitialData
) =>
  buildFlow({
    id: 'skincareFlow',
    routerSettings: {
      updateHistory: false,
    },
    settings: {
      resetWhenOpened: true,
    },
    setupHook: () => {
      const productCatalogState = useProductsCatalogQuery({
        variables: {
          input: {
            type: CartType.Dayspa,
          },
        },
      })

      return {
        products: productCatalogState.data?.productsCatalog,
        productsLoading: productCatalogState.loading,
      }
    },
    children: [
      buildScreenWithImageLayout({
        id: 'about',
        subType: 'form',
        layoutProps: imageLayoutProps,
        columnStyle: true,
        theme: initialData?.isRetreatSpaFlow ? 'retreat' : 'default',
        breadcrumb: control => {
          return {
            title: control.context?.t(skincareMessages.info.aboutBreadcrumb),
          }
        },
        fields: {
          main: [
            ...buildHeading({
              title: control =>
                control.context?.t(skincareMessages.info.aboutTitle),
              subTitle: control =>
                control.context?.t(skincareMessages.info.aboutSubtitle),
              includeBreadcrumb: true,
              imageSrc: imageLayoutProps.layoutImageSrc,
              imageLayout: { display: { bmd: 'none' } },
            }),
            buildButton({
              props: {
                label: control =>
                  control.context.t(skincareMessages.info.aboutContinueButton, {
                    discount: control.flow.setupHook.products?.find(
                      (product: Product) =>
                        product.productId === PRODUCT_IDS.SilicaMudMask75ml
                    ).fixedDiscountPercentage,
                  }),
                onClick: control => control.nextScreen(),
              },
              layout: {
                marginTopAuto: { xs: true },
                width: { xs: `calc(100% + ${theme.spacing[2]})` },
                spacing: {
                  ml: { xs: -1 },
                  mr: { xs: -1 },
                },
              },
            }),
          ],
        },
      }),
      buildScreenWithImageLayout({
        id: 'chooseProduct',
        subType: 'form',
        layoutProps: imageLayoutProps,
        columnStyle: true,
        theme: initialData?.isRetreatSpaFlow ? 'retreat' : 'default',
        breadcrumb: control => {
          return {
            title: control.context?.t(
              skincareMessages.info.chooseProductBreadcrumb
            ),
          }
        },
        fields: {
          main: [
            ...buildHeading({
              title: control =>
                control.context?.t(skincareMessages.info.chooseProductTitle),
              subTitle: control =>
                control.context?.t(
                  skincareMessages.info.chooseProductSubtitle,
                  {
                    discount: control.flow.setupHook.products?.find(
                      (product: Product) =>
                        product.productId === PRODUCT_IDS.SilicaMudMask75ml
                    ).fixedDiscountPercentage,
                  }
                ),
              includeBreadcrumb: true,
            }),
            // using localization for this while we only have one product, later we'll probably get this from contentful
            // also defaultValue, id, price and discount will get their productId from mapping over contentful products
            buildAddProductCardField({
              defaultValue: { [PRODUCT_IDS.SilicaMudMask75ml]: 1 },
              id: PRODUCT_IDS.SilicaMudMask75ml,
              condition: control => !control.flow.setupHook?.productsLoading,
              props: {
                title: control =>
                  control.context.t(productIdsMessages.BL1B01F1),
                description: control =>
                  control.context.t(
                    skincareMessages.info.silicaMudMaskDescription
                  ),
                price: control =>
                  control.flow.setupHook.products?.find(
                    (product: Product) =>
                      product.productId === PRODUCT_IDS.SilicaMudMask75ml
                  ).price,
                discountedPrice: control =>
                  control.flow.setupHook.products?.find(
                    (product: Product) =>
                      product.productId === PRODUCT_IDS.SilicaMudMask75ml
                  ).discountedPrice,
                hasImage: false,
              },
            }),
            // Build spinner
            buildCustomField({
              defaultValue: null,
              condition: control => control.flow.setupHook?.productsLoading,
              props: {
                component: () => {
                  return (
                    <SpinnerWrapper>
                      <Spinner shouldAnimate />
                    </SpinnerWrapper>
                  )
                },
              },
            }),
            buildProgressButton({
              props: {
                label: control =>
                  control.context.t(skincareMessages.info.addToCartButton),
                onClick: async control => {
                  const { hasErrors } = control.validateAndSetScreenErrors()

                  if (hasErrors) {
                    return false
                  }

                  control.screen.setState({
                    isAddingToCart: true,
                  })

                  const success = await onAddToCart(
                    {
                      id: '123',
                      type: 'massage',
                    },
                    control
                  )

                  control.screen.setState({
                    isAddingToCart: false,
                  })

                  if (!success) {
                    control.screenErrors.setState({
                      error: control.context.t(
                        skincareMessages.errors.addProductFailedError
                      ),
                    })

                    return false
                  }

                  return true
                },
                onComplete: control => control.nextScreen(),
              },
              layout: {
                marginTopAuto: { xs: true },
                width: { xs: `calc(100% + ${theme.spacing[2]})` },
                spacing: {
                  ml: { xs: -1 },
                  mr: { xs: -1 },
                  pt: { xs: 1.5 },
                },
              },
            }),
          ],
        },
      }),

      buildScreenWithImageLayout({
        id: 'confirmation',
        subType: 'form',
        layoutProps: imageLayoutProps,
        breadcrumb: false,
        columnStyle: true,
        theme: 'blue',
        fields: {
          main: [
            ...buildHeading({
              title: control => {
                return control.context.t(
                  skincareMessages.info.confirmationTitle
                )
              },
              subTitle: control => {
                return control.context.t(
                  skincareMessages.info.confirmationSubtitle
                )
              },
            }),
            buildConfirmationSummaryField({
              id: 'confirmationSummary',
              defaultValue: null,
              props: {
                type: 'products',
                products: control => [
                  // While we're just selling Silica Mud Mask, this is hard coded for that,
                  // when we start selling more products this setup will change
                  {
                    productId: PRODUCT_IDS.SilicaMudMask75ml,
                    title: control.context.t(
                      productIdsMessages[PRODUCT_IDS.SilicaMudMask75ml]
                    ),
                    qty: control.flow.state?.chooseProduct?.[
                      PRODUCT_IDS.SilicaMudMask75ml
                    ],
                  },
                ],
              },
            }),
            buildButton({
              props: {
                label: control =>
                  control.context.t(globalBookingMessages.buttons.closeWindow),
                onClick: control => onClose(control),
              },
              layout: {
                marginTopAuto: { xs: true },
                width: { xs: `calc(100% + ${theme.spacing[2]})` },
                spacing: {
                  ml: { xs: -1 },
                  mr: { xs: -1 },
                  mt: { xs: 5 },
                },
              },
            }),
          ],
        },
      }),
    ],
  })
