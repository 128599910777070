import { type PropsWithChildren, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { useRouter } from 'next/router'
import isEqual from 'lodash/isEqual'

import { InfoBox } from 'bl-common/src/booking/InfoBox/InfoBox'
import { SimpleAccordion } from 'bl-common/src/booking/SimpleAccordion/SimpleAccordion'
import { colors } from 'bl-common/src/constants/colors'
import { Spinner } from 'bl-common/src/elements/Spinner'
import { SpinnerWrapper } from 'bl-common/src/elements/SpinnerWrapper'
import { useBreakpoints } from 'bl-common/src/hooks/useBreakpoints'
import { ButtonLink } from 'bl-common/src/richText/RichTextBlocks'
import { theme } from 'bl-common/src/styles/theme'
import {
  buildBreadcrumbField,
  buildButton,
  buildCustomField,
  buildDisclaimerField,
  buildDrawerField,
  buildHeading,
  buildPickerField,
  buildScreenErrorField,
  buildScreenWithImageLayout,
  type FlowComponent,
} from 'bl-flows-core'
import { MembershipType } from 'bl-graphql'
import { triggerEvent } from 'bl-utils/src/analytics/events'
import { MembershipSubType } from 'bl-utils/src/membership'

import { globalBookingMessages } from '../../../messages'
import { getSpaImageLayoutProps } from '../../../utils'
import SubscriptionCardAccepted from '../components/SubscriptionCardAccepted'
import SubscriptionDrawer from '../components/SubscriptionDrawer'
import { SPA_ALLOWED_ADULTS } from '../constants'
import { commonMessages } from '../messages/common'
import { guestsMessages } from '../messages/guests'
import { calculateNumberOfGuests, type State } from '../utils'

type StateKeys = keyof State
type StateValues = State[StateKeys]

const isDefined = (value: unknown) => typeof value !== 'undefined'
const requiresReset = (previousValue: StateValues, nextValue: StateValues) =>
  isDefined(previousValue) && !isEqual(previousValue, nextValue)

type MembershipInfoBoxType = PropsWithChildren<FlowComponent> & {
  isSidebar: boolean
}
const MembershipInfoBox = ({ control, isSidebar }: MembershipInfoBoxType) => {
  return (
    <div
      style={{
        padding: isSidebar ? theme.spacing[4] : `${theme.spacing[4]} 0`,
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing[1],
      }}
    >
      <InfoBox
        title={control.context.t(
          globalBookingMessages.text.membershipLoginTitle
        )}
        content={
          control.context.membershipSettings.whichMembershipInfoToShow ===
          'winter'
            ? control.context.t(
                globalBookingMessages.text.bookWithWinterCardSubtitle
              )
            : control.context.t(
                globalBookingMessages.text.bookWithSummerCardSubtitle
              )
        }
      >
        {control.context.membershipSettings.isWinterCardAllowed && (
          <ButtonLink
            type="button"
            onClick={() =>
              control.screen.setUiState({
                ...control.screen.uiState,
                membershipType: MembershipType.Winter,
                isSubscriptionModalOpen: true,
              })
            }
            textColor="deepBlue"
            weight="bold"
            paddingSize="small"
            style={{
              overflow: 'visible',
            }}
          >
            {control.context.t(
              globalBookingMessages.buttons.bookWithWinterCard
            )}
          </ButtonLink>
        )}
        {control.context.membershipSettings.isSummerCardAllowed && (
          <ButtonLink
            type="button"
            onClick={() =>
              control.screen.setUiState({
                ...control.screen.uiState,
                membershipType: MembershipType.Summer,
                isSubscriptionModalOpen: true,
              })
            }
            textColor="deepBlue"
            weight="bold"
            paddingSize="small"
            style={{
              overflow: 'visible',
            }}
          >
            {control.context.t(
              globalBookingMessages.buttons.bookWithSummerCard
            )}
          </ButtonLink>
        )}
        <ButtonLink
          type="button"
          onClick={() =>
            control.flow.setupHook?.router?.push(
              control.context.membershipSettings.whichMembershipInfoToShow ===
                'summer'
                ? control.context.t(
                    globalBookingMessages.buttons.summerCardDetailsLink
                  )
                : control.context.t(
                    globalBookingMessages.buttons.winterCardDetailsLink
                  )
            )
          }
          textColor="deepBlue"
          weight="bold"
          paddingSize="small"
          style={{
            overflow: 'visible',
          }}
        >
          {control.context.t(globalBookingMessages.buttons.details)}
        </ButtonLink>
      </InfoBox>
    </div>
  )
}

const SubscriptionDrawerComponent = (
  props: PropsWithChildren<FlowComponent>
) => {
  return (
    <SubscriptionDrawer
      control={props.control}
      membershipType={props.control.screen.uiState.membershipType}
      {...props}
      onContinue={() => {
        props.control.screen.setState(
          {
            adults: 1,
            children: 0,
          },
          true
        )
        props.control.screen.setUiState({
          ...props.control.screen.uiState,
          isSubscriptionModalOpen: false,
        })
      }}
    />
  )
}

const buildMembershipInfoBox = (isSidebar: boolean) => {
  return buildCustomField({
    condition: control => {
      const mediaBmd = control.screen.setupHook?.mediaBmd
      const isMembershipTokenNull =
        control.flow.setupHook?.cart?.membership?.membershipToken == null
      const isLocaleIcelandic = control.context.locale === 'is'

      return (
        isMembershipTokenNull && isLocaleIcelandic && (isSidebar || !mediaBmd)
      )
    },
    defaultValue: null,
    props: {
      component: props => (
        <MembershipInfoBox isSidebar={isSidebar} {...props} />
      ),
    },
  })
}

const buildSubscriptionDrawerField = buildDrawerField({
  props: {
    isOpen: control => control.screen.uiState.isSubscriptionModalOpen,
    onHide: (_, control) => {
      control.screen.setUiState({
        ...control.screen.uiState,
        isSubscriptionModalOpen: false,
      })
    },
  },
  children: [
    buildCustomField({
      defaultValue: null,
      props: {
        component: SubscriptionDrawerComponent,
      },
    }),
  ],
})

export const spaGuestScreen = () => {
  return buildScreenWithImageLayout({
    id: 'guests',
    subType: 'form',
    uiState: {
      isSubscriptionModalOpen: false,
    },
    route: () => {
      return {
        en: 'guests',
        is: 'gestir',
      }
    },
    setupHook: control => {
      const { mediaBmd } = useBreakpoints()
      const { query } = useRouter()
      useEffect(() => {
        const login = query.login
        // Check if we have login=summer or winter in the query strings.
        if (
          login === 'summer' ||
          (login === 'winter' &&
            !control.flow.state.hasInitiallyOpenedSubscriptionModal)
        ) {
          control.flow.setStateWithoutValidation({
            ...control.flow.state,
            hasInitiallyOpenedSubscriptionModal: true,
          })
          control.screen.setUiState({
            ...control.screen.uiState,
            membershipType:
              login === 'summer'
                ? MembershipType.Summer
                : MembershipType.Winter,
            isSubscriptionModalOpen: true,
          })
        }
      }, [])

      return {
        mediaBmd,
      }
    },
    queryParams: control => {
      return {
        adults: (control.screen?.state?.adults || 1).toString(),
        children: (control.screen?.state?.children || 0).toString(),
      }
    },
    layoutProps: control =>
      getSpaImageLayoutProps('comfort', 'guests', control),
    breadcrumb: control => {
      const guests = calculateNumberOfGuests(control.flow.state.guests)

      return {
        title: control.context.t(guestsMessages.info.breadcrumbTitle),
        value:
          !!control.flow.state.guests &&
          control.context.t(guestsMessages.info.breadcrumbSubtitle, {
            guests,
          }),
      }
    },
    fields: {
      main: [
        buildBreadcrumbField({
          props: {
            breadcrumb: control => control.screen.breadcrumb,
          },
          layout: {
            spacing: {
              mb: { xs: 3 },
            },
          },
        }),
        buildCustomField({
          condition: control => {
            return (
              control.flow.setupHook?.cart?.membership?.membershipToken != null
            )
          },
          props: {
            render: () => {
              const { formatMessage } = useIntl()
              return (
                <SubscriptionCardAccepted
                  acceptedText={formatMessage(
                    globalBookingMessages.text.winterCardAccepted
                  )}
                  removeButtonLabel={formatMessage(
                    globalBookingMessages.buttons.remove
                  )}
                />
              )
            },
          },
        }),
        ...buildHeading({
          title: control => control.context.t(guestsMessages.info.title),
          subTitle: control =>
            control.context.t(
              control.flow.setupHook?.cart?.membership?.membershipToken != null
                ? guestsMessages.info.subtitleMembership
                : guestsMessages.info.subtitle
            ),
          removeMarginTop: true,
        }),

        buildPickerField({
          id: 'adults',
          defaultValue: 1,
          props: {
            label: control =>
              control.context.t(guestsMessages.info.adultPickerLabel),
            min: 1,
            max: control =>
              control.flow.setupHook?.cart?.membership?.membershipToken == null
                ? SPA_ALLOWED_ADULTS
                : control.flow.setupHook?.cart?.membership?.subType ===
                    MembershipSubType.INDIVIDUAL
                  ? 1
                  : 2,
            onDisabledIncrementClick(value, control) {
              if (value >= SPA_ALLOWED_ADULTS) {
                control.screen.setUiState({
                  showGuestLimit: true,
                })
              }
            },
            onChange: (value, control) => {
              if (value < SPA_ALLOWED_ADULTS) {
                control.screen.setUiState({
                  showGuestLimit: false,
                })
              }
            },
          },
          layout: {
            spacing: {
              mt: { xs: 4, bmd: 2.5 },
              mb: { xs: 3, bmd: 2 },
            },
          },
          validation: {
            renderErrorInFieldRenderer: false,
            validator: (fieldValue: number, _, control) => {
              const membership = control.flow.setupHook?.cart?.membership
              const maxAdults =
                membership?.subType === MembershipSubType.INDIVIDUAL ? 1 : 2
              if (membership?.membershipToken != null) {
                if (fieldValue > maxAdults) {
                  return control.context.t(
                    control.context.isSummerCardAllowed
                      ? guestsMessages.errors.summerCardGuestRestriction
                      : guestsMessages.errors.winterCardGuestRestriction,
                    {
                      numberOfAdults: maxAdults,
                      numberOfChildren: maxAdults * 2,
                    }
                  )
                }
              }
              if (fieldValue > SPA_ALLOWED_ADULTS) {
                return control.context.t(guestsMessages.errors.tooManyGuests)
              }
              if (fieldValue < 1) {
                return control.context.t(guestsMessages.errors.missingAdult)
              }
            },
          },
        }),
        buildPickerField({
          id: 'children',
          defaultValue: 0,
          props: {
            label: control =>
              control.context.t(guestsMessages.info.childrenPickerLabel),
            value: 0,
            min: 0,
            max: control => control.screen.state.adults * 2,
          },
          validation: {
            renderErrorInFieldRenderer: false,
            validator: (numberOfChildren: number, screenValues, control) => {
              const { adults } = screenValues
              if (numberOfChildren > adults * 2) {
                return control.context.t(guestsMessages.errors.tooManyChildren)
              }
            },
          },
          layout: {
            spacing: {
              mb: { xs: 5, bmd: 3 },
            },
          },
        }),
        buildDisclaimerField({
          props: {
            value: control =>
              control.context.t(guestsMessages.warnings.numberOfAdults, {
                numberOfAdults: SPA_ALLOWED_ADULTS,
              }),
            color: colors?.orange,
            weight: 'bold',
            showDisclaimer: control => !!control.screen.uiState?.showGuestLimit,
          },
          layout: {
            spacing: {
              mb: { xs: 1 },
            },
          },
        }),
        buildDisclaimerField({
          props: {
            value: control =>
              control.context.t(guestsMessages.warnings.floaties),
            color: colors?.orange,
            weight: 'bold',
            showDisclaimer: control =>
              control.screen.stateRef?.current?.children > 0,
          },
        }),
        // Build spinner
        buildCustomField({
          defaultValue: null,
          condition: control => {
            return !!control.screen.uiState.isResettingCart
          },
          props: {
            component: () => {
              return (
                <SpinnerWrapper>
                  <Spinner shouldAnimate />
                </SpinnerWrapper>
              )
            },
          },
          layout: {
            spacing: {
              pt: { xs: 2 },
            },
          },
        }),
        buildButton({
          condition: control => {
            return !control.screen.uiState.isResettingCart
          },
          props: {
            label: control => control.context.t(guestsMessages.info.continue),
            isDisabled: control => {
              return (
                control.flow.setupHook?.loading ||
                control.screen.uiState.isResettingCart
              )
            },
            onClick: async control => {
              // Re-create the cart if cart was last updated over 30 minutes ago
              // Otherwise the backend could have expired the cart in LS
              // and cause potential issues when adding admission to the cart.
              if (control.flow.setupHook.cartLastUpdated) {
                const cartLastUpdated = new Date(
                  control.flow.setupHook.cartLastUpdated
                )

                if (
                  cartLastUpdated.getTime() <
                  new Date().getTime() - 30 * 60 * 1000
                ) {
                  control.screen.setUiState({
                    isResettingCart: true,
                  })
                  try {
                    await control.flow.setupHook.createCart({ force: true })
                  } catch (e) {
                    console.error('Error creating cart', e)
                  }
                  control.screen.setUiState({
                    isResettingCart: false,
                  })
                }
              }
              if (
                control.flow.setupHook?.cart?.items?.length &&
                requiresReset(
                  control.flow.stateRef.current.guests,
                  control.screen.state as State['guests']
                )
              ) {
                const shouldContinue = await control.confirm(
                  control.context.t(commonMessages.warnings.modalResetState),
                  {
                    confirmLabel: control.context.t(
                      commonMessages.info.modalContinue
                    ),
                    cancelLabel: control.context.t(
                      commonMessages.info.modalCancel
                    ),
                    title: control.context.t(
                      commonMessages.info.modalTitleUpdateBooking
                    ),
                  }
                )

                if (!shouldContinue) {
                  control.screen.setUiState({
                    isResettingCart: false,
                  })
                  return
                }

                const isSignedInWithMembership =
                  control.flow.setupHook?.cart?.membership?.membershipToken

                if (control.flow.setupHook?.cart?.items?.length) {
                  control.screen.setUiState({
                    isResettingCart: true,
                  })

                  if (isSignedInWithMembership) {
                    // Remove items from cart
                    // We don't want to reset the cart if the user has signed in with membership card

                    for (
                      let i = 0;
                      i < control.flow.setupHook.cart.items.length;
                      i++
                    ) {
                      // Don't refetch cart until we remove the last item
                      const isLastItem =
                        i === control.flow.setupHook.cart.items.length - 1
                      await control.flow.setupHook.removeItemFromCart(
                        control.flow.setupHook.cart.items[i].id,
                        !isLastItem
                      )
                    }
                  } else {
                    await control.flow.setupHook.createCart({ force: true })
                  }
                }
                control.screen.setUiState({
                  isResettingCart: false,
                })

                control.flow.setState(
                  {
                    previousPath: control.flow.state.previousPath,
                  },
                  true
                )
              }

              if (control.nextScreen()) {
                triggerEvent({
                  event: 'funnel_guest',
                  BLType: 'Day Visit',
                  pageTitle: 'Book Guest',
                  pageCategory: 'book',
                  Adult: control.screen.state.adults || 1,
                  Children: control.screen.state.children || 0,
                  language: control.context.locale,
                })
              }
            },
          },
          layout: {
            spacing: {
              pt: { xs: 2, bmd: 2.5 },
            },
          },
        }),
        buildCustomField({
          defaultValue: null,
          layout: {
            spacing: {
              mt: { xs: 3, bmd: 2 },
            },
          },
          props: {
            render: control => {
              return (
                <SimpleAccordion
                  label={control.context.t(guestsMessages.info.disclaimerLabel)}
                  onClick={() => {
                    triggerEvent({
                      event: 'BE Event',
                      category: 'SPA',
                      action: 'Click',
                      label: 'age limit',
                    })
                  }}
                >
                  {control.context.t(guestsMessages.info.disclaimerContent)}
                </SimpleAccordion>
              )
            },
          },
        }),
        buildScreenErrorField({
          props: {},
          layout: {
            spacing: {
              mt: { xs: 1 },
            },
          },
        }),
        buildMembershipInfoBox(false),
        buildSubscriptionDrawerField,
      ],
      rightBottom: [buildMembershipInfoBox(true), buildSubscriptionDrawerField],
    },
  })
}
