import { theme } from 'bl-common/src/styles/theme'
import {
  buildAvailableDateTimesField,
  buildButton,
  buildCardButtonField,
  buildCustomField,
  buildDisclaimerField,
  buildFlow,
  buildHeading,
  buildItineraryField,
  buildOverviewField,
  buildProgressButton,
  buildScreenErrorField,
  buildScreenWithDoubleLayout,
  buildScreenWithImageLayout,
  type BuildSubFlow,
  type FlowImageLayout,
  type FlowItineraryField,
  type SubFlowActivity,
  type SubFlowBookingInfo,
} from 'bl-flows-core'
import { setDateTimeISOString } from 'bl-utils/src/date'
import { formatDateInUTC } from 'bl-utils/src/formatting/formatDate'
import { REYKJAVIK_TERMINAL_ID } from 'bl-utils/src/productUtils'

import { LocationPicker } from '../../components/LocationPicker/LocationPicker'
import { LocationMap } from '../../components/LocationPicker/Map'
import { globalBookingMessages } from '../../messages/global'
import {
  Direction,
  LocationType,
  type TransportationType,
} from '../../types/Transportation'
import { getItemsForBluelagoonTransportationOverview } from '../../utils'
import { transportationMessages } from './messages/transportationMessages'

const imageLayoutProps: FlowImageLayout['props'] = {
  layoutId: 'transportation-image-layout',
  layoutImageSrc:
    'https://images.ctfassets.net/w65k7w0nsb8q/kpKJI4oZOgmVRQyHSHjyT/960c12dfc4e272ee2af57c264d3ac511/Destiantion-Blue-Lagoon2.jpg',
}

// TODO - remove once we have a proper cart
interface CartItem {
  id: string
  title: string
  time: string
  type: string
  description: string
  price: number
}

export interface TransportationFlowInitialData {
  bookingInfo: SubFlowBookingInfo
  activities?: SubFlowActivity[]
  transportation?: TransportationType
  itineraryItems: FlowItineraryField['props']['items']
  date: string
  isRetreatSpaFlow?: boolean
}

const getTotalPrice = ({ price, adults }: { price: number; adults: number }) =>
  price * adults

export const buildTransportationFlow: BuildSubFlow<CartItem> = (
  onAddToCart,
  onClose,
  initialData?: TransportationFlowInitialData
) =>
  buildFlow({
    id: 'transportationFlow',
    settings: {
      resetWhenOpened: true,
    },
    routerSettings: {
      updateHistory: false,
    },
    setupHook: () => {
      return {
        price: initialData?.transportation?.price,
        adults: initialData?.bookingInfo?.adults,
      }
    },
    children: [
      buildScreenWithImageLayout({
        id: 'pickup',
        subType: 'form',
        layoutProps: imageLayoutProps,
        columnStyle: true,
        theme: initialData?.isRetreatSpaFlow ? 'retreat' : 'default',
        breadcrumb: control => ({
          title: control.context.t(
            transportationMessages.info.pickupLocationBreadcrumb
          ),
        }),
        fields: {
          main: [
            ...buildHeading({
              title: control =>
                control.context.t(transportationMessages.info.pickupTitle),
              includeBreadcrumb: true,
              imageSrc: imageLayoutProps.layoutImageSrc,
              imageLayout: { display: { bmd: 'none' } },
            }),
            buildCardButtonField({
              props: {
                ctaLabel: control =>
                  control.context.t(globalBookingMessages.buttons.select),
                description: control =>
                  control.context.t(globalBookingMessages.text.timeMinutes, {
                    minutes: 60,
                  }),
                title: control =>
                  control.context.t(transportationMessages.info.locationCity),
                price: initialData?.transportation?.price ?? 0,
                priceFormat: control =>
                  control.context.t(
                    transportationMessages.info.locationPriceRoundtrip
                  ),
                isClosed: false,
                inCart: control =>
                  control.flow.state?.pickup?.type === LocationType.city,
                inCartLabel: control =>
                  control.context.t(globalBookingMessages.labels.selected),
                onClick: control => {
                  // If the user has already chosen 'airport' and chosen a time for it,
                  // we need to clear the time they chose, because 'city' and 'airport' don't have the same slots
                  if (control.screen.state?.type === 'airport') {
                    control.flow.setState({
                      ...control.flow.state,
                      pickupTime: {
                        date: control.flow.state?.pickupTime?.date,
                        time: undefined,
                      },
                    })
                  }
                  control.screen.setState({
                    type: LocationType.city,
                    name: control.context.t(
                      transportationMessages.info.locationCity
                    ),
                  })
                  control.nextScreen()
                },
              },
              layout: {
                spacing: {
                  mb: { xs: 2 },
                },
              },
            }),
            buildCardButtonField({
              props: {
                ctaLabel: control =>
                  control.context.t(globalBookingMessages.buttons.select),
                description: control =>
                  control.context.t(globalBookingMessages.text.timeMinutes, {
                    minutes: 20,
                  }),
                title: control =>
                  control.context.t(
                    transportationMessages.info.locationAirport
                  ),
                price: initialData?.transportation?.price ?? 0,
                priceFormat: control =>
                  control.context.t(
                    transportationMessages.info.locationPriceRoundtrip
                  ),
                isClosed: false,
                inCart: control =>
                  control.flow.state?.pickup?.type === LocationType.airport,
                inCartLabel: control =>
                  control.context.t(globalBookingMessages.labels.selected),
                onClick: control => {
                  // If the user has already chosen 'city' and chosen a time for it,
                  // we need to clear the time they chose, because 'city' and 'airport' don't have the same slots
                  if (control.screen.state?.type === 'city') {
                    control.flow.setState({
                      ...control.flow.state,
                      pickupTime: {
                        date: control.flow.state?.pickupTime?.date,
                        time: undefined,
                      },
                    })
                  }

                  control.screen.setState({
                    type: LocationType.airport,
                    name: 'Keflavík Airport',
                  })
                  control.nextScreen()
                },
              },
            }),
            buildButton({
              condition: control => !!control.flow.state?.pickup?.type,
              props: {
                label: control =>
                  control.context.t(globalBookingMessages.buttons.continue),
                onClick: control => {
                  control.screen.setState({
                    type: control.flow.stateRef?.current?.pickup?.type,
                    name: control.context.t(
                      control.flow.stateRef?.current?.pickup?.type ===
                        LocationType.city
                        ? transportationMessages.info.locationCity
                        : transportationMessages.info.locationAirport
                    ),
                  })
                  control.nextScreen()
                },
              },
              layout: {
                marginTopAuto: { xs: true },
                position: { xs: 'sticky' },
                bottom: { xs: 0 },
                width: { xs: `calc(100% + ${theme.spacing[2]})` },
                spacing: {
                  ml: { xs: -1 },
                  mr: { xs: -1 },
                },
              },
            }),
          ],
        },
      }),
      buildScreenWithDoubleLayout({
        id: 'pickupLocation',
        subType: 'form',
        layoutProps: {
          id: 'double',
        },
        theme: initialData?.isRetreatSpaFlow ? 'retreat' : 'default',
        condition: control =>
          control.flow.stateRef.current.pickup?.type === LocationType.city,
        breadcrumb: control => ({
          title: control.context.t(
            transportationMessages.info.pickupLocationBreadcrumb
          ),
        }),
        uiState: {
          showMap: false,
        },
        fields: {
          main: [
            buildCustomField({
              defaultValue: {
                direction: Direction.pickup,
                // we have to initialize the location object to fix console errors
                location: {
                  address: '',
                  id: '',
                  latitude: 0,
                  longitude: 0,
                  name: '',
                },
              },
              props: {
                component: LocationPicker,
              },
            }),
          ],
          right: [
            buildCustomField({
              defaultValue: { direction: Direction.pickup },
              props: {
                component: LocationMap,
              },
            }),
          ],
        },
      }),
      buildScreenWithImageLayout({
        id: 'pickupTime',
        subType: 'form',
        layoutProps: imageLayoutProps,
        columnStyle: true,
        theme: initialData?.isRetreatSpaFlow ? 'retreat' : 'default',
        breadcrumb: control => ({
          title: control.context.t(
            transportationMessages.info.pickupTimeBreadcrumb
          ),
        }),
        fields: {
          main: [
            ...buildHeading({
              title: control =>
                control.context.t(transportationMessages.info.pickupTimeTitle),
              subTitle: control =>
                control.context.t(
                  transportationMessages.info.pickupTimeSubtitle
                ),
              includeBreadcrumb: true,
            }),
            buildDisclaimerField({
              condition: control =>
                control.flow.state.pickupLocation.location?.id !==
                  REYKJAVIK_TERMINAL_ID &&
                control.flow.state.pickup?.type !== LocationType.airport,
              props: {
                value: control =>
                  control.context.t(
                    transportationMessages.info.pickupTimeShuttleDisclaimer
                  ),

                preset: 'text',
              },
              layout: {
                spacing: {
                  pb: { xs: 1, md: 2 },
                },
              },
            }),

            buildItineraryField({
              condition: () => !!initialData?.itineraryItems?.length,
              props: {
                items: initialData?.itineraryItems,
                moreLabel: globalBookingMessages.buttons.plusItems,
              },
              layout: {
                spacing: {
                  pt: { xs: 1, md: 2 },
                  pb: { xs: 2 },
                },
              },
            }),
            buildAvailableDateTimesField({
              defaultValue: {
                date: initialData?.bookingInfo?.entryDate,
                time: initialData?.transportation?.pickupTime?.time,
              },
              props: {
                type: 'transportationPickup',
                bookingInfo: initialData?.bookingInfo,
                activities: initialData?.activities,
                currentReservationDate:
                  initialData?.bookingInfo?.entryDate &&
                  initialData?.transportation?.pickupTime?.time &&
                  setDateTimeISOString(
                    formatDateInUTC(
                      initialData?.bookingInfo?.entryDate,
                      'yyyy-MM-dd'
                    ),
                    initialData?.transportation?.pickupTime?.time
                  ),
                guests:
                  initialData?.bookingInfo?.adults +
                  initialData?.bookingInfo?.children,
                isHotelBooking: !!initialData?.bookingInfo?.hotelDates,
              },
              layout: {
                spacing: {
                  mb: { xs: 2 },
                },
              },
            }),
            buildButton({
              props: {
                label: control =>
                  control.context.t(globalBookingMessages.buttons.continue),
                onClick: control => {
                  control.nextScreen()
                },
                isDisabled: control => !control.screen?.state?.time,
              },
              layout: {
                marginTopAuto: { xs: true },
                position: { xs: 'sticky' },
                bottom: { xs: 0 },
                width: { xs: `calc(100% + ${theme.spacing[2]})` },
                spacing: {
                  ml: { xs: -1 },
                  mr: { xs: -1 },
                },
              },
            }),
          ],
        },
      }),
      buildScreenWithImageLayout({
        id: Direction.dropoff,
        subType: 'form',
        layoutProps: imageLayoutProps,
        theme: initialData?.isRetreatSpaFlow ? 'retreat' : 'default',
        breadcrumb: control => ({
          title: control.context.t(
            transportationMessages.info.dropoffLocationBreadcrumb
          ),
        }),
        fields: {
          main: [
            ...buildHeading({
              title: control =>
                control.context.t(transportationMessages.info.dropoffTitle),
              includeBreadcrumb: true,
            }),
            buildCardButtonField({
              props: {
                ctaLabel: control =>
                  control.context.t(globalBookingMessages.buttons.select),
                description: control =>
                  control.flow.state.pickupLocation?.pickupLocation,
                title: control =>
                  control.context.t(transportationMessages.info.sameAsPickup),
                price: initialData?.transportation?.price ?? 0,
                priceFormat: control =>
                  control.context.t(
                    transportationMessages.info.locationPriceRoundtrip
                  ),
                isClosed: false,
                inCart: control => control.flow.state?.dropoff?.sameAsPickup,
                inCartLabel: control =>
                  control.context.t(globalBookingMessages.labels.selected),
                onClick: control => {
                  // If the user has already chosen a location that is not the same as pickup and chosen a time for it,
                  // we need to clear the time they chose, because 'city' and 'airport' don't have the same slots
                  if (
                    control.screen.state?.type !==
                    control.flow.state.pickup?.type
                  ) {
                    control.flow.setState({
                      ...control.flow.state,
                      departureTime: {
                        date: control.flow.state?.departureTime?.date,
                        time: undefined,
                      },
                    })
                  }
                  control.flow.setState({
                    dropoffLocation: {
                      direction: Direction.dropoff,
                      location: control.flow.state.pickupLocation?.location,
                    },
                  })
                  control.screen.setState({
                    sameAsPickup: true,
                    ...control.flow.state?.pickup,
                  })
                  control.nextScreen()
                },
              },
              layout: {
                spacing: {
                  mb: { xs: 2 },
                },
              },
            }),
            buildCardButtonField({
              props: {
                ctaLabel: control =>
                  control.context.t(globalBookingMessages.buttons.select),
                description: control =>
                  control.context.t(globalBookingMessages.text.timeMinutes, {
                    minutes: 60,
                  }),
                title: control =>
                  control.context.t(transportationMessages.info.locationCity),
                price: initialData?.transportation?.price ?? 0,
                priceFormat: control =>
                  control.context.t(
                    transportationMessages.info.locationPriceRoundtrip
                  ),
                isClosed: false,
                inCart: control =>
                  control.flow.state?.dropoff?.type === LocationType.city &&
                  !control.flow.state?.dropoff?.sameAsPickup,
                inCartLabel: control =>
                  control.context.t(globalBookingMessages.labels.selected),
                onClick: control => {
                  // If the user has already chosen 'airport' and chosen a time for it,
                  // we need to clear the time they chose, because 'city' and 'airport' don't have the same slots
                  if (control.screen.state?.type === 'airport') {
                    control.flow.setState({
                      ...control.flow.state,
                      departureTime: {
                        date: control.flow.state?.departureTime?.date,
                        time: undefined,
                      },
                    })
                  }
                  control.screen.setState({
                    type: LocationType.city,
                    name: control.context.t(
                      transportationMessages.info.locationCity
                    ),
                  })
                  control.screen.setState({ sameAsPickup: false })
                  control.nextScreen()
                },
              },
              layout: {
                spacing: {
                  mb: { xs: 2 },
                },
              },
            }),
            buildCardButtonField({
              props: {
                ctaLabel: control =>
                  control.context.t(globalBookingMessages.buttons.select),
                description: control =>
                  control.context.t(globalBookingMessages.text.timeMinutes, {
                    minutes: 20,
                  }),
                title: control =>
                  control.context.t(
                    transportationMessages.info.locationAirport
                  ),
                price: initialData?.transportation?.price ?? 0,
                priceFormat: control =>
                  control.context.t(
                    transportationMessages.info.locationPriceRoundtrip
                  ),
                isClosed: false,
                inCart: control =>
                  control.flow.state?.dropoff?.type === LocationType.airport &&
                  !control.flow.state?.dropoff?.sameAsPickup,
                inCartLabel: control =>
                  control.context.t(globalBookingMessages.labels.selected),
                onClick: control => {
                  // If the user has already chosen 'city' and chosen a time for it,
                  // we need to clear the time they chose, because 'city' and 'airport' don't have the same slots
                  if (control.screen.state?.type === 'city') {
                    control.flow.setState({
                      ...control.flow.state,
                      departureTime: {
                        date: control.flow.state?.departureTime?.date,
                        time: undefined,
                      },
                    })
                  }
                  control.screen.setState({
                    type: LocationType.airport,
                    name: 'Keflavík Airport',
                  })
                  control.screen.setState({ sameAsPickup: false })
                  control.nextScreen()
                },
              },
            }),
            buildButton({
              condition: control => !!control.flow.state?.dropoff?.type,
              props: {
                label: control =>
                  control.context.t(globalBookingMessages.buttons.continue),
                onClick: control => {
                  if (control.flow.state?.dropoff?.sameAsPickup) {
                    control.flow.setState({
                      dropoffLocation: {
                        direction: Direction.dropoff,
                        location: control.flow.state.pickupLocation?.location,
                      },
                    })
                    control.screen.setState({
                      sameAsPickup: true,
                      ...control.flow.state?.pickup,
                    })
                  }
                  control.nextScreen()
                },
              },
              layout: {
                marginTopAuto: { xs: true },
                position: { xs: 'sticky' },
                bottom: { xs: 0 },
                width: { xs: `calc(100% + ${theme.spacing[2]})` },
                spacing: {
                  ml: { xs: -1 },
                  mr: { xs: -1 },
                },
              },
            }),
          ],
        },
      }),
      buildScreenWithDoubleLayout({
        id: 'dropoffLocation',
        subType: 'form',
        layoutProps: {
          id: 'double',
        },
        theme: initialData?.isRetreatSpaFlow ? 'retreat' : 'default',
        condition: control => {
          return (
            control.flow.stateRef.current.dropoff?.type === LocationType.city &&
            !control.flow.stateRef.current.dropoff?.sameAsPickup
          )
        },
        breadcrumb: control => ({
          title: control.context.t(
            transportationMessages.info.dropoffLocationBreadcrumb
          ),
        }),
        uiState: {
          showMap: false,
        },
        fields: {
          main: [
            buildCustomField({
              defaultValue: {
                direction: Direction.dropoff,
                // we have to initialize the location object to fix console errors
                location: {
                  address: '',
                  id: '',
                  latitude: 0,
                  longitude: 0,
                  name: '',
                },
              },
              props: {
                component: LocationPicker,
              },
            }),
          ],
          right: [
            buildCustomField({
              defaultValue: { direction: Direction.dropoff },
              props: {
                component: LocationMap,
              },
            }),
          ],
        },
      }),
      buildScreenWithImageLayout({
        id: 'departureTime',
        subType: 'form',
        layoutProps: imageLayoutProps,
        theme: initialData?.isRetreatSpaFlow ? 'retreat' : 'default',
        breadcrumb: control => ({
          title: control.context.t(
            transportationMessages.info.departureTimeBreadcrumb
          ),
        }),
        fields: {
          main: [
            ...buildHeading({
              title: control =>
                control.context.t(
                  transportationMessages.info.departureTimeTitle
                ),
              subTitle: control =>
                control.context.t(
                  transportationMessages.info.departureTimeSubtitle
                ),
              includeBreadcrumb: true,
            }),
            buildItineraryField({
              condition: () => !!initialData?.itineraryItems?.length,
              props: {
                items: initialData?.itineraryItems,
                moreLabel: globalBookingMessages.buttons.plusItems,
              },
              layout: {
                spacing: {
                  pt: { xs: 1, md: 2 },
                  pb: { xs: 2 },
                },
              },
            }),
            buildAvailableDateTimesField({
              defaultValue: {
                date: initialData?.bookingInfo?.entryDate,
                time: initialData?.transportation?.pickupTime?.time,
              },
              props: {
                type: 'transportationDeparture',
                bookingInfo: initialData?.bookingInfo,
                activities: initialData?.activities,
                currentReservationDate:
                  initialData?.bookingInfo?.entryDate &&
                  setDateTimeISOString(
                    formatDateInUTC(
                      initialData?.bookingInfo?.entryDate,
                      'yyyy-MM-dd'
                    ),
                    initialData?.transportation?.departureTime?.time
                  ),
                guests:
                  initialData?.bookingInfo?.adults +
                  initialData?.bookingInfo?.children,
                isHotelBooking: !!initialData?.bookingInfo?.hotelDates,
                isRetreatSpa: initialData?.isRetreatSpaFlow,
              },
              layout: {
                spacing: {
                  mb: {
                    xs: 2,
                  },
                },
              },
            }),
            buildButton({
              props: {
                label: control =>
                  control.context.t(globalBookingMessages.buttons.continue),
                onClick: control => {
                  control.nextScreen()
                },
                isDisabled: control => !control.screen?.state?.time,
              },
              layout: {
                marginTopAuto: { xs: true },
                position: { xs: 'sticky' },
                bottom: { xs: 0 },
                width: { xs: `calc(100% + ${theme.spacing[2]})` },
                spacing: {
                  ml: { xs: -1 },
                  mr: { xs: -1 },
                },
              },
            }),
          ],
        },
      }),

      buildScreenWithImageLayout({
        id: 'overview',
        subType: 'form',
        layoutProps: imageLayoutProps,
        columnStyle: true,
        theme: initialData?.isRetreatSpaFlow ? 'retreat' : 'default',
        breadcrumb: control => ({
          title: control.context.t(globalBookingMessages.breadcrumbs.overview),
        }),
        uiState: {
          isAddingToCart: false,
        },
        fields: {
          main: [
            ...buildHeading({
              title: control =>
                control.context.t(globalBookingMessages.text.overviewTitle),
              subTitle: control =>
                control.context.t(transportationMessages.info.overviewSubtitle),
              includeBreadcrumb: true,
            }),
            buildOverviewField({
              defaultValue: null,
              props: {
                items: control =>
                  getItemsForBluelagoonTransportationOverview(control),
                isEditing: false,
                isHotelBooking: !!initialData?.bookingInfo?.hotelDates,
                noTableHeading: true,
                totalPrice: () =>
                  getTotalPrice({
                    price: initialData?.transportation.price,
                    adults: initialData?.bookingInfo?.adults,
                  }),
              },
            }),
            buildProgressButton({
              props: {
                label: control =>
                  control.context.t(transportationMessages.info.submitLabel),
                isDisabled: control => control.screen.state.isAddingToCart,
                onClick: async control => {
                  const { hasErrors } = control.validateAndSetScreenErrors()

                  if (hasErrors) {
                    return false
                  }
                  control.screen.setUiState({
                    isAddingToCart: true,
                  })

                  const pickupLocation =
                    control.flow.stateRef.current?.pickupLocation
                      ?.pickupLocation
                  const pickupTime =
                    control.flow.stateRef.current?.pickupTime?.pickupTime
                  const dropoffLocation =
                    control.flow.stateRef.current?.dropoffLocation
                      ?.dropoffLocation
                  const dropoffTime =
                    control.screen.stateRef.current?.dropoffTime?.dropoffTime
                  const guests = control.screen.stateRef.current?.guests

                  const success = await onAddToCart(
                    {
                      id: '789',
                      title: 'Transportation',
                      description: `${guests}x guests from ${pickupLocation} at ${pickupTime} then to ${dropoffLocation} at ${dropoffTime}`,
                      type: 'transportation',
                      time: '',
                      price: 0,
                    },
                    control
                  )

                  control.screen.setUiState({
                    isAddingToCart: false,
                  })

                  if (!success) {
                    control.screenErrors.setState({
                      error: control.context.t(
                        transportationMessages.errors.submitError
                      ),
                    })
                    return false
                  }

                  return true
                },
                onComplete(control) {
                  onClose(control)
                },
              },
              layout: {
                marginTopAuto: { xs: true },
                width: { xs: `calc(100% + ${theme.spacing[2]})` },
                spacing: {
                  ml: { xs: -1 },
                  mr: { xs: -1 },
                  mt: { xs: 1 },
                },
              },
            }),

            buildScreenErrorField({
              props: {
                fieldIdsToRender: ['error'],
              },
              layout: {
                spacing: {
                  mt: { xs: 1 },
                },
              },
            }),
          ],
        },
      }),
    ],
  })
