import { useMemo } from 'react'

import { Disclaimer } from 'bl-common/src/booking/Disclaimer/Disclaimer'
import { colors } from 'bl-common/src/constants/colors'

import type { FlowComponent, FlowScreenErrorField } from '../../types'

type ScreenErrorFieldProps = FlowComponent & FlowScreenErrorField['props']

export const ScreenErrorField = ({
  fieldIdsToRender,
  control,
}: ScreenErrorFieldProps) => {
  const errors = useMemo(() => {
    const fieldIds = Object.keys(control.screenErrors.state)

    if (fieldIdsToRender) {
      return fieldIds.filter(id => fieldIdsToRender.includes(id))
    }

    return fieldIds
  }, [control.screenErrors.state])

  if (errors.length === 0) {
    return null
  }

  return (
    <div role="alert">
      {errors.map(fieldId => (
        <Disclaimer key={fieldId} color={colors.errorRed} weight="bold">
          {control.screenErrors.state[fieldId]}
        </Disclaimer>
      ))}
    </div>
  )
}
