import { type ReactNode, useState } from 'react'
import { AnimatePresence } from 'framer-motion'

import { Box } from 'ui-primitives/src/Box'
import { CloseIcon } from 'bl-common/src/elements/Icons/CloseIcon'
import { Type } from 'bl-common/src/elements/Typography/Typography'

import * as styles from './styles'

type ItemType = {
  time: string
  date: string
  title: string
  subtitle: string
  faded?: boolean
  showDate?: boolean
}

export type TimelineProps = {
  items: ItemType[]
  defaultExpanded?: boolean
  children?: ReactNode
  moreLabel?: (count: number) => string
  moreAriaLabel?: (count: number) => string
}

const TimelineItem = ({
  item,
  showDate,
  children,
  hasMinWidth,
}: {
  item: ItemType
  showDate?: boolean
  children?: ReactNode
  hasMinWidth?: boolean
}) => (
  <styles.TimelineItem style={{ opacity: item.faded ? 0.5 : 1 }}>
    <styles.Time hasMinWidth={hasMinWidth}>
      <Type preset="labelSmall">
        {showDate && `${item.date} `}
        {item.time}
      </Type>
    </styles.Time>
    <styles.Content>
      <Type preset="labelSmall" weight="bold" bottom={{ xs: 0.5 }}>
        {item.title}
      </Type>
      <Type preset="labelSmall" opacity={0.5}>
        {item.subtitle}
      </Type>
      {children}
    </styles.Content>
  </styles.TimelineItem>
)

export const Timeline = ({
  items = [],
  defaultExpanded = false,
  children,
  moreLabel,
  moreAriaLabel,
}: TimelineProps) => {
  const [expanded, setExpanded] = useState(defaultExpanded)

  const uniqueDates = new Set(items.map(item => item.date))
  const hasMultipleDates = uniqueDates.size > 1

  const editedItems = items.map((item, i) => ({
    ...item,
    showDate: hasMultipleDates && (i === 0 || item.date !== items[i - 1]?.date),
  }))

  // Split items into three arrays: first, hidden, and last
  const [first, hidden, last] = editedItems.reduce<
    [ItemType[], ItemType[], ItemType[]]
  >(
    ([first, hidden, last], current, index) => {
      if (index === 0) {
        return [[current], hidden, last]
      } else if (index === items.length - 1) {
        return [first, hidden, [current]]
      } else {
        return [first, [...hidden, current], last]
      }
    },
    [[], [], []]
  )

  const toggleOpen = () => {
    setExpanded(!expanded)
  }

  const showLastDate = first?.[0]?.date !== last?.[0]?.date && !expanded

  // Replace placeholders in labels
  const label = moreLabel
    ? moreLabel(hidden.length).replace('{count}', hidden.length.toString())
    : `+${hidden.length} Items`

  const ariaLabel = moreAriaLabel
    ? moreAriaLabel(hidden.length).replace('{count}', hidden.length.toString())
    : `${hidden.length} more items, press to expand`

  return (
    <Box alignItems="baseline" position="relative">
      {children}
      {/* First Item */}
      {first.map(item => (
        <TimelineItem
          key={`${item.date}-${item.title}-${item.time}`}
          item={item}
          showDate={item.showDate}
          hasMinWidth={hasMultipleDates}
        >
          {/* Toggle Button */}
          {hidden.length > 0 && !defaultExpanded && (
            <styles.ExtraItemsButton
              onClick={toggleOpen}
              aria-controls="hidden-items"
              aria-expanded={expanded}
              aria-label={ariaLabel}
              animate={expanded ? 'open' : 'closed'}
              variants={{
                closed: {
                  opacity: 1,
                  height: 'auto',
                  transition: { delay: 0.6 },
                },
                open: { opacity: 0, height: 0 },
              }}
              transition={{ duration: 0.2 }}
              type="button"
            >
              <Type preset="labelSmall" weight="bold" top={{ xs: 1 }}>
                {label}
              </Type>
            </styles.ExtraItemsButton>
          )}
        </TimelineItem>
      ))}

      {/* Close Icon */}
      <AnimatePresence>
        {expanded && !defaultExpanded && (
          <styles.Close
            onClick={toggleOpen}
            aria-label="press to hide"
            initial="closed"
            animate="open"
            exit="closed"
            variants={{ open: { opacity: 1 }, closed: { opacity: 0 } }}
          >
            <CloseIcon style={{ height: '12px', width: '12px' }} />
          </styles.Close>
        )}
      </AnimatePresence>

      {/* Hidden Items */}
      <AnimatePresence initial={false}>
        {expanded && (
          <styles.Hidden
            id="hidden-items"
            aria-hidden={!expanded}
            initial="closed"
            animate="open"
            exit="closed"
            variants={{
              open: {
                height: 'auto',
                transition: { duration: 0.5, delay: 0.3 },
              },
              closed: { height: 0 },
            }}
            transition={{ duration: 0.5, ease: 'easeInOut' }}
          >
            {hidden.map((item, index) => (
              <TimelineItem
                key={`${item.date}-${item.title}-${index}`}
                item={item}
                showDate={item.showDate}
                hasMinWidth={hasMultipleDates}
              />
            ))}
          </styles.Hidden>
        )}
      </AnimatePresence>

      {/* Last Item */}
      {last.map(item => (
        <TimelineItem
          key={`${item.date}-${item.title}-${item.time}`}
          item={item}
          showDate={item.showDate || showLastDate}
          hasMinWidth={hasMultipleDates}
        />
      ))}
    </Box>
  )
}
