import styled from 'styled-components'

import { sentryLogging } from 'sentry-utils/logging'
import { spacing } from 'ui-primitives/src/utils/spacing'

const StyledIframeContainer = styled.div({
  height: 450,
  position: 'relative',
  background: 'var(--color-background)',
  padding: spacing[2],
})

const StyledIframe = styled.iframe({
  height: '100%',
  width: '100%',
  border: 'none',
})

const handleIframeError = (
  e: React.SyntheticEvent<HTMLIFrameElement, Event>
) => {
  sentryLogging({
    error: new Error('3ds error: iframe failed to load'),
    extras: { event: e?.currentTarget?.src },
    team: 'team-frontend-infrastructure',
  })
}

export const SecureFieldsIframe = ({ src }: { src: string }) => {
  return (
    <StyledIframeContainer>
      <StyledIframe
        src={src}
        id="3dsecure-iframe"
        onError={handleIframeError}
      />
    </StyledIframeContainer>
  )
}
