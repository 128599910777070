import { colors } from 'bl-common/src/constants/colors'
import { theme } from 'bl-common/src/styles/theme'
import {
  buildAvailableDateTimesField,
  buildButton,
  buildDisclaimerField,
  buildFlow,
  buildHeading,
  buildItineraryField,
  buildOverviewField,
  buildPickerField,
  buildProgressButton,
  buildScreenErrorField,
  buildScreenWithImageLayout,
  type BuildSubFlow,
  buildText,
  buildTextAreaField,
  type FlowImageLayout,
  type FlowItineraryField,
  type SubFlowActivity,
  type SubFlowBookingInfo,
  type SubFlowTransportation,
} from 'bl-flows-core'

import { globalBookingMessages } from '../../messages'
import { getItemsForSpaRestaurantOverview } from '../../utils'
import { isBookingTooClose } from '../../utils/isBookingTooClose'
import { spaRestaurantMessages } from './messages/spaRestaurantMessages'

export interface SpaRestaurantFlowInitialData {
  bookingInfo: SubFlowBookingInfo
  activities?: SubFlowActivity[]
  transportation?: SubFlowTransportation
  itineraryItems?: FlowItineraryField['props']['items']
  isSPA?: boolean
  isHBE?: boolean
  isRetreatSpaFlow?: boolean
}

interface CartItem {
  id: string
  title: string
  type: string
}

const imageLayoutProps: FlowImageLayout['props'] = {
  layoutId: 'lava-image-layout',
  layoutImageSrc:
    'https://images.ctfassets.net/w65k7w0nsb8q/3RNjv9rw1MmkvC2XPoIaPl/341c6343457914aa0a8431c0d50490e7/Blue_Lagoon_K9A8760_01.png?w=1600',
}

export const buildSpaRestaurantFlow: BuildSubFlow<CartItem> = (
  onAddToCart,
  onClose,
  initialData?: SpaRestaurantFlowInitialData
) =>
  buildFlow({
    id: 'spaRestaurantFlow',
    routerSettings: {
      updateHistory: false,
    },
    settings: {
      resetWhenOpened: true,
    },
    children: [
      buildScreenWithImageLayout({
        id: 'about',
        subType: 'form',
        layoutProps: imageLayoutProps,
        columnStyle: true,
        theme: initialData?.isRetreatSpaFlow ? 'retreat' : 'default',
        breadcrumb: control => {
          return {
            title: control.context.t(
              spaRestaurantMessages.info.aboutBreadcrumb
            ),
          }
        },
        fields: {
          main: [
            ...buildHeading({
              title: control =>
                control.context.t(spaRestaurantMessages.info.aboutTitle),
              subTitle: control =>
                control.context.t(spaRestaurantMessages.info.aboutSubtitle),
              includeBreadcrumb: true,
              imageSrc: imageLayoutProps.layoutImageSrc,
              imageLayout: { display: { bmd: 'none' } },
            }),
            buildDisclaimerField({
              condition: () => isBookingTooClose(initialData.bookingInfo),
              props: {
                value: control =>
                  control.context.t(
                    globalBookingMessages.warnings.bookingTooCloseToAllowUpgrade
                  ),
                color: colors.errorRed,
              },
            }),
            buildButton({
              props: {
                label: control =>
                  control.context.t(
                    spaRestaurantMessages.info.aboutReserveButton
                  ),
                isDisabled: () => isBookingTooClose(initialData.bookingInfo),
                onClick: control => {
                  control.nextScreen()
                },
              },
              layout: {
                marginTopAuto: { xs: true },
                width: { xs: `calc(100% + ${theme.spacing[2]})` },
                spacing: {
                  ml: { xs: -1 },
                  mr: { xs: -1 },
                  mt: { xs: 2 },
                },
              },
            }),
          ],
        },
      }),

      buildScreenWithImageLayout({
        id: 'guests',
        subType: 'form',
        layoutProps: imageLayoutProps,
        columnStyle: true,
        theme: initialData?.isRetreatSpaFlow ? 'retreat' : 'default',
        breadcrumb: control => {
          return {
            title: control.context.t(
              spaRestaurantMessages.info.guestsBreadcrumb
            ),
            value: control.flow.state?.guests?.guests,
          }
        },
        fields: {
          main: [
            ...buildHeading({
              title: control =>
                control.context.t(spaRestaurantMessages.info.guestsTitle),
              includeBreadcrumb: true,
            }),
            buildPickerField({
              id: 'guests',
              defaultValue:
                initialData?.bookingInfo?.adults +
                initialData?.bookingInfo?.children +
                (initialData?.bookingInfo?.infants ?? 0),
              props: {
                label: control =>
                  control.context.t(
                    spaRestaurantMessages.info.guestsPickerLabel
                  ),
                min: 1,
                max: initialData?.bookingInfo?.adults || 2,
              },
              layout: {
                spacing: {
                  mt: { xs: 2.5, md: 0 },
                  mb: { xs: 2.5, md: 0 },
                },
              },
            }),
            buildDisclaimerField({
              props: {
                value: control =>
                  control.context.t(
                    spaRestaurantMessages.warnings.numberHigherThanGuests
                  ),
                showDisclaimer: control =>
                  control.screen.stateRef.current.guests >
                    initialData?.bookingInfo?.adults +
                      initialData?.bookingInfo?.children +
                      (initialData?.bookingInfo?.infants ?? 0) &&
                  control.screen.stateRef.current.guests < 11,
                type: 'circle',
              },
              layout: {
                spacing: {
                  mt: { xs: 2 },
                },
              },
            }),
            buildTextAreaField({
              id: 'comment',
              props: {
                label: control =>
                  control.context.t(spaRestaurantMessages.info.commentLabel),
                placeholder: control =>
                  control.context.t(
                    spaRestaurantMessages.info.commentPlaceholder
                  ),
              },
              layout: {
                spacing: {
                  pt: { xs: 1, md: 2 },
                  pb: { xs: 1 },
                },
              },
            }),
            buildText({
              condition: control => control.screen.stateRef.current.guests > 10,
              props: {
                value: control =>
                  control.context.t(
                    spaRestaurantMessages.warnings.tooManyGuestsForFlowBooking
                  ),
                type: 'html',
              },
              layout: {
                spacing: {
                  mt: { xs: 1 },
                  mb: { xs: 1 },
                },
              },
            }),
            buildButton({
              props: {
                label: control =>
                  control.context.t(globalBookingMessages.buttons.continue),
                onClick: control => {
                  control.nextScreen()
                },
                isDisabled: control =>
                  control.screen.stateRef.current.guests > 10,
              },

              layout: {
                marginTopAuto: { xs: true },
                width: { xs: `calc(100% + ${theme.spacing[2]})` },
                spacing: {
                  ml: { xs: -1 },
                  mr: { xs: -1 },
                  mt: { xs: 2 },
                },
              },
            }),
          ],
        },
      }),

      buildScreenWithImageLayout({
        id: 'time',
        subType: 'form',
        layoutProps: imageLayoutProps,
        columnStyle: true,
        theme: initialData?.isRetreatSpaFlow ? 'retreat' : 'default',
        breadcrumb: control => {
          return {
            title: control.context.t(spaRestaurantMessages.info.timeBreadcrumb),
            value: control.flow.state?.time?.time,
          }
        },
        fields: {
          main: [
            ...buildHeading({
              title: control =>
                control.context.t(spaRestaurantMessages.info.timeTitle),
              subTitle: control =>
                control.context.t(spaRestaurantMessages.info.timeSubtitle),
              includeBreadcrumb: true,
            }),
            buildItineraryField({
              condition: () => !!initialData?.itineraryItems?.length,
              props: {
                items: initialData?.itineraryItems,
                isHotelBooking: !!initialData?.bookingInfo?.hotelDates,
                moreLabel: globalBookingMessages.buttons.plusItems,
              },
              layout: {
                spacing: {
                  pb: { xs: 1 },
                },
              },
            }),
            buildAvailableDateTimesField({
              defaultValue: {
                date:
                  initialData?.bookingInfo?.entryDate ||
                  initialData?.bookingInfo?.hotelDates?.arrivalDate,
                time: null,
              },
              props: {
                type: 'spaRestaurant',
                bookingInfo: initialData?.bookingInfo,
                activities: initialData?.activities,
                transportation: initialData?.transportation,
                guests: control => control.flow.state.guests.guests,
                isHotelBooking: !!initialData?.bookingInfo?.hotelDates,
              },
              layout: {
                spacing: {
                  mb: { xs: 2 },
                },
              },
            }),

            buildButton({
              props: {
                label: control =>
                  control.context.t(globalBookingMessages.buttons.continue),
                onClick: control => {
                  control.nextScreen()
                },
                isDisabled: control =>
                  !control.screen.stateRef.current?.date ||
                  !control.screen.stateRef.current?.time,
              },
              layout: {
                marginTopAuto: { xs: true },
                position: { xs: 'sticky' },
                bottom: { xs: 0 },
                width: { xs: `calc(100% + ${theme.spacing[2]})` },
                spacing: {
                  ml: { xs: -1 },
                  mr: { xs: -1 },
                  pt: { xs: 2.5 },
                },
              },
            }),
          ],
        },
      }),

      buildScreenWithImageLayout({
        id: 'overview',
        subType: 'form',
        columnStyle: true,
        theme: initialData?.isRetreatSpaFlow ? 'retreat' : 'default',
        breadcrumb: control => {
          return {
            title: control.context.t(
              globalBookingMessages.breadcrumbs.overview
            ),
          }
        },
        layoutProps: imageLayoutProps,
        fields: {
          main: [
            ...buildHeading({
              title: control =>
                control.context.t(globalBookingMessages.text.overviewTitle),
              includeBreadcrumb: true,
            }),
            buildOverviewField({
              defaultValue: null,
              props: {
                items: control => getItemsForSpaRestaurantOverview(control),
                isEditing: false,
                noTableHeading: true,
              },
            }),
            buildProgressButton({
              props: {
                label: control =>
                  control.context.t(spaRestaurantMessages.info.addReservation),
                isDisabled: control =>
                  control.screen.state.isAddingToCart ||
                  control.screen.stateRef.current.guests > 10,
                onClick: async control => {
                  control.screen.setState({
                    isAddingToCart: true,
                  })

                  const success = await onAddToCart(
                    {
                      id: '123',
                      title: 'Spa restaurant reservation',
                      type: 'spaRestaurant',
                    },
                    control
                  )

                  control.screen.setState({
                    isAddingToCart: false,
                  })

                  if (!success) {
                    control.screenErrors.setState({
                      error: control.context.t(
                        spaRestaurantMessages.errors.reservationFailedError
                      ),
                    })
                    return false
                  }
                  return true
                },
                onComplete(control) {
                  onClose(control)
                },
              },
              layout: {
                marginTopAuto: { xs: true },
                width: { xs: `calc(100% + ${theme.spacing[2]})` },
                spacing: {
                  ml: { xs: -1 },
                  mr: { xs: -1 },
                },
              },
            }),
            buildScreenErrorField({
              props: {},
              layout: {
                spacing: {
                  mt: { xs: 1 },
                },
              },
            }),
          ],
        },
      }),
    ],
  })
