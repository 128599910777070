import { useMemo } from 'react'
import { useIntl } from 'react-intl'
import { subDays } from 'date-fns'
import { addDays } from 'date-fns/addDays'
import { addMinutes } from 'date-fns/addMinutes'
import { isToday } from 'date-fns/isToday'
import capitalize from 'lodash/capitalize'

import type { ScreenTheme } from 'bl-common/src/styles/types'
import {
  buildBreadcrumbField,
  buildChangeDateField,
  buildCustomField,
  buildHeading,
  buildScreenWithFullScreenLayout,
  buildText,
} from 'bl-flows-core'
import {
  CartItemType,
  CartType,
  MembershipType,
  useProductsAvailabilityQuery,
} from 'bl-graphql'
import { triggerEvent } from 'bl-utils/src/analytics/events'
import { setDateTimeISOString } from 'bl-utils/src/date'
import { formatDateInUTC } from 'bl-utils/src/formatting/formatDate'
import { MembershipCartType } from 'bl-utils/src/membership'
import { PRODUCT_IDS } from 'bl-utils/src/ProductIds'

import { globalBookingMessages } from '../../../messages/global'
import { selectDayVisitTimeFieldMessages } from '../../../utils/selectDayVisitTimeFieldMessages'
import { AdmissionCards } from '../components/AdmissionCards'
import SubscriptionCardAccepted from '../components/SubscriptionCardAccepted'
import { admissionMessages } from '../messages/admission'
import {
  getAdmissionTypeFromUrl,
  getDayVisitAdmissionProductIds,
  getSeasonalPackageFromAvailability,
  getSpaAdmissionComparisonCard,
} from '../utils'
import { getEcommerce } from '../utils/getEcommerce'

export const admissionScreen = ({
  screenTheme,
}: {
  screenTheme: ScreenTheme
}) => {
  return buildScreenWithFullScreenLayout({
    id: 'admission',
    subType: 'form',
    route: () => {
      return {
        en: 'admission',
        is: 'adgangur',
      }
    },
    theme: screenTheme,
    setupHook: control => {
      // Fetch product availability for the selected date
      const arrivalDate = new Date(control.flow.state.calendar?.arrivalDate)

      // Make sure that we always have a valid date
      // fromTime should never be in the past, otherwise we get an error from the API.
      // Adding 5 minutes to the current time as a buffer.
      const fromTime = isToday(arrivalDate)
        ? formatDateInUTC(addMinutes(new Date(), 5), 'HH:mm')
        : '00:00'

      const toTime = '23:59'

      // Format the date
      const date = formatDateInUTC(arrivalDate, 'yyyy-MM-dd')

      // Format the date with the time
      const formattedFromTime = setDateTimeISOString(date, fromTime)
      const formattedToTime = setDateTimeISOString(date, toTime)

      const useSeasonalPackages = control.context.useSeasonalPackages

      // Get all admission product ids and add the seasonal ones from Contentful
      const dayVisitAdmissionProductIds = getDayVisitAdmissionProductIds(
        control.context.spaFlowSettings
      )

      const {
        loading: isProductsAvailabilityDataLoading,
        data: productsAvailabilityData,
      } = useProductsAvailabilityQuery({
        variables: {
          input: {
            type: CartType.Dayspa,
            productIds: dayVisitAdmissionProductIds,
            from: formattedFromTime,
            to: formattedToTime,
            isMembership:
              control.flow.setupHook?.cart?.membership?.membershipToken != null,
          },
        },
      })

      const seasonalPackage = useMemo(() => {
        return useSeasonalPackages
          ? getSeasonalPackageFromAvailability(productsAvailabilityData)
          : productsAvailabilityData?.productAvailabilities.filter(
              pa =>
                pa.available > 0 && pa.productId === PRODUCT_IDS.SpaSignature
            )
      }, [productsAvailabilityData])

      const products = useMemo(() => {
        if (productsAvailabilityData == null) {
          return null
        }

        // Get cheapest and most expensive price for each product
        const sortedComfortPrices =
          productsAvailabilityData.productAvailabilities
            .filter(
              pa => pa.available > 0 && pa.productId === PRODUCT_IDS.SpaComfort
            )
            .sort((a, b) => a.price - b.price)
        const sortedPremiumPrices =
          productsAvailabilityData.productAvailabilities
            .filter(
              pa => pa.available > 0 && pa.productId === PRODUCT_IDS.SpaPremium
            )
            .sort((a, b) => a.price - b.price)
        const sortedSeasonalPrices = seasonalPackage.sort(
          (a, b) => a.price - b.price
        )
        const sortedRetreatPrices =
          productsAvailabilityData.productAvailabilities
            .filter(
              pa => pa.available > 0 && pa.productId === PRODUCT_IDS.SpaRetreat
            )
            .sort((a, b) => a.price - b.price)

        const comfortPriceLow = sortedComfortPrices[0]?.price || 0
        const comfortPriceHigh =
          sortedComfortPrices.slice(-1)[0]?.price || comfortPriceLow || 0

        const premiumPriceLow = sortedPremiumPrices[0]?.price || 0
        const premiumPriceHigh =
          sortedPremiumPrices.slice(-1)[0]?.price || premiumPriceLow || 0

        const seasonalPriceLow = sortedSeasonalPrices[0]?.price || 0
        const seasonalPriceHigh =
          sortedSeasonalPrices.slice(-1)[0]?.price || seasonalPriceLow || 0

        const retreatPriceLow = sortedRetreatPrices[0]?.price || 0
        const retreatPriceHigh =
          sortedRetreatPrices.slice(-1)[0]?.price || retreatPriceLow || 0

        return {
          comfort: {
            low: comfortPriceLow,
            high: comfortPriceHigh,
            productId: PRODUCT_IDS.SpaComfort,
          },
          premium: {
            low: premiumPriceLow,
            high: premiumPriceHigh,
            productId: PRODUCT_IDS.SpaPremium,
          },
          seasonal: {
            hasSeasonalPackage:
              seasonalPackage?.[0]?.productId !== PRODUCT_IDS.SpaSignature,
            low: seasonalPriceLow,
            high: seasonalPriceHigh,
            productId:
              seasonalPackage?.[0]?.productId || PRODUCT_IDS.SpaSignature,
          },
          retreatspa: {
            low: retreatPriceLow,
            high: retreatPriceHigh,
            productId: PRODUCT_IDS.SpaRetreat,
          },
        }
      }, [productsAvailabilityData])

      return {
        productsAvailabilityData,
        isProductsAvailabilityDataLoading,
        products,
      }
    },
    queryParams: control => {
      return {
        arrivalDate: control?.flow?.state?.calendar?.arrivalDate
          ? formatDateInUTC(control?.flow?.state?.calendar?.arrivalDate)
          : formatDateInUTC(new Date()),
      }
    },
    layoutProps: {
      id: 'fullscreen',
    },
    breadcrumb: control => {
      // We can't rely on setupHook here to figure out which admission we have
      // And also if the user refreshes the page we must rely on some URL state.
      const admissionTypeFromUrl = getAdmissionTypeFromUrl(
        window?.location?.pathname
      )

      let admissionType = ''

      switch (admissionTypeFromUrl) {
        case 'premium':
          admissionType = 'Premium'
          break
        case 'comfort':
          admissionType = 'Comfort'
          break
        case 'signature':
          admissionType = 'Signature'
          break
        case 'seasonal':
          admissionType = control.context.t(admissionMessages.info.seasonal)
          break
        default:
          admissionType = control.context.t(admissionMessages.info.subscription)
      }

      return {
        title: control.context.t(admissionMessages.info.breadcrumbTitle),
        value: admissionType,
      }
    },
    fields: {
      main: [
        buildBreadcrumbField({
          props: {
            breadcrumb: control => control.screen.breadcrumb,
          },
          layout: {
            spacing: {
              mb: { xs: 3 },
            },
          },
        }),
        buildCustomField({
          props: {
            render: () => {
              const { formatMessage } = useIntl()
              return (
                <SubscriptionCardAccepted
                  acceptedText={formatMessage(
                    globalBookingMessages.text.winterCardAccepted
                  )}
                  removeButtonLabel={formatMessage(
                    globalBookingMessages.buttons.remove
                  )}
                />
              )
            },
          },
        }),
        ...buildHeading({
          title: control =>
            control.context.t(admissionMessages.info.title, {
              admissionType: capitalize(
                control?.flow?.setupHook?.selectedPackage
              ),
            }),
          removeMarginTop: true,
          maxWidth: '100%',
        }),
        buildChangeDateField({
          id: 'selectedTime',
          defaultValue: null,
          props: {
            isLoadingAvailability: control =>
              control.screen.setupHook?.isProductsAvailabilityDataLoading,

            date: control => control.flow.state?.calendar?.arrivalDate,
            onPrevDayClick: control =>
              control.flow.setState({
                calendar: {
                  arrivalDate: subDays(
                    new Date(control.flow.state?.calendar?.arrivalDate),
                    1
                  ),
                },
              }),
            onNextDayClick: control =>
              control.flow.setState({
                calendar: {
                  arrivalDate: addDays(
                    new Date(control.flow.state?.calendar?.arrivalDate),
                    1
                  ),
                },
              }),

            messages: selectDayVisitTimeFieldMessages,
          },
          layout: {
            spacing: {
              mt: { xs: 2, md: 0 },
            },
          },
        }),
        buildCustomField({
          defaultValue: null,
          props: {
            component: props => {
              const membershipType =
                props.control.flow.setupHook?.cart?.membership?.type
              const spaFlows = props.control.context.spaFlowSettings
              return (
                <AdmissionCards
                  admissions={{
                    comfort: getSpaAdmissionComparisonCard(
                      'comfort',
                      PRODUCT_IDS.SpaComfort,
                      spaFlows
                    ),
                    premium: getSpaAdmissionComparisonCard(
                      'premium',
                      PRODUCT_IDS.SpaPremium,
                      spaFlows
                    ),
                    seasonal: getSpaAdmissionComparisonCard(
                      'seasonal',
                      props.control.screen?.setupHook?.products?.seasonal
                        ?.productId || PRODUCT_IDS.SpaSignature,
                      spaFlows
                    ),
                    retreatspa: getSpaAdmissionComparisonCard(
                      'retreat',
                      PRODUCT_IDS.SpaRetreat,
                      spaFlows
                    ),
                  }}
                  membershipType={
                    [
                      MembershipCartType.WINTER_CARD,
                      MembershipCartType.WINTER_FAMILY_CARD,
                    ].includes(membershipType)
                      ? MembershipType.Winter
                      : [
                            MembershipCartType.SUMMER_CARD,
                            MembershipCartType.SUMMER_FAMILY_CARD,
                          ].includes(membershipType)
                        ? MembershipType.Summer
                        : undefined
                  }
                  onSelect={packageType => {
                    if (packageType === 'retreatspa') {
                      window.location.href =
                        props.control.context.locale === 'is'
                          ? '/is/boka/spa/retreat/guests'
                          : '/book/spa/retreat/guests'
                    } else {
                      props.control.flow?.setupHook?.setSelectedPackage(
                        packageType
                      )
                      props.control.flow.setState({
                        selectedPackage: packageType,
                      })
                      // this disables validation below when user has clicked on a package
                      props.control.screen.setUiState({
                        hasChosenNewPackage: true,
                      })
                      if (props.control.nextScreen()) {
                        const admissionPrice = {
                          comfort:
                            props.control.screen.setupHook?.products?.comfort
                              ?.low,
                          premium:
                            props.control.screen.setupHook?.products?.premium
                              ?.low,
                          seasonal:
                            props.control.screen.setupHook?.products?.seasonal
                              ?.low,

                          subscription: 0,
                        }
                        triggerEvent({
                          event: 'select_item',
                          ecommerce: getEcommerce({
                            price: admissionPrice[packageType] || 0,
                            adults:
                              props.control.flow.state.guests?.adults || 1,
                            children:
                              props.control.flow.state.guests?.children || 0,
                            exchangeRates:
                              props.control.flow.setupHook?.exchangeRates,
                            productId:
                              packageType === 'premium'
                                ? PRODUCT_IDS.SpaPremium
                                : packageType === 'comfort'
                                  ? PRODUCT_IDS.SpaComfort
                                  : packageType === 'seasonal'
                                    ? props.control.flow.setupHook?.products
                                        ?.seasonal?.productId
                                    : packageType === 'subscription'
                                      ? PRODUCT_IDS.BLSummerCard
                                      : PRODUCT_IDS.SpaComfort,
                            spaType: packageType,
                          }),
                        })
                        triggerEvent({
                          event: 'funnel_admission',
                          BLType: 'Day Visit',
                          pageTitle: 'Book Admission',
                          pageCategory: 'book',
                          Adult: props.control.flow.state.guests?.adults || 1,
                          Children:
                            props.control.flow.state.guests?.children || 0,
                          Calendar: props.control.flow.state.calendar
                            ?.arrivalDate
                            ? formatDateInUTC(
                                new Date(
                                  props.control.flow.state.calendar?.arrivalDate
                                ),
                                'yyyy-MM-dd'
                              )
                            : 'MISSING',
                          Package: packageType,
                          language: props.control.context.locale,
                        })
                      }
                    }
                  }}
                  {...props}
                />
              )
            },
          },
          validation: {
            renderErrorInFieldRenderer: false,
            validator: (_, __, control) => {
              const admissionItem = control.flow.setupHook?.cart?.items?.find(
                item => item.type === CartItemType.Admission
              )

              if (
                admissionItem?.productId === PRODUCT_IDS.SpaRetreat &&
                !control.screen.uiStateRef?.current?.hasChosenNewPackage
              ) {
                // we are not rendering this validation on the screen, since its purpose is just to
                // stop the user on this screen if they have a Retreat admission item in their cart
                // that way they are forced to add their comfort/premium admission item
                // to the cart to replace the old one
                return 'Cannot continue with wrong admission item'
              }
            },
          },
        }),
        buildText({
          id: 'priceRangeDisclaimer',
          props: {
            value: control =>
              control.context.t(
                admissionMessages.warnings.priceRangeDisclaimer
              ),
            type: 'text',
            preset: 'labelTiny',
          },
          layout: {
            spacing: {
              mt: { xs: 1 },
            },
          },
        }),
      ],
    },
  })
}
