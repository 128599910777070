/* eslint-disable no-restricted-imports */
// This allows for import of useFeatureFlagEnabled from posthog-js/react
import { useFeatureFlagEnabled, useFeatureFlagPayload } from 'posthog-js/react'

import type { FeatureFlags } from './generated/featureFlags'
import type { HBFeatureFlags } from './generated/hbFeatureFlags'
import {
  isPayloadObject,
  usePosthogPageview,
  useSessionRecording,
} from './useInitPosthog'

type ProjectFeatureFlags = {
  bluelagoon: FeatureFlags
  highland: HBFeatureFlags
}

export const useFeatureFlagEnabledWrapper = <
  T extends 'bluelagoon' | 'highland' = 'bluelagoon',
>(
  flag: ProjectFeatureFlags[T]
) => {
  const enabled = useFeatureFlagEnabled(flag)
  const payload = useFeatureFlagPayload(flag)
  const isSessionRecordingEnabled =
    isPayloadObject(payload) && payload.session_recording && enabled
  const allowedUrlSegments =
    isPayloadObject(payload) && payload.allowedUrlSegments

  const recordSession = enabled && isSessionRecordingEnabled

  useSessionRecording(isSessionRecordingEnabled, allowedUrlSegments)
  usePosthogPageview(recordSession)

  return enabled
}
