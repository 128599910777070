import { useEffect, useState } from 'react'
import styled, { useTheme } from 'styled-components'

import { colors } from 'bl-common/src/constants/colors'
import { Type } from 'bl-common/src/elements/Typography/Typography'
import { theme } from 'bl-common/src/styles/theme'
import type {
  PartialBookingEngine,
  ScreenTheme,
} from 'bl-common/src/styles/types'
import type { FlowControl, SubFlowActivity } from 'bl-flows-core'

import type { MassageState } from '../subflows'
import { floatMessages } from '../subflows/float/messages/floatMessages'
import { MassageCard } from './MassageCard'

interface MassageCardsProps {
  control: FlowControl
  previousMassageActivity?: SubFlowActivity
  isEditing?: boolean
  enableClearCard?: boolean
  useIncrementalCards?: boolean
  isFloat?: boolean
  screenTheme?: ScreenTheme
  isMYB?: boolean
}

const Cards = styled.div({
  display: 'flex',
  flexDirection: 'column',
  rowGap: theme.spacing[2],
})

const AddButton = styled.button<{
  themeStyle?: PartialBookingEngine['massageCard']
}>(({ themeStyle }) => ({
  color: themeStyle?.addButtonColor ?? colors.deepBlue,
  cursor: 'pointer',
  textAlign: 'left',
  marginTop: theme.spacing[-1],
}))

export const MassageCards: React.FC<MassageCardsProps> = ({
  control,
  previousMassageActivity,
  isEditing,
  enableClearCard,
  useIncrementalCards,
  isFloat,
  screenTheme,
  isMYB,
}: MassageCardsProps) => {
  const numberOfGuests =
    Number(control.flow.setupHook?.bookingInfo?.adults || 0) +
    Number(control.flow.setupHook?.bookingInfo?.children || 0)
  const massageQuantity = Number(control.flow.state.quantity?.quantity)
  const numSelectedMassages = Number(
    control?.screen?.state?.massages?.length || 1
  )

  const theme = useTheme()
  const themeStyle = theme.bookingEngine?.[screenTheme]?.massageCard

  const [massageCardsQuantity, setMassageCardsQuantity] = useState(
    isEditing || useIncrementalCards ? numSelectedMassages : massageQuantity
  )

  let massages = Array.from(Array(massageCardsQuantity).keys())
  const bookingInfo = control.flow.setupHook?.bookingInfo

  const ready = control.screen.stateRef?.current?.massages?.every(
    massage => massage.productNo && massage.time && massage.date
  )

  const showAddCardButton =
    useIncrementalCards && massageCardsQuantity < numberOfGuests

  useEffect(() => {
    control.screen.setState({
      ready,
    })
    // remove disclaimer when user has filled everything out
    if (ready) {
      control.screen.setUiState({
        showNotReadyDisclaimer: false,
      })
    }
  }, [ready])

  const onDeleteCard = index => {
    const arr = control.screen.state?.massages as MassageState[]
    const editedArr = arr.filter((_, i) => index !== i)

    const screenState = control.screen.state
    control.screen.setState({
      ...screenState,
      massages: editedArr,
    })
    massages = massages.slice(-1)
    setMassageCardsQuantity(massageCardsQuantity - 1)
  }

  return (
    <Cards>
      {massages.map(massage => (
        <MassageCard
          key={massage}
          number={massage}
          control={control}
          bookingInfo={bookingInfo}
          previousMassageActivity={previousMassageActivity}
          isEditing={isEditing}
          enableClearCard={enableClearCard}
          isFloat={isFloat}
          themeStyle={themeStyle}
          screenTheme={screenTheme}
          isMYB={isMYB}
          onDeleteCard={onDeleteCard}
        />
      ))}
      {showAddCardButton && (
        <AddButton
          type="button"
          onClick={() => {
            setMassageCardsQuantity(massageCardsQuantity + 1)
          }}
          themeStyle={themeStyle}
        >
          <Type preset="label">
            {control.context.t(floatMessages.info.addAnotherCardLabel)}
          </Type>
        </AddButton>
      )}
    </Cards>
  )
}
