import { type ChangeEvent, useMemo } from 'react'
import get from 'lodash/get'
import styled, { useTheme } from 'styled-components'

import { Type } from 'bl-common/src/elements/Typography/Typography'
import { Select } from 'bl-common/src/form/Select/Select'
import { StyledSelect } from 'bl-common/src/form/Select/styles'
import { ErrorMessage } from 'bl-common/src/units/Form/styles'

import type { FlowComponent, FlowSelectField, FlowValue } from '../../types'
import { getFlowValue } from '../../utils'

type SelectFieldProps = FlowComponent &
  FlowSelectField['props'] & {
    id: string
    error?: FlowValue
  }

const InputWrapper = styled.div<{ hasError?: boolean }>(({ hasError }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',

  [StyledSelect]: {
    borderWidth: hasError && '2px',
  },
}))

const TopWrapper = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  gap: 5,
})

const Label = styled(Type)({
  fontWeight: 'bold',
  marginBottom: '8px',
})

export const SelectField = ({ screenTheme, ...props }: SelectFieldProps) => {
  const theme = useTheme()
  const themeStyle = theme?.bookingEngine?.[screenTheme]?.selectField
  const errorColor = theme?.bookingEngine?.[screenTheme]?.errorMessage?.color

  const onChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const newValue = event.target.value

    if (props.control.screen.stateRef.current[props.id] === newValue) {
      return
    }

    props.onChange?.(newValue, props.control)

    props.control.screen.setState({
      [props.id]: event.target.value,
    })
  }
  const initialValue =
    getFlowValue(props.initialValue, props.control) ?? undefined

  const selectedValue =
    get(props.control.screen.state, props.id, initialValue) ?? ''
  const optionsValue = getFlowValue(props.options, props.control)
  const initialLabel = getFlowValue(props.initialLabel, props.control)

  const options = useMemo(() => {
    if (optionsValue) {
      return !selectedValue
        ? [{ label: initialLabel ?? '...', value: '' }, ...optionsValue]
        : optionsValue
    }
  }, [optionsValue, selectedValue])

  return (
    <InputWrapper hasError={!!props?.error}>
      <TopWrapper>
        {!!props.label && (
          <Label size={{ xs: 14, md: 14 }} as="label" htmlFor={props.id}>
            {getFlowValue(props.label, props.control)}
            {props.required ? '*' : ''}
          </Label>
        )}
        {props?.error && (
          <ErrorMessage color={errorColor}>{props?.error}</ErrorMessage>
        )}
      </TopWrapper>

      <Select
        id={props.id}
        name={props.id}
        value={selectedValue}
        onChange={onChange}
        disabled={getFlowValue(props.isDisabled, props.control)}
        themeStyle={themeStyle?.select}
        hasError={!!props?.error}
      >
        {options?.map((option, index) => {
          const value = option.value
          const nothingSelected = !value && index === 0

          return (
            <option
              key={value}
              value={value}
              disabled={nothingSelected || option.disabled}
            >
              {option.label}
            </option>
          )
        })}
      </Select>
    </InputWrapper>
  )
}
