import { useEffect, useState } from 'react'

import { sentryLogging } from 'sentry-utils/logging'

import { scriptSource } from './config'
import type {
  SecureFields,
  SecureFieldsFieldsConfig,
  SecureFieldsOptions,
} from './types'

declare global {
  interface Window {
    SecureFields?: SecureFields
  }
}

export const useSecureFields = ({
  transactionId,
  fields,
  options,
}: {
  transactionId: string
  fields: SecureFieldsFieldsConfig
  options?: SecureFieldsOptions
}) => {
  const [secureFields, setSecureFields] = useState<SecureFields | undefined>(
    undefined
  )

  useEffect(() => {
    const init = () => {
      if (!window.SecureFields) return

      const secureFields = new window.SecureFields()
      secureFields.init(transactionId, fields, options)
      setSecureFields(secureFields)
    }

    if (document.querySelector(`script[src="${scriptSource}"]`)) {
      init()
    } else {
      const script = document.createElement('script')
      script.src = scriptSource
      script.onload = init
      script.onerror = () => {
        sentryLogging({
          message: 'SecureFields: Failed to load script',
          team: 'team-frontend-infrastructure',
        })
      }
      document.body.appendChild(script)
    }
  }, [transactionId])

  return secureFields
}
