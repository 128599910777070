import { useEffect, useState } from 'react'
import { add } from 'date-fns/add'
import { addDays } from 'date-fns/addDays'
import { isAfter } from 'date-fns/isAfter'
import { sub } from 'date-fns/sub'
import { AnimatePresence, motion } from 'framer-motion'
import styled, { useTheme } from 'styled-components'

import { colors } from 'bl-common/src/constants/colors'
import { SmallArrow } from 'bl-common/src/elements/SmallArrow'
import { Spinner } from 'bl-common/src/elements/Spinner'
import { Type } from 'bl-common/src/elements/Typography/Typography'
import { theme } from 'bl-common/src/styles/theme'
import type { PartialBookingEngine } from 'bl-common/src/styles/types'
import { globalBookingMessages } from 'bl-flows/src/messages/global'
import { LocationType } from 'bl-flows/src/types/Transportation'
import {
  getAvailableDepartureSlots,
  getAvailableLavaSlots,
  getAvailablePickupSlots,
  getAvailableSpaRestaurantSlots,
  getRecommendedDepartureSlots,
  getRecommendedRestaurantSlots,
} from 'bl-flows/src/utils'
import {
  CartType,
  useActivityProductAvailabilityQuery,
  useDepartureDetailsQuery,
  usePickupDetailsQuery,
  useRestaurantAvailabilityQuery,
} from 'bl-graphql/src/generated/hooks-with-types'
import { setDateTimeISOString } from 'bl-utils/src/date'
import { formatDateInUTC } from 'bl-utils/src/formatting/formatDate'
import {
  type DayVisitProductId,
  dayVisitProductIds,
  PRODUCT_IDS,
} from 'bl-utils/src/ProductIds'

import { buildDateTimeSelectField } from '../../builders'
import { SuggestionCard } from '../../components/SuggestionCard/SuggestionCard'
import { FieldRenderer } from '../../renderers'
import type {
  FlowAvailableDateTimesField,
  FlowComponent,
  TimeSlot,
} from '../../types'
import { getFlowValue } from '../../utils'

type AvailableDateTimesFieldProps = FlowComponent &
  FlowAvailableDateTimesField['props']

const Suggestions = styled.div({
  paddingBottom: theme.spacing[0.5],
})

const ToggleButton = styled.button<{
  themeStyle?: PartialBookingEngine['availableDateTimesField']
}>(({ themeStyle }) => ({
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  marginTop: theme.spacing[1],
  marginBottom: theme.spacing[1],
  color: themeStyle?.textColor ?? colors.midGrey,
}))

const Arrow = styled(Type)({
  transition: 'transform 150ms',
  paddingLeft: 5,
  svg: {
    width: 16,
    height: 12,
  },
})

const transportationDurations = {
  airport: 20,
  city: 60,
}

const getSelectId = (type: string): string => {
  switch (type) {
    case 'lava':
    case 'spaRestaurant':
      return 'time'
    case 'transportationPickup':
      return 'pickupTime'
    case 'transportationDeparture':
      return 'departureTime'
    default:
      return 'time'
  }
}

export const AvailableDateTimesField = ({
  screenTheme,
  control,
  ...props
}: AvailableDateTimesFieldProps) => {
  const type = getFlowValue(props.type, control)
  const bookingInfo = getFlowValue(props.bookingInfo, control)
  const activities = getFlowValue(props.activities, control)
  const transportation = getFlowValue(props.transportation, control)
  const hideRecommendedSlots = getFlowValue(props.hideRecommendedSlots, control)
  const showCurrentReservationLabel = getFlowValue(
    props.showCurrentReservationLabel,
    control
  )
  const currentReservationDate = getFlowValue(
    props.currentReservationDate,
    control
  )
  const guests = getFlowValue(props.guests, control)
  const isHotelBooking = getFlowValue(props.isHotelBooking, control)
  const isRetreatSpa = getFlowValue(props.isRetreatSpa, control)
  const isEditing = getFlowValue(props.isEditing, control)

  const [showList, setShowList] = useState(false)

  const theme = useTheme()
  const themeStyle =
    theme?.bookingEngine?.[screenTheme]?.availableDateTimesField
  const flowState = control.flow.state

  const onSelectTime = (slot: TimeSlot, date: Date) => {
    const { time } = slot
    control.screen.setState({
      time,
      date,
    })
    control.nextScreen()
  }

  const date = new Date(
    control?.screen?.state?.date
      ? control?.screen?.state?.date
      : control.flow.state?.time?.date
  )

  const numberOfGuests =
    guests || (bookingInfo.adults ?? 0) + (bookingInfo.children ?? 0)

  const dateFormatted = formatDateInUTC(date, 'yyyy-MM-dd')

  // Format the date with the time
  const formattedFromTime = setDateTimeISOString(
    dateFormatted,
    isHotelBooking ? '00:00' : formatDateInUTC(date, 'HH:mm')
  )
  const formattedToTime = setDateTimeISOString(dateFormatted, '23:59')

  const { data: spaRestaurantData, loading: isSpaRestaurantLoading } =
    useActivityProductAvailabilityQuery({
      fetchPolicy: 'network-only',
      variables: {
        input: {
          type: isHotelBooking ? CartType.Hotel : CartType.Dayspa,
          from: formattedFromTime,
          to: formattedToTime,
          productIds: [PRODUCT_IDS.SpaRestaurant],
        },
      },
      skip: type !== 'spaRestaurant',
    })

  const { data: lavaData, loading: lavaLoading } =
    useRestaurantAvailabilityQuery({
      fetchPolicy: 'network-only',
      variables: {
        date: dateFormatted,
      },
      skip: type !== 'lava',
    })

  const { data: pickupData, loading: pickupLoading } = usePickupDetailsQuery({
    variables: {
      date: formatDateInUTC(
        bookingInfo?.entryDate || bookingInfo?.hotelDates?.arrivalDate,
        'yyyy-MM-dd'
      ),
      arrivalTime: formatDateInUTC(
        bookingInfo?.entryDate || bookingInfo?.hotelDates?.arrivalDate,
        'HH:mm'
      ),
    },
    skip: type !== 'transportationPickup',
  })

  const departureDateFormatted = (formatString = 'yyyy-MM-dd') =>
    formatDateInUTC(
      bookingInfo?.entryDate || bookingInfo?.hotelDates?.arrivalDate,
      formatString
    )

  const { data: departureData, loading: departureLoading } =
    useDepartureDetailsQuery({
      variables: {
        date: departureDateFormatted(),
        arrivalTime: departureDateFormatted('HH:mm'),
      },
      skip: type !== 'transportationDeparture',
    })

  const nextDayEntryDateDeparture = addDays(
    bookingInfo?.entryDate || bookingInfo?.hotelDates?.arrivalDate,
    1
  )
  const nextDayEntryDateDepartureFormatted = formatDateInUTC(
    nextDayEntryDateDeparture,
    'yyyy-MM-dd'
  )

  const { data: nextDayDepartureData, loading: nextDayDepartureLoading } =
    useDepartureDetailsQuery({
      variables: {
        date: nextDayEntryDateDepartureFormatted,
        arrivalTime: '00:00',
      },
      skip: type !== 'transportationDeparture',
    })

  const selectedPickupLocation: LocationType =
    flowState?.pickup?.type ?? LocationType.airport
  const selectedDropoffLocation: LocationType =
    flowState?.dropoff?.type ?? LocationType.airport

  const isLoading =
    lavaLoading ||
    pickupLoading ||
    departureLoading ||
    nextDayDepartureLoading ||
    isSpaRestaurantLoading

  const departureSlots = departureData?.departureDetails?.[
    selectedDropoffLocation
  ]
    ? departureData.departureDetails[selectedDropoffLocation].map(slot => ({
        time: slot.time,
        available: slot.availability,
        date: departureDateFormatted(),
      }))
    : []
  const nextDayDepartureSlots = nextDayDepartureData?.departureDetails?.[
    selectedDropoffLocation
  ]
    ? nextDayDepartureData.departureDetails[selectedDropoffLocation].map(
        slot => ({
          time: slot.time,
          available: slot.availability,
          date: nextDayEntryDateDepartureFormatted,
        })
      )
    : []

  const earlyMorningSlots = nextDayDepartureSlots.filter(slot => {
    if (!slot.time || !slot.time.includes(':')) {
      return false
    }

    const [hours, minutes] = slot.time.split(':').map(Number)
    if (Number.isNaN(hours) || Number.isNaN(minutes)) {
      return false
    }

    return hours < 3 || (hours === 3 && minutes === 0)
  })

  const getTimeSlots = (): TimeSlot[] => {
    switch (type) {
      case 'lava':
        return getAvailableLavaSlots(
          (lavaData?.restaurantAvailability?.slots as TimeSlot[]) ?? [],
          transportation
        )
      case 'spaRestaurant':
        return getAvailableSpaRestaurantSlots(
          (spaRestaurantData?.activityProductAvailability as TimeSlot[]) ?? [],
          transportation
        )
      case 'transportationPickup':
        return getAvailablePickupSlots(
          (pickupData?.pickupDetails?.[selectedPickupLocation]?.map(slot => ({
            time: slot.time,
            available: slot.availability,
          })) as TimeSlot[]) ?? [],
          transportationDurations?.[selectedPickupLocation] ?? 0,
          bookingInfo?.entryDate,
          activities?.[0]
        )
      case 'transportationDeparture':
        return getAvailableDepartureSlots(
          departureSlots,
          bookingInfo?.entryDate,
          activities?.slice(-1)?.[0]
        )

      default:
        return []
    }
  }

  const isCheckinDay =
    !!bookingInfo?.hotelDates &&
    dateFormatted ===
      formatDateInUTC(bookingInfo?.hotelDates.arrivalDate, 'yyyy-MM-dd')

  const timeSlots = getTimeSlots()

  const filteredSlots = timeSlots.filter(slot => {
    return (
      isAfter(new Date(`${dateFormatted} ${slot?.time}`), new Date()) &&
      (isCheckinDay ? slot.time >= '15:00' : true)
    )
  })

  const dates = []
  if (bookingInfo?.hotelDates) {
    let currentDate = bookingInfo?.hotelDates?.arrivalDate
    const departureDate =
      type === 'lava' || type === 'spaRestaurant'
        ? bookingInfo?.hotelDates?.departureDate
        : add(bookingInfo?.hotelDates?.departureDate, { days: 1 })

    while (currentDate < departureDate) {
      dates.push(currentDate)
      currentDate = add(currentDate, { days: 1 })
    }
  } else {
    dates.push(bookingInfo?.entryDate)
  }

  const filteredDates = dates.filter(date => {
    const yesterday = sub(new Date(), { days: 1 })
    // We want to allow customers to book activities on the entry date/checkout date
    return isAfter(date, yesterday)
  })

  const activitiesExcludingDayVisit = activities?.filter(activity =>
    //filtering out lagoon activities to get recommended slots
    dayVisitProductIds.includes(activity.productNo as DayVisitProductId)
  )

  const getRecommendedSlots = (): TimeSlot[] => {
    switch (type) {
      case 'lava':
      case 'spaRestaurant':
        return getRecommendedRestaurantSlots({
          timeSlots: filteredSlots ?? [],
          entryDate:
            bookingInfo?.entryDate ||
            (activitiesExcludingDayVisit.length
              ? new Date(activitiesExcludingDayVisit[0]?.date)
              : undefined),
          lastActivity: activities?.slice(-1)?.[0],
        })?.filter(slot => slot.available >= numberOfGuests)
      case 'transportationPickup':
        // Make sure to filter out slots that are not available for the number of guests
        return filteredSlots
          .filter(slot => slot.available >= numberOfGuests)
          .slice(-1)
      case 'transportationDeparture':
        return getRecommendedDepartureSlots(
          [...timeSlots, ...earlyMorningSlots],
          bookingInfo?.entryDate,
          activities?.slice(-1)?.[0],
          isRetreatSpa
        )?.filter(slot => slot.available >= numberOfGuests)
      default:
        return []
    }
  }

  const recommendedSlots = getRecommendedSlots()

  const selectedPickupTime = control?.flow?.state?.pickupTime?.time
  const selectedDepartureTime = control?.flow?.state?.departureTime?.time

  // Select the first option in the select box if nothing is selected
  // or if the selected option is not available
  useEffect(() => {
    if (isHotelBooking || isEditing) {
      if (
        (!control?.flow?.state?.time?.time && type === 'lava') ||
        (!control?.flow?.state?.time?.time && type === 'spaRestaurant') ||
        ((!selectedPickupTime ||
          !filteredSlots.find(slot => slot.time === selectedPickupTime)) &&
          type === 'transportationPickup') ||
        ((!selectedDepartureTime ||
          !filteredSlots.find(slot => slot.time === selectedDepartureTime)) &&
          type === 'transportationDeparture')
      ) {
        if (filteredSlots[0]?.time) {
          control?.screen?.setState({
            time: filteredSlots[0]?.time,
          })
        }
      }
      setShowList(true)
    }
  }, [filteredSlots.length])

  // Show DateTimeSelectField if there are no recomended slots or it shouldn't
  // be displayed.
  useEffect(() => {
    if (recommendedSlots.length === 0 || hideRecommendedSlots) {
      setShowList(true)
    } else {
      setShowList(false)
    }
  }, [recommendedSlots.length, hideRecommendedSlots])

  return isLoading ? (
    <Spinner shouldAnimate />
  ) : (
    <>
      {recommendedSlots.length > 0 && !hideRecommendedSlots && (
        <>
          <Type preset="textLarge" weight="bold" bottom={0.5}>
            {control.context.t(globalBookingMessages.labels.suggestionLabel)}
          </Type>
          <Suggestions>
            {recommendedSlots.map(slot => {
              return (
                <SuggestionCard
                  slot={slot}
                  key={slot.time}
                  date={slot.date || control?.screen?.state?.date}
                  onSelectTime={onSelectTime}
                  control={control}
                  themeStyle={themeStyle?.suggestionCard}
                />
              )
            })}
          </Suggestions>
        </>
      )}

      <ToggleButton
        onClick={() => setShowList(!showList)}
        aria-controls="timeslots"
        aria-expanded={showList}
        type="button"
        disabled={isHotelBooking || isEditing}
        style={{ cursor: (isHotelBooking || isEditing) && 'default' }}
        themeStyle={themeStyle}
      >
        <Type preset="textLarge" weight="bold" right={{ md: 0.5 }} inline>
          {control.context.t(globalBookingMessages.labels.customizationLabel)}
        </Type>
        {!isEditing && !isHotelBooking && (
          <Arrow
            preset="label"
            case="uppercase"
            aria-hidden
            inline
            style={{ transform: `rotate(${showList ? '90deg' : '0deg'})` }}
          >
            <SmallArrow />
          </Arrow>
        )}
      </ToggleButton>
      <AnimatePresence>
        {showList && (
          <motion.div
            key="dateTimeSelect"
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: 'auto' }}
            exit={{ opacity: 0, height: 0 }}
            transition={{ ease: 'linear', duration: 0.3 }}
          >
            <FieldRenderer
              control={control}
              screenTheme={screenTheme}
              item={buildDateTimeSelectField({
                id: getSelectId(type),
                defaultValue: null,
                props: {
                  dates: filteredDates,
                  timeSlots: filteredSlots,
                  isCard: true,
                  guests: guests || bookingInfo.adults + bookingInfo.children,
                  showCurrentReservationLabel,
                  currentReservationDate,
                  type,
                  isHotelBooking,
                },
              })}
            />
          </motion.div>
        )}
      </AnimatePresence>
    </>
  )
}
