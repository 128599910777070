import { defineMessages } from 'react-intl'

import { HB_PRODUCT_IDS, PRODUCT_IDS } from 'bl-utils/src/ProductIds'

export const productIdsMessages = defineMessages({
  [PRODUCT_IDS.BLWinterCard]: {
    id: 'sharedFlows:productIds.B-071',
    defaultMessage: 'Winter card package',
    description: 'ProductIds: Winter card package',
  },
  [PRODUCT_IDS.BLSummerCard]: {
    id: 'sharedFlows:productIds.B-138',
    defaultMessage: 'Summer card package',
    description: 'ProductIds: Summer card package',
  },
  [PRODUCT_IDS.BLWinterCardFamily]: {
    id: 'sharedFlows:productIds.B-070',
    defaultMessage: 'Winter card family package',
    description: 'ProductIds: Winter card family package',
  },
  [PRODUCT_IDS.BLSummerCardFamily]: {
    id: 'sharedFlows:productIds.B-139',
    defaultMessage: 'Summer card family package',
    description: 'ProductIds: Summer card family package',
  },
  [PRODUCT_IDS.SpaComfort]: {
    id: 'sharedFlows:productIds.G-BL-410A',
    defaultMessage: 'Comfort admission',
    description: 'ProductIds: Comfort admission',
  },
  [PRODUCT_IDS.SpaPremium]: {
    id: 'sharedFlows:productIds.G-BL-420A',
    defaultMessage: 'Premium admission',
    description: 'ProductIds: Premium admission',
  },
  [PRODUCT_IDS.SpaSignature]: {
    id: 'sharedFlows:productIds.G-BL-430',
    defaultMessage: 'Signature admission',
    description: 'ProductIds: Signature package',
  },
  [PRODUCT_IDS.SpaRetreat]: {
    id: 'sharedFlows:productIds.T-100',
    defaultMessage: 'Retreat admission',
    description: 'ProductIds: Retreat admission',
  },
  [PRODUCT_IDS.SpaValentines]: {
    id: 'sharedFlows:productIds.G-BL-430A',
    defaultMessage: 'Valentine admission',
    description: 'ProductIds: Valentine admission',
  },
  [PRODUCT_IDS.SpaChild]: {
    id: 'sharedFlows:productIds.G-BL-404',
    defaultMessage: 'Children admission',
    description: 'ProductIds: Child admission',
  },
  [PRODUCT_IDS.Champagne]: {
    id: 'sharedFlows:productIds.V-OLG-66502',
    defaultMessage: 'Champagne',
    description: 'ProductIds: Champagne extra',
  },
  [PRODUCT_IDS.SparklingWine]: {
    id: 'sharedFlows:productIds.V-OLG-60442',
    defaultMessage: 'Sparkling Wine',
    description: 'ProductIds: Sparkling wine',
  },
  [PRODUCT_IDS.ChocolatesAndStrawberries]: {
    id: 'sharedFlows:productIds.V-MOK-3004',
    defaultMessage: 'Chocolate & Strawberries',
    description: 'ProductIds: Chocolates & Strawberries',
  },
  [PRODUCT_IDS.SilicaMudMask75ml]: {
    id: 'sharedFlows:productIds.BL1B01F1',
    defaultMessage: 'Silica Mud Mask 75ml',
    description: 'ProductIds: Silica mud mask 75 ml',
  },
  [PRODUCT_IDS.SilicaMudMask100ml]: {
    id: 'sharedFlows:productIds.BL1B01F',
    defaultMessage: 'Silica Mud Mask 100ml',
    description: 'ProductIds: Silica mud mask 100 ml',
  },
  [PRODUCT_IDS.SilicaMudMask30ml]: {
    id: 'sharedFlows:productIds.BL1B02M3',
    defaultMessage: 'Silica Mud Mask 30ml',
    description: 'ProductIds: Silica mud mask 30 ml',
  },
  [PRODUCT_IDS.MassageFloatingOne]: {
    id: 'sharedFlows:productIds.B-230',
    defaultMessage: 'Solo Float Therapy',
    description: 'ProductIds: Solo Float Therapy',
  },
  [PRODUCT_IDS.MassageFloatingTwo]: {
    id: 'sharedFlows:productIds.B-230A',
    defaultMessage: 'Couples Float Therapy',
    description: 'ProductIds: Couples Float Therapy',
  },
  [PRODUCT_IDS.MassageFloatingGroup]: {
    id: 'sharedFlows:productIds.B-230B',
    defaultMessage: 'Group Float Therapy (Samflot)',
    description: 'ProductIds: Group Float Therapy',
  },
  [PRODUCT_IDS.Massage30]: {
    id: 'sharedFlows:productIds.B-217',
    defaultMessage: 'Relaxing in-water massage (30 min)',
    description: 'ProductIds: Relaxing in-water massage (30 min)',
  },
  [PRODUCT_IDS.Massage60]: {
    id: 'sharedFlows:productIds.B-218',
    defaultMessage: 'Relaxing in-water massage (60 min)',
    description: 'ProductIds: Relaxing in-water massage (60 min)',
  },
  [PRODUCT_IDS.Massage120]: {
    id: 'sharedFlows:productIds.B-229',
    defaultMessage: 'Signature in-water massage (120 min)',
    description: 'ProductIds: Signature In Water Massage',
  },
  [PRODUCT_IDS.RestaurantLava]: {
    id: 'sharedFlows:productIds.LAVA-RES',
    defaultMessage: 'Lava Table Reservation',
    description: 'ProductIds: LAVA Table Reservation',
  },
  [PRODUCT_IDS.SpaRestaurant]: {
    id: 'sharedFlows:productIds.SPA-RES',
    defaultMessage: 'Spa Restaurant Table Reservation',
    description: 'ProductIds: Spa Restaurant Table Reservation',
  },
  [PRODUCT_IDS.RestaurantMoss]: {
    id: 'sharedFlows:productIds.MOSS-RES',
    defaultMessage: 'Michelin-dining at Moss',
    description: 'ProductIds: Moss Table Reservation',
  },
  [HB_PRODUCT_IDS.HighlandBaseRestaurant]: {
    id: 'sharedFlows:productIds.KER-RES"',
    defaultMessage: 'Highland Base Restaurant Table Reservation',
    description: 'ProductIds: Highland Base Restaurant Table Reservation',
  },
  [PRODUCT_IDS.TransferTwoWay]: {
    id: 'sharedFlows:productIds.R-105',
    defaultMessage: 'DBL Two way transfer',
    description: 'ProductIds: DBL Two way transfer',
  },
  [PRODUCT_IDS.PrivateTransferDepartureLarge]: {
    id: 'sharedFlows:productIds.VALDEMAR - 13',
    defaultMessage: 'Private transfer from Blue Lagoon to Kef Airport',
    description:
      'ProductIds: Private transfer: Blue lagoon - KEF Airport (4-8 persons) - VALDEMAR',
  },
  [PRODUCT_IDS.PrivateTransferDepartureSmall]: {
    id: 'sharedFlows:productIds.VALDEMAR - 2',
    defaultMessage: 'Private transfer from Blue Lagoon to Kef Airport',
    description:
      'ProductIds: Private transfer: Blue lagoon - KEF Airport (1-3 persons) - VALDEMAR',
  },
  [PRODUCT_IDS.PrivateTransferArrivalLarge]: {
    id: 'sharedFlows:productIds.VALDEMAR - 14',
    defaultMessage: 'Private transfer from Kef Airport to Blue Lagoon',
    description:
      'ProductIds: Private transfer: KEF Airport - Blue lagoon (4-8 persons) - VALDEMAR',
  },
  [PRODUCT_IDS.PrivateTransferArrivalSmall]: {
    id: 'sharedFlows:productIds.VALDEMAR - 3',
    defaultMessage: 'Private transfer from Kef Airport to Blue Lagoon',
    description:
      'ProductIds: Private transfer: KEF Airport - Blue lagoon (1-3 persons) - VALDEMAR',
  },
  [PRODUCT_IDS.FlowersBlomakot]: {
    id: 'sharedFlows:productIds.BLÓMAKOT',
    defaultMessage: 'Flowers',
    description: 'ProductIds: Flowers - Blomakot',
  },
  [PRODUCT_IDS.FlowersBlomaval]: {
    id: 'sharedFlows:productIds.BLÓMAVAL',
    defaultMessage: 'Flowers',
    description: 'ProductIds: Flowers - Blomaval',
  },
  [PRODUCT_IDS.Balloons]: {
    id: 'sharedFlows:productIds.MIMOSA',
    defaultMessage: 'Balloons',
    description: 'ProductIds: Balloons ',
  },
  [PRODUCT_IDS.Mimosa]: {
    id: 'sharedFlows:productIds.H-TA22',
    defaultMessage: 'Mimosa',
    description: 'ProductIds: Mimosa (room-addon)',
  },
  [PRODUCT_IDS.FlowersGifts]: {
    id: 'sharedFlows:productIds.H-TA16',
    defaultMessage: 'Flowers & Gifts',
    description: 'ProductIds: Flowers & Gifts (room-addon)',
  },
  [HB_PRODUCT_IDS.AdultAdmission]: {
    id: 'sharedFlows:productIds.A-200',
    defaultMessage: 'Highland Base Baths Admission',
    description: 'ProductIds: Highland Base Baths Admission',
  },
  [HB_PRODUCT_IDS.TeenAdmission]: {
    id: 'sharedFlows:productIds.A-201',
    defaultMessage: 'Highland Base Baths admission - Young Adult',
    description: 'ProductIds: Highland Base Baths Admission Young Adult',
  },
  [HB_PRODUCT_IDS.ChildAdmission]: {
    id: 'sharedFlows:productIds.A-202',
    defaultMessage: 'Highland Base Baths admission - Child',
    description: 'ProductIds: Highland Base Baths Admission Child',
  },
  [HB_PRODUCT_IDS.BuggySingleRide]: {
    id: 'sharedFlows:productIds.BUGGY-1',
    defaultMessage: 'Buggy Single Ride',
    description: 'ProductIds: Buggy Single Ride',
  },
  [HB_PRODUCT_IDS.BuggyDoubleRide]: {
    id: 'sharedFlows:productIds.BUGGY-2',
    defaultMessage: 'Buggy Double Ride',
    description: 'ProductIds: Buggy Double Ride',
  },
  [HB_PRODUCT_IDS.SnowmobileSingleRide]: {
    id: 'sharedFlows:productIds.SNOW-1',
    defaultMessage: 'Individual snowmobile (one rider)',
    description: 'ProductIds: Individual snowmobile with one rider',
  },
  [HB_PRODUCT_IDS.SnowmobileDoubleRide]: {
    id: 'sharedFlows:productIds.SNOW-2',
    defaultMessage: 'Shared snowmobile (two riders)',
    description: 'ProductIds: Shared snowmobile with two riders',
  },
})
