import type { Asset } from 'contentful'
import styled from 'styled-components'

import { Picker } from '../../booking/Picker/Picker'
import { Amount } from '../../elements/Amount'
import { ContentfulImage } from '../../elements/ContentfulImage'
import { Type } from '../../elements/Typography/Typography'
import { theme } from '../../styles/theme'
import type { PartialBookingEngine } from '../../styles/types'

const CardWrapper = styled.div<{
  hasImage?: boolean
  themeStyle?: PartialBookingEngine['addProductCardField']
}>(({ hasImage, themeStyle }) => ({
  boxShadow: '0px 0px 30px rgba(0, 0, 0, 0.1)',
  maxWidth: hasImage && 340,

  background: themeStyle?.cardBackground ?? 'inherit',
}))

const ImageContainer = styled.div({
  aspectRatio: '1.5',
  position: 'relative',
})

const ContentContainer = styled.div({
  padding: `${theme.spacing[2]} ${theme.spacing[1.5]}`,
})

const Bottom = styled.div({
  marginTop: theme.spacing[1.5],
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',

  p: {
    flexShrink: 0,
  },
})

const DiscountPrice = styled.span({
  marginLeft: 5,
})

type AddProductCardProps = {
  image?: Asset
  title: string
  description: string
  price: number
  discountedPrice: number
  quantity: number
  updateQuantity: (quantity: number) => void
  hasImage?: boolean
  themeStyle?: PartialBookingEngine['addProductCardField']
}

export const AddProductCard = ({
  image,
  title,
  description,
  price,
  discountedPrice,
  quantity,
  updateQuantity,
  hasImage = true,
  themeStyle,
}: AddProductCardProps) => {
  const discount = price - discountedPrice

  return (
    <CardWrapper hasImage={hasImage} themeStyle={themeStyle}>
      {hasImage && (
        <ImageContainer>
          {image && (
            <ContentfulImage
              alt={title}
              sizes="(min-width: 75em) 20vw, (min-width: 48em) 25vw, 50vw"
              image={image}
            />
          )}
        </ImageContainer>
      )}

      <ContentContainer>
        <Type preset="text" weight="bold">
          {title}
        </Type>
        <Type preset="text" mTop={0.5}>
          {description}
        </Type>
        <Bottom>
          <Type preset="textSmall" weight="bold">
            <Amount value={price} lineThrough={discount > 0} />
            {discount > 0 && (
              <DiscountPrice>
                <Amount value={discountedPrice} />
              </DiscountPrice>
            )}
          </Type>
          <Picker
            value={quantity}
            onChange={newValue => {
              updateQuantity(newValue)
            }}
            text=""
            increaseAriaLabel="Increase quantity of product"
            decreaseAriaLabel="Decrease quantity of product"
            rounded
            fixedSize
            iconColor={themeStyle?.picker?.iconColor}
          />
        </Bottom>
      </ContentContainer>
    </CardWrapper>
  )
}
