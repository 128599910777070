import { sentryLogging } from 'sentry-utils/logging'

import { formatDateInUTC } from './formatting/formatDate'

export const logActivityUnavailable = ({
  activityType,
  spaDate,
  hotelDates,
}: {
  activityType: string
  spaDate: Date
  hotelDates: string[]
}) => {
  const isSpa = Boolean(spaDate)
  const formattedStringDates = isSpa
    ? formatDateInUTC(spaDate)
    : hotelDates.map(date => formatDateInUTC(date)).join(', ')

  sentryLogging({
    team: isSpa ? 'team-day-visit-svartsengi' : 'team-hotels-svartsengi',
    message: `No ${activityType} availability for dates: ${formattedStringDates}`,
  })
}
