import { type AdmissionItem, CartItemType } from 'bl-graphql'
import {
  productIdToItemId,
  productIdToItemListId,
  productIdToItemListName,
  productIdToName,
} from 'bl-utils/src/analytics'
import type { Item } from 'bl-utils/src/analytics/types/google-analytics-events'
import { calcPrice } from 'bl-utils/src/currency/calcPrice'
import {
  CartItemActivities,
  HB_PRODUCT_IDS,
  PRODUCT_IDS,
} from 'bl-utils/src/ProductIds'

const SpaIndexByType = { comfort: 0, premium: 1, retreatspa: 2 }

export const getHotelBookingItems = (
  orderedRooms,
  exchangeRates,
  state = undefined
) => {
  const analyticsItems: Item[] = []
  let roomNumber = 0
  let hotel
  orderedRooms?.forEach(item => {
    if (item?.type === CartItemType.Hotel) {
      hotel = item?.fields?.hotel
      roomNumber++
      const packageCost =
        state?.packageDetails?.packageSelection?.[item?.id]?.packageCost
      const roomPrice = packageCost
        ? item?.linePrice - packageCost
        : item?.linePrice

      analyticsItems.push({
        item_id: item?.fields?.title?.replace(/\s+/g, '_').toLowerCase(),
        item_name: item?.fields?.title,
        currency: 'EUR',
        ...(item?.discountedPrice > 0 && {
          discount: calcPrice(
            item?.price - item?.discountedPrice,
            exchangeRates?.EUR
          ),
        }),
        item_brand: hotel,
        item_category: 'Accommodation',
        item_list_id: `accommodation_room${roomNumber}`,
        item_list_name: `Accommodation Room${roomNumber}`,
        price: calcPrice(roomPrice, exchangeRates?.EUR),
        quantity: 1,
      })

      // state is only included in the function in cases where packages are seperated from the room
      // in those cases we add the packages here
      if (packageCost) {
        analyticsItems.push({
          item_id: item?.packageTitle?.replace(/\s+/g, '_').toLowerCase(),
          item_name: item?.packageTitle,
          currency: 'EUR',
          discount: 0,
          item_brand: hotel,
          item_category: 'Accommodation',
          item_list_id: `hotel_package${roomNumber}`,
          item_list_name: `Hotel Package${roomNumber}`,
          price: calcPrice(packageCost, exchangeRates?.EUR),
          quantity: 1,
        })
      }
    }

    if (item?.type === CartItemType.Item) {
      analyticsItems.push({
        item_id: item?.fields?.name?.replace(/\s+/g, '_').toLowerCase(),
        item_name: item?.fields?.name,
        currency: 'EUR',
        ...(item?.discountedPrice > 0 && {
          discount: calcPrice(
            item?.price - item?.discountedPrice,
            exchangeRates?.EUR
          ),
        }),
        item_brand: hotel,
        item_category: 'Accommodation',
        item_list_id: `room${roomNumber}_addons`,
        item_list_name: `Room${roomNumber} add-ons`,
        price: calcPrice(item?.linePrice, exchangeRates?.EUR),
        quantity: item?.qty,
      })
    }

    if (CartItemActivities.find(cartItem => item?.type === cartItem)) {
      analyticsItems.push({
        item_id: item?.fullName?.replace(/\s+/g, '_').toLowerCase(),
        item_name: item?.fullName,
        currency: 'EUR',
        ...(item?.discountedPrice > 0 && {
          discount: calcPrice(
            item?.price - item?.discountedPrice,
            exchangeRates?.EUR
          ),
        }),
        item_brand: hotel,
        item_category: 'Accommodation',
        item_list_id: `room${roomNumber}_activities`,
        item_list_name: `Room${roomNumber} Activities`,
        price: calcPrice(item?.linePrice, exchangeRates?.EUR),
        quantity: item?.qty,
      })
    }

    if (item?.type === CartItemType.PrivateTransfer) {
      analyticsItems.push({
        item_id: item?.fullName?.replace(/\s+/g, '_').toLowerCase(),
        item_name: item?.fullName,
        currency: 'EUR',
        ...(item?.discountedPrice > 0 && {
          discount: calcPrice(
            item?.price - item?.discountedPrice,
            exchangeRates?.EUR
          ),
        }),
        item_brand: hotel,
        item_category: 'Accommodation',
        item_list_id: `room${roomNumber}_transportation`,
        item_list_name: `Room${roomNumber} Transportation`,
        price: calcPrice(item?.linePrice, exchangeRates?.EUR),
        quantity: item?.qty,
      })
    }
  })

  return analyticsItems
}

const getSpaType = (admission: AdmissionItem) => {
  switch (admission.productId) {
    case PRODUCT_IDS.SpaComfort:
      return 'comfort'
    case PRODUCT_IDS.SpaPremium:
      return 'premium'
    case PRODUCT_IDS.SpaRetreat:
      return 'retreatspa'
    case HB_PRODUCT_IDS.AdultAdmission:
    case HB_PRODUCT_IDS.ChildAdmission:
    case HB_PRODUCT_IDS.TeenAdmission:
      return 'highlandbasebaths'
  }
}

export const getSpaBookingItems = (
  orderedAdmissions,
  exchangeRates,
  coupon?: string
) => {
  const analyticsItems: Item[] = []
  let hasAddedTransportation = false

  orderedAdmissions?.forEach(item => {
    if (!item) return
    if (item.type === CartItemType.Admission) {
      const spaType = getSpaType(item)
      const index = SpaIndexByType[spaType] ?? 0
      const price = item.price

      analyticsItems.push({
        item_id: productIdToItemId[item.productId] || spaType,
        item_name:
          productIdToName[item.productId] || item.fullName || item.displayName,
        currency: 'EUR',
        coupon,
        index,
        ...(item.discountedPrice > 0 && {
          discount: calcPrice(
            item.price - item.discountedPrice,
            exchangeRates?.EUR
          ),
        }),
        item_brand: 'Blue Lagoon',
        item_category: 'Day Visit',
        item_list_id: 'day_visit',
        item_list_name: 'Day Visit',
        price: calcPrice(price, exchangeRates?.EUR),
        quantity: spaType === 'retreatspa' ? item.qty : item.adultQty,
      })

      if (item.childQty) {
        analyticsItems.push({
          item_id: productIdToItemId[item.productId] || spaType,
          item_name:
            productIdToName[item.productId] ||
            item.fullName ||
            item.displayName,
          currency: 'EUR',
          coupon,
          index,
          item_brand: 'Blue Lagoon',
          item_category: 'Day Visit',
          item_list_id: 'day_visit',
          item_list_name: 'Day Visit',
          price: 0,
          quantity: item.childQty,
        })
      }
    }

    if (item.type === CartItemType.Item) {
      analyticsItems.push({
        item_id:
          item.productId === PRODUCT_IDS.SilicaMudMask75ml
            ? 'skincare'
            : item.productId,
        item_name:
          productIdToName[item.productId] || item.fullName || item.displayName,

        currency: 'EUR',
        coupon,
        ...(item.discountedPrice > 0 && {
          discount: calcPrice(
            item.price - item.discountedPrice,
            exchangeRates?.EUR
          ),
        }),
        item_brand: 'Blue Lagoon',
        item_category: 'Day Visit',
        item_list_id: productIdToItemListId[item.productId],
        item_list_name: productIdToItemListName[item.productId],
        price: calcPrice(item.price, exchangeRates?.EUR),
        quantity: item.qty,
      })
    }

    if (item.type === CartItemType.Transfer && !hasAddedTransportation) {
      hasAddedTransportation = true
      analyticsItems.push({
        item_id: item.productId,
        item_name:
          productIdToName[item.productId] || item.fullName || item.displayName,

        currency: 'EUR',
        ...(item.discountedPrice > 0 && {
          discount: calcPrice(
            item.price - item.discountedPrice,
            exchangeRates?.EUR
          ),
        }),
        item_brand: 'Blue Lagoon',
        item_category: 'Day Visit',
        price: calcPrice(item.price, exchangeRates?.EUR),
        quantity: item.qty,
      })
    }

    if (CartItemActivities.find(cartItem => item.type === cartItem)) {
      analyticsItems.push({
        item_id:
          item.productId === PRODUCT_IDS.RestaurantLava
            ? 'lava'
            : item.productId,
        item_name:
          productIdToName[item.productId] || item.fullName || item.displayName,
        currency: 'EUR',
        coupon,
        ...(item.discountedPrice > 0 && {
          discount: calcPrice(
            item.price - item.discountedPrice,
            exchangeRates?.EUR
          ),
        }),
        item_brand: 'Blue Lagoon',
        item_category: 'Day Visit',
        item_list_id: productIdToItemListId[item.productId],
        item_list_name: productIdToItemListName[item.productId],
        price: calcPrice(item.price, exchangeRates?.EUR),
        quantity: item.qty,
      })
    }
  })

  return analyticsItems
}

export const getHighlandBaseBathsBookingItems = (
  items,
  exchangeRates,
  coupon?: string
) => {
  const analyticsItems: Item[] = []
  let hasAddedTransportation = false

  items?.forEach(item => {
    if (!item) return
    if (item.type === CartItemType.Admission) {
      const price = item.price

      analyticsItems.push({
        item_id: productIdToItemId[item.productId],
        item_name:
          productIdToName[item.productId] || item.fullName || item.displayName,
        currency: 'EUR',
        coupon,
        index: 0,
        ...(item.discountedPrice > 0 && {
          discount: calcPrice(
            item.price - item.discountedPrice,
            exchangeRates?.EUR
          ),
        }),
        item_brand: 'Highland Base',
        item_category: 'Day Visit',
        item_list_id: 'day_visit',
        item_list_name: 'Day Visit',
        price: calcPrice(price, exchangeRates?.EUR),
        quantity: item.adultQty,
      })

      if (item.youngAdultQty && item.youngAdultItem) {
        analyticsItems.push({
          item_id: productIdToItemId[item.productId],
          item_name:
            productIdToName[item.productId] ||
            item.fullName ||
            item.displayName,
          currency: 'EUR',
          coupon,
          index: 0,
          item_brand: 'Highland Base',
          item_category: 'Day Visit',
          item_list_id: 'day_visit',
          item_list_name: 'Day Visit',
          price: calcPrice(item.youngAdultItem.price, exchangeRates?.EUR),
          quantity: item.youngAdultQty,
        })
      }

      if (item.childQty) {
        analyticsItems.push({
          item_id: productIdToItemId[item.productId],
          item_name:
            productIdToName[item.productId] ||
            item.fullName ||
            item.displayName,
          currency: 'EUR',
          coupon,
          index: 0,
          item_brand: 'Highland Base',
          item_category: 'Day Visit',
          item_list_id: 'day_visit',
          item_list_name: 'Day Visit',
          price: 0,
          quantity: item.childQty,
        })
      }
    }

    if (item.type === CartItemType.Item) {
      analyticsItems.push({
        item_id: item.productId,
        item_name:
          productIdToName[item.productId] || item.fullName || item.displayName,

        currency: 'EUR',
        coupon,
        ...(item.discountedPrice > 0 && {
          discount: calcPrice(
            item.price - item.discountedPrice,
            exchangeRates?.EUR
          ),
        }),
        item_brand: 'Highland Base',
        item_category: 'Day Visit',
        item_list_id: productIdToItemListId[item.productId],
        item_list_name: productIdToItemListName[item.productId],
        price: calcPrice(item.price, exchangeRates?.EUR),
        quantity: item.qty,
      })
    }

    if (item.type === CartItemType.Transfer && !hasAddedTransportation) {
      hasAddedTransportation = true
      analyticsItems.push({
        item_id: item.productId,
        item_name:
          productIdToName[item.productId] || item.fullName || item.displayName,

        currency: 'EUR',
        ...(item.discountedPrice > 0 && {
          discount: calcPrice(
            item.price - item.discountedPrice,
            exchangeRates?.EUR
          ),
        }),
        item_brand: 'Highland Base',
        item_category: 'Day Visit',
        price: calcPrice(item.price, exchangeRates?.EUR),
        quantity: item.meta?.noOfPersons || item.qty,
      })
    }

    if (CartItemActivities.find(cartItem => item.type === cartItem)) {
      analyticsItems.push({
        item_id: item.productId,
        item_name:
          productIdToName[item.productId] || item.fullName || item.displayName,
        currency: 'EUR',
        coupon,
        ...(item.discountedPrice > 0 && {
          discount: calcPrice(
            item.price - item.discountedPrice,
            exchangeRates?.EUR
          ),
        }),
        item_brand: 'Highland Base',
        item_category: 'Day Visit',
        item_list_id: productIdToItemListId[item.productId],
        item_list_name: productIdToItemListName[item.productId],
        price: calcPrice(item.price, exchangeRates?.EUR),
        quantity: item.meta?.noOfPersons || item.qty,
      })
    }
  })

  return analyticsItems
}
