import { useContext, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import type { GetStaticPaths, InferGetStaticPropsType } from 'next'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import type { Entry } from 'contentful'
import get from 'lodash/get'
import styled from 'styled-components'

import type { IBeSpaFlowFields } from 'contentful-shared'
import { sentryLogging } from 'sentry-utils/logging'
import { mediaObj } from 'ui-primitives/src/utils/media'
import { CartProvider } from 'bl-common/src/context/CartContext'
import {
  CurrencyContext,
  CurrencyProvider,
} from 'bl-common/src/context/Currency/CurrencyProvider'
import { Spinner } from 'bl-common/src/elements/Spinner'
import { SpinnerWrapper } from 'bl-common/src/elements/SpinnerWrapper'
import { useCartContext } from 'bl-common/src/hooks/useCartContext'
import { useGlobalPageViewTrigger } from 'bl-common/src/hooks/useGlobalPageViewTrigger'
import {
  getSpaComfortOrPremiumAlertBanner,
  getSpaComfortOrPremiumMetadata,
  getSpaSummerAndWinterCardDateRange,
} from 'bl-flows/src/flows/spa/utils'
import { Container } from 'bl-flows-core/src/layouts/ImageLayout/styles'
import { Screen } from 'bl-flows-core/src/renderers/ScreenRenderer'
import { CartType } from 'bl-graphql'
import { IntlProviderWrapper } from 'bl-localization/src/IntlProviderWrapper'
import { useFeatureFlagEnabledWrapper } from 'bl-posthog/src/useFeatureFlagEnabledWrapper'
import {
  isNowInDateRange,
  whichMembershipInfoToShow,
} from 'bl-utils/src/membership'

import SpaFlowRenderer from 'components/FlowSetup/SpaFlowRenderer'
import InformationBanner from 'components/InformationBanner'
import { Metadata } from 'components/Metadata'
import { getEntriesWrapper, getEntryWrapper } from 'services/contentfulClient'
import { getCurrentEnvironment } from 'utils/environment'

const CommandPaletteWrapper = dynamic(
  () => import('components/CommandPalette'),
  {
    ssr: false,
  }
)

const FlowWrapper = styled('div')({
  height: '100%',

  //Temporary: can be removed when alert banner is removed:
  [mediaObj.bmd]: {
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    [Screen]: {
      overflowY: 'hidden',
    },
    [Container]: {
      height: '100%',
      overflowY: 'hidden',
    },
  },
})

const environment = getCurrentEnvironment()

export const CreateFlexBookingPage = (
  flowBasePath: string,
  locale: 'is' | 'en'
) => {
  const BookingPage = ({
    spaFlowData,
    useMCPPayment,
  }: InferGetStaticPropsType<typeof getStaticProps> & {
    useMCPPayment?: boolean
  }) => {
    const router = useRouter()
    const [isReady, setIsReady] = useState(false)
    const [hasError, setHasError] = useState(false)
    const [retryTimeMs, setRetryTimeMs] = useState(2000)
    // eslint-disable-next-line @typescript-eslint/unbound-method
    const { formatMessage } = useIntl()
    const { countryCode: detectedCC, currency } = useContext(CurrencyContext)
    const { cartId, cart, watchCart, createCart } = useCartContext()

    // Feature flags
    const useSeasonalPackages = useFeatureFlagEnabledWrapper(
      '[SPA]seasonal-packages'
    )

    useGlobalPageViewTrigger(locale, currency)

    // Get spa flow settings for comfort and premium
    const { summerCardDateRange, winterCardDateRange } =
      getSpaSummerAndWinterCardDateRange(spaFlowData)

    const isSummerCardAllowed = isNowInDateRange(
      summerCardDateRange?.fields?.from,
      summerCardDateRange?.fields?.to
    )

    const isWinterCardAllowed = isNowInDateRange(
      winterCardDateRange?.fields?.from,
      winterCardDateRange?.fields?.to
    )

    const context = {
      t: formatMessage,
      locale,
      detectedCC,
      contentfulClient: {
        getEntriesWrapper,
        getEntryWrapper,
      },
      spaFlowSettings: spaFlowData || [],
      enableAddGroupFloat: useFeatureFlagEnabledWrapper('[SPA]add-group-float'),
      useMCPPayment,
      useSeasonalPackages,
      membershipSettings: {
        isSummerCardAllowed,
        isWinterCardAllowed,
        whichMembershipInfoToShow: whichMembershipInfoToShow({
          isSummerCardAllowed,
          isWinterCardAllowed,
          summerStartDateString: summerCardDateRange?.fields?.from,
          winterStartDateString: winterCardDateRange?.fields?.from,
        }),
      },
    }

    useEffect(() => {
      async function setup() {
        try {
          await createCart({
            cartType: CartType.Dayspa,
          })
          setIsReady(true)
        } catch (error) {
          sentryLogging({
            team: 'team-day-visit-svartsengi',
            error: new Error('Failed to create cart for flex-spa.'),
            extras: { error },
          })
          setHasError(true)
          // Retry first after 2 seconds, then times two after that up to two minutes.
          // Example failures: API is down or rate limit is hit.
          setRetryTimeMs(prevValue => Math.min(prevValue * 2, 1000 * 60 * 2))
        }
      }

      if (!hasError) {
        setup()
      }
    }, [hasError])

    useEffect(() => {
      if (!cartId) return

      const subscription = watchCart(CartType.Dayspa)

      return () => {
        subscription?.unsubscribe()
      }
    }, [cartId])

    useEffect(() => {
      let timeout: NodeJS.Timeout = null
      if (hasError && !isReady) {
        timeout = setTimeout(() => {
          setHasError(false)
        }, retryTimeMs)
      }

      return () => {
        if (timeout) {
          clearTimeout(timeout)
        }
      }
    }, [hasError, isReady])

    // Check if either Comfort or Premium has metadata
    const metadataForComfortOrPremium =
      getSpaComfortOrPremiumMetadata(spaFlowData)

    const alertBannersForComfortOrPremium =
      getSpaComfortOrPremiumAlertBanner(spaFlowData)

    if (!isReady || !router.isReady || hasError || !cart || !detectedCC) {
      return (
        <FlowWrapper>
          <Metadata metadata={metadataForComfortOrPremium} />
          {/* Temporary alert banner, until we find a better solution */}
          <InformationBanner banners={alertBannersForComfortOrPremium} />
          <SpinnerWrapper verticalCenter>
            <Spinner shouldAnimate />
          </SpinnerWrapper>
        </FlowWrapper>
      )
    }

    return (
      <FlowWrapper>
        <Metadata metadata={metadataForComfortOrPremium} />
        {/* Temporary alert banner, until we find a better solution */}
        <InformationBanner banners={alertBannersForComfortOrPremium} />
        <SpaFlowRenderer context={context} flowBasePath={flowBasePath} />
      </FlowWrapper>
    )
  }

  const BookingPageContainer = ({
    translations,
    spaFlowData,
  }: InferGetStaticPropsType<typeof getStaticProps>) => {
    const useMCPPayment = useFeatureFlagEnabledWrapper('[SPA]mcp-payment')
    return (
      <IntlProviderWrapper
        locale={locale}
        defaultLocale={locale}
        messages={translations}
      >
        <CurrencyProvider
          language={locale}
          ratesToUse={useMCPPayment ? 'mcpRates' : 'rates'}
        >
          <CartProvider
            cartIdKey="cart-id-spa-booking"
            cartType={CartType.Dayspa}
          >
            <BookingPage
              translations={translations}
              spaFlowData={spaFlowData}
              useMCPPayment={useMCPPayment}
            />
            {environment === 'development' && <CommandPaletteWrapper />}
          </CartProvider>
        </CurrencyProvider>
      </IntlProviderWrapper>
    )
  }

  const getStaticPaths: GetStaticPaths = () => {
    // Lets just fallback to blocking for now
    const paths = []

    return { paths, fallback: 'blocking' }
  }

  const getStaticProps = async () => {
    let spaFlowData: Entry<IBeSpaFlowFields>[] = []

    try {
      spaFlowData =
        (
          await getEntriesWrapper<IBeSpaFlowFields>({
            content_type: 'beSpaFlow',
            locale,
            include: 3,
            limit: 1000,
          })
        )?.items || []
    } catch (e) {
      sentryLogging({
        team: 'team-day-visit-svartsengi',
        error: new Error('Error fetching spa flow settings from contentful', e),
      })
    }
    const checkoutErrorTranslationLookup: Record<string, string> = {}

    try {
      const checkoutErrorTranslations = await getEntriesWrapper({
        content_type: 'dialog',
        locale,
        limit: 1000,
        'fields.projects[all]': 'Booking engine',
        'fields.key[match]': 'booking.errors.confirm',
      })

      checkoutErrorTranslations.items.forEach((item: any) => {
        const { key, title, message } = item.fields

        checkoutErrorTranslationLookup[`${key}.title`] = title
        checkoutErrorTranslationLookup[`${key}.description`] = message
      })
    } catch (e) {
      sentryLogging({
        team: 'team-day-visit-svartsengi',
        error: new Error('Error fetching checkout errors from contentful', e),
      })
    }

    let translations: Record<string, string> = {}

    try {
      const namespaces = ['spaBooking', 'sharedFlows']
      const hotelBookingTranslations = await getEntriesWrapper({
        content_type: 'localization',
        select: 'fields.strings',
        'fields.namespace[in]': namespaces.join(','),
        locale,
      })

      translations = hotelBookingTranslations.items.reduce(
        (acc, item) => {
          return {
            ...acc,
            ...get(item, 'fields.strings', {}),
          }
        },
        { ...checkoutErrorTranslationLookup }
      )
    } catch (e) {
      sentryLogging({
        team: 'team-day-visit-svartsengi',
        error: new Error('Error fetching translations from contentful', e),
      })
    }

    return {
      props: {
        translations,
        spaFlowData,
      },
      revalidate: environment === 'development' ? 1 : 30,
    }
  }

  return { Page: BookingPageContainer, getStaticProps, getStaticPaths }
}

const { Page, ...rest } = CreateFlexBookingPage('/book/spa', 'en')

export default Page
export const getStaticProps = rest.getStaticProps
export const getStaticPaths = rest.getStaticPaths
