import { Component } from 'react'
/* eslint-disable @typescript-eslint/unbound-method, jsx-a11y/role-supports-aria-props*/
import FocusLock from 'react-focus-lock'
import { Translation } from 'react-i18next'
import styled, { css } from 'styled-components'

import { colors } from '../constants/colors'
import { durations } from '../constants/durations'
import { zIndex } from '../constants/zIndex'
import { CurrencyContext } from '../context/Currency/CurrencyProvider'
import { Type } from '../elements/Typography/Typography'
import type { PartialBookingEngine } from '../styles/types'
import { media } from '../utils/media'

type CurrencySelectorProps = {
  id?: string
  minimal?: any
  positionRelativeTo?: {
    horizontal?: 'inside' | 'outside'
    vertical?: 'inside' | 'outside'
  }
  horizontalPosition?: 'left' | 'right' | 'center'
  verticalPosition?: 'top' | 'bottom' | 'center'
  offset?: { x?: number; y?: number }
  onChangeCurrency?(props?: any): any
  themeStyle?: PartialBookingEngine['cart'] | PartialBookingEngine
}

type CurrencySelectorState = {
  isOpen?: boolean
}

type DialogProps = {
  positionRelativeTo?: CurrencySelectorProps['positionRelativeTo']
  horizontalPosition?: CurrencySelectorProps['horizontalPosition']
  verticalPosition?: CurrencySelectorProps['verticalPosition']
  offset?: CurrencySelectorProps['offset']
  isLoading?: boolean
  isOpen?: boolean
}

type IsActiveProps = {
  isActive?: boolean
}

const DropdownSvg = ({ className = '' }: { className?: string }) => (
  <svg
    width="10"
    height="6"
    viewBox="0 0 10 6"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M9 1L5 5L1 1"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

const CloseSvg = ({ className = '' }: { className?: string }) => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path d="M13.3637 2.36392L11.9495 0.949707L6.9997 5.89945L2.04996 0.949707L0.635743 2.36392L5.58549 7.31367L0.635742 12.2634L2.04996 13.6776L6.9997 8.72788L11.9495 13.6776L13.3637 12.2634L8.41392 7.31367L13.3637 2.36392Z" />
  </svg>
)

const Close = styled.button`
  position: absolute;
  right: ${({ theme }) => theme.spacing[1]};
  top: ${({ theme }) => theme.spacing[1]};
  cursor: pointer;
`

const Selector = styled.button`
  color: ${colors.dark};
  cursor: pointer;
  display: flex;
  white-space: nowrap;

  ${media.md(css`
    margin-top: 0;
  `)};
`

const SelectorGroup = styled.span`
  color: var(--color-accent, ${colors.midGrey});
  display: flex;
  align-items: center;
  > svg {
    margin-left: ${({ theme }) => theme.spacing[0.5]};
  }
`

const SelectorLabel = styled(({ ...props }) => (
  <Type preset="labelSmall" {...props} />
))`
  margin-right: 8px;
  ${media.md(css`
    margin-right: ${({ theme }) => theme.spacing[0.5]};
  `)}
`

const Dialog = styled.ul<DialogProps>`
  gap: ${({ theme }) => theme.spacing[0.5]};
  background: var(--color-fillPrimary, ${colors.white});
  border-radius: var(--border-radius);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
  transition: opacity ${durations.medium}ms;
  padding: ${({ theme }) => theme.spacing[2]};
  padding-right: ${({ theme }) => theme.spacing[4]};
  position: absolute;
  z-index: ${zIndex.behindMenu};
  display: none;

  ${({ horizontalPosition, verticalPosition, offset, positionRelativeTo }) => {
    const xOffset = offset?.x ?? 0
    const yOffset = offset?.y ?? 0
    let horizontalTransform = ''
    let verticalTransform = ''

    // Horizontal position handling
    if (horizontalPosition === 'left') {
      horizontalTransform =
        positionRelativeTo?.horizontal === 'outside'
          ? `translateX(calc(-100% - ${xOffset}px))`
          : `translateX(${xOffset}px)`
    } else if (horizontalPosition === 'right') {
      horizontalTransform =
        positionRelativeTo?.horizontal === 'outside'
          ? `translateX(calc(100% + ${xOffset}px))`
          : `translateX(${xOffset}px)`
    } else if (horizontalPosition === 'center') {
      horizontalTransform = 'translateX(-50%)'
    }

    // Vertical position handling
    if (verticalPosition === 'top') {
      verticalTransform =
        positionRelativeTo?.vertical === 'outside'
          ? `translateY(calc(-100% - ${yOffset}px))`
          : `translateY(${yOffset}px)`
    } else if (verticalPosition === 'bottom') {
      verticalTransform =
        positionRelativeTo?.vertical === 'outside'
          ? `translateY(calc(100% + ${yOffset}px))`
          : `translateY(${yOffset}px)`
    } else if (verticalPosition === 'center') {
      verticalTransform = 'translateY(-50%)'
    }

    // Combine horizontal and vertical transforms
    const transform = `${horizontalTransform} ${verticalTransform}`.trim()

    return css`
      ${horizontalPosition === 'left' ? 'left: 0;' : ''}
      ${horizontalPosition === 'right' ? 'right: 0;' : ''}
      ${horizontalPosition === 'center' ? 'left: 50%;' : ''}
      ${verticalPosition === 'top' ? 'top: 0;' : ''}
      ${verticalPosition === 'bottom' ? 'bottom: 0;' : ''}
      ${verticalPosition === 'center' ? 'top: 50%;' : ''}
      transform: ${transform};
    `
  }}

  ${({ isLoading }) =>
    isLoading &&
    css`
      opacity: 0.5;
    `}

  ${({ isOpen }) =>
    isOpen &&
    css`
      display: grid;
    `};
`

const Name = styled.span<IsActiveProps>`
  flex: 2;
  white-space: nowrap;
  ${({ isActive }) =>
    isActive &&
    css`
      color: var(--color-accent);
    `};
`

const Item = styled.li`
  display: grid;
  grid-template-columns: 1fr 2fr;
  align-items: center;
  gap: ${({ theme }) => theme.spacing[1]};
  cursor: pointer;

  p {
    transition: none !important;
  }

  &:hover {
    ${Name} {
      color: var(--color-accent);
    }
  }
`

const StyledDropdownSvg = styled(DropdownSvg)({
  minWidth: 10,
  stroke: `var(--color-accent, ${colors.midGrey})`,
})

const StyledCloseSvg = styled(CloseSvg)({
  fill: `var(--color-accent, ${colors.midGrey})`,
})

const Abbreviation = styled.span<IsActiveProps>`
  flex: none;
  ${({ isActive }) =>
    isActive &&
    css`
      color: var(--color-accent);
    `};
`

const AVAILABLE_CURRENCIES = [
  {
    symbol: '€',
    ISO: 'EUR',
    name: 'euro',
  },
  {
    symbol: '$',
    ISO: 'USD',
    name: 'usDollar',
  },
  {
    symbol: '£',
    ISO: 'GBP',
    name: 'poundSterling',
  },
  {
    symbol: '',
    ISO: 'ISK',
    name: 'icelandicKrona',
  },
]

export class CurrencySelector extends Component<
  CurrencySelectorProps,
  CurrencySelectorState
> {
  constructor(props) {
    super(props)
    this.state = {
      isOpen: false,
    }
    this.handleKeyUp = this.handleKeyUp.bind(this)
    this.handleOpen = this.handleOpen.bind(this)
    this.handleClose = this.handleClose.bind(this)
  }

  handleOpen() {
    this.setState({
      isOpen: true,
    })
  }

  handleClose() {
    this.setState({
      isOpen: false,
    })
  }

  handleKeyUp(event, callback) {
    if (event.key === 'Enter') {
      callback()
    }
  }

  render() {
    const { isOpen } = this.state
    const {
      id = 'currency-selector',
      minimal,
      positionRelativeTo,
      verticalPosition,
      horizontalPosition,
      offset,
      onChangeCurrency,
    } = this.props

    return (
      <div>
        <CurrencyContext.Consumer>
          {({ currency, changeCurrency, isLoading }) => (
            <Translation>
              {t => (
                <>
                  <Selector
                    onClick={
                      this.state.isOpen ? this.handleClose : this.handleOpen
                    }
                    aria-expanded={isOpen}
                    aria-controls="currency-selector"
                    aria-label={t('currencySelectorAria')}
                    type="button"
                  >
                    {!minimal && <SelectorLabel>{t('currency')}</SelectorLabel>}

                    <Type preset="labelSmall" weight="bold">
                      {AVAILABLE_CURRENCIES.filter(
                        item => item.ISO === currency
                      ).map(item => (
                        <SelectorGroup key={item.ISO}>
                          <span style={{ paddingBottom: 1 }}>
                            {item.symbol} {item.ISO}
                          </span>

                          <StyledDropdownSvg />
                        </SelectorGroup>
                      ))}
                    </Type>
                  </Selector>
                  <FocusLock
                    disabled={!isOpen}
                    onDeactivation={this.handleClose}
                  >
                    <Dialog
                      id={id}
                      isLoading={isLoading}
                      isOpen={isOpen}
                      role="dialog"
                      aria-expanded={isOpen}
                      aria-hidden={!isOpen}
                      verticalPosition={verticalPosition}
                      horizontalPosition={horizontalPosition}
                      offset={offset}
                      positionRelativeTo={positionRelativeTo}
                    >
                      {AVAILABLE_CURRENCIES.map(item => {
                        const isActive = item.ISO === currency
                        const changeCurrencyAndClose = iso => {
                          changeCurrency(iso)
                          if (onChangeCurrency) {
                            onChangeCurrency(iso)
                          }
                          this.handleClose()
                        }
                        return (
                          <Item
                            tabIndex={0}
                            key={item.name}
                            onKeyDown={event =>
                              this.handleKeyUp(event, () => {
                                changeCurrencyAndClose(item.ISO)
                              })
                            }
                            onClick={() =>
                              isActive
                                ? this.handleClose()
                                : changeCurrencyAndClose(item.ISO)
                            }
                          >
                            <Abbreviation isActive={isActive}>
                              <Type preset="labelSmall" weight="bold">
                                {item.symbol} {item.ISO}
                              </Type>
                            </Abbreviation>
                            <Name isActive={isActive}>
                              <Type
                                preset="textSmall"
                                weight={isActive ? 'medium' : 'normal'}
                              >
                                <Translation>{t => t(item.name)}</Translation>
                              </Type>
                            </Name>
                          </Item>
                        )
                      })}
                      <Close
                        type="button"
                        onClick={this.handleClose}
                        aria-label={t('closeCurrency')}
                      >
                        <StyledCloseSvg />
                      </Close>
                    </Dialog>
                  </FocusLock>
                </>
              )}
            </Translation>
          )}
        </CurrencyContext.Consumer>
      </div>
    )
  }
}
