import { colors } from 'bl-common/src/constants/colors'
import { theme } from 'bl-common/src/styles/theme'
import {
  buildButton,
  buildCheckboxInputField,
  buildConfirmationSummaryField,
  buildCustomField,
  buildDisclaimerField,
  buildFlow,
  buildHeading,
  buildInputText,
  buildOverviewField,
  buildProgressButton,
  buildScreenErrorField,
  buildScreenWithImageLayout,
  buildSelectField,
  type BuildSubFlow,
  buildText,
  buildTextAreaField,
  type FlowImageLayout,
  type SubFlowBookingInfo,
} from 'bl-flows-core'
import type { AtLeastOne } from 'bl-flows-core/src/types/typeUtils'
import type { ActivityProductAvailabilityQuery } from 'bl-graphql'
import { formatDateInUTC } from 'bl-utils/src/formatting/formatDate'
import { formatHtmlText } from 'bl-utils/src/formatting/formatHtmlText'

import { PrivateTransferCards } from '../../components/PrivateTransferCards'
import { PrivateTransferServioCards } from '../../components/PrivateTransferServioCards'
import { globalBookingMessages } from '../../messages'
import { getItemsForPrivateTransferOverview } from '../../utils'
import { isBookingTooClose } from '../../utils/isBookingTooClose'
import { defaultRequiredValidator } from '../../utils/validation'
import { privateTransferMessages } from './messages/privateTransferMessages'

const SEVEN_DAYS = 24 * 7

const imageLayoutProps: FlowImageLayout['props'] = {
  layoutId: 'private-transfer-image-layout',
  layoutImageSrc:
    'https://images.ctfassets.net/w65k7w0nsb8q/qu5VqlhlLMNYyHnVSrVE7/68c79a8bb3095eb7a8c63604350d2785/DJI_0878.jpg?w=1600',
}

type SelectProps = AtLeastOne<{
  label: string
  value: string
}>

interface CartItem {
  title: string
}

export interface PrivateTransferFlowInitialData {
  bookingInfo?: SubFlowBookingInfo
  transferAvailability?: ActivityProductAvailabilityQuery['activityProductAvailability']
  isHBE?: boolean
}

function subtractHours(date: Date, hours: number) {
  date.setUTCHours(date.getUTCHours() - hours)

  return date
}

export const buildPrivateTransferFlow: BuildSubFlow<CartItem> = (
  onAddToCart,
  onClose,
  initialData: PrivateTransferFlowInitialData
) =>
  buildFlow({
    id: 'privateTransferFlow',
    routerSettings: {
      updateHistory: false,
    },
    settings: {
      resetWhenOpened: true,
    },
    setupHook: () => {
      return {
        bookingInfo: initialData?.bookingInfo,
        isHBE: initialData?.isHBE,
      }
    },
    children: [
      buildScreenWithImageLayout({
        id: 'type',
        subType: 'form',
        layoutProps: imageLayoutProps,
        breadcrumb: control => {
          return {
            title: control.context?.t(
              privateTransferMessages.info.typeBreadcrumb
            ),
          }
        },
        fields: {
          main: [
            ...buildHeading({
              title: control =>
                control.context?.t(privateTransferMessages.info.typeTitle),
              subTitle: control =>
                control.context?.t(privateTransferMessages.info.typeSubtitle),
              includeBreadcrumb: true,
              imageSrc: imageLayoutProps.layoutImageSrc,
              imageLayout: { display: { bmd: 'none' } },
            }),
            buildDisclaimerField({
              condition: () =>
                isBookingTooClose(initialData.bookingInfo, SEVEN_DAYS),
              props: {
                value: control =>
                  control.context.t(
                    globalBookingMessages.warnings.bookingTooCloseToAllowUpgrade
                  ),
                color: colors.deepRed,
              },
            }),
            buildCustomField({
              condition: () =>
                !isBookingTooClose(initialData.bookingInfo, SEVEN_DAYS),
              defaultValue: null,
              props: {
                component: props =>
                  initialData.bookingInfo.property?.toLowerCase() ===
                  'silica' ? (
                    <PrivateTransferCards
                      {...props}
                      transferAvailability={initialData?.transferAvailability}
                    />
                  ) : (
                    <PrivateTransferServioCards
                      {...props}
                      transferAvailability={initialData?.transferAvailability}
                    />
                  ),
              },
            }),
            buildButton({
              props: {
                label: control =>
                  control.context.t(globalBookingMessages.buttons.continue),
                onClick: control => {
                  control.nextScreen()
                },
                isDisabled: control =>
                  (!control.screen.state?.selectedArrival &&
                    !control.screen.state?.selectedDeparture) ||
                  isBookingTooClose(initialData.bookingInfo, SEVEN_DAYS),
              },
              layout: {
                width: { xs: `calc(100% + ${theme.spacing[2]})` },
                spacing: {
                  ml: { xs: -1 },
                  mr: { xs: -1 },
                },
                position: { xs: 'sticky' },
                bottom: { xs: 16 },
                zIndex: { xs: 2 },
              },
            }),
          ],
        },
      }),
      buildScreenWithImageLayout({
        id: 'arrival',
        subType: 'form',
        layoutProps: imageLayoutProps,
        breadcrumb: control => {
          return {
            title: control.context.t(
              privateTransferMessages.info.arrivalBreadcrumb
            ),
          }
        },
        condition: control =>
          !!control.flow.stateRef?.current?.type?.selectedArrival,
        fields: {
          main: [
            ...buildHeading({
              title: control =>
                control.context?.t(privateTransferMessages.info.arrivalTitle),
              subTitle: control =>
                control.context.t(
                  privateTransferMessages.info.arrivalSubtitle,
                  {
                    property: initialData?.bookingInfo?.entryDate
                      ? control.context.t(
                          globalBookingMessages.labels.BlueLagoon
                        )
                      : control.context.t(
                          globalBookingMessages.labels?.[
                            initialData?.bookingInfo?.property.toUpperCase()
                          ]
                        ),
                    arrivalDate: formatDateInUTC(
                      new Date(
                        initialData?.bookingInfo?.entryDate ??
                          initialData?.bookingInfo?.hotelDates?.arrivalDate
                      ),
                      'dd MMM'
                    ),
                  }
                ),
              includeBreadcrumb: true,
            }),
            buildSelectField({
              id: 'estimatedArrival',
              props: {
                label: control =>
                  control.context.t(
                    privateTransferMessages.info.estimatedArrivalFlightTimeLabel
                  ),
                required: true,
                options: control => {
                  return initialData?.transferAvailability
                    ?.filter(
                      item =>
                        item.productId ===
                          control.flow.stateRef?.current?.type
                            ?.selectedArrival &&
                        formatDateInUTC(item.time) ===
                          formatDateInUTC(
                            initialData?.bookingInfo?.entryDate ??
                              initialData?.bookingInfo?.hotelDates?.arrivalDate
                          )
                    )
                    .sort((a, b) => {
                      return a.time < b.time ? -1 : a.time > b.time ? 1 : 0
                    })
                    .map(option => {
                      return {
                        label: formatDateInUTC(option.time, 'HH:mm'),
                        value: option.time,
                      }
                    }) as SelectProps
                },
              },
            }),
            buildInputText({
              id: 'flightNumber',
              props: {
                label: control =>
                  control.context.t(privateTransferMessages.info.flightNrLabel),
                placeholder: control =>
                  control.context.t(
                    privateTransferMessages.info.flightNrPlaceholder
                  ),
                maxLength: 6,
                required: true,
              },
              layout: {
                spacing: {
                  mt: { xs: 2 },
                },
              },
            }),

            buildTextAreaField({
              id: 'comment',
              props: {
                label: control =>
                  control.context.t(privateTransferMessages.info.commentLabel),
                placeholder: control =>
                  control.context.t(
                    privateTransferMessages.info.commentPlaceholder
                  ),
                rows: 2,
                // MaxLength is higher in Arrival than in Departure because Arrival doesn't add estimated flight time to the commment like Departure does
                maxLength: 70,
              },
              layout: {
                spacing: {
                  mt: { xs: 2 },
                },
              },
            }),
            buildText({
              props: {
                type: 'paragraph',
                value: control =>
                  control.context.t(
                    privateTransferMessages.info.characterCount,
                    { count: control.screen.state?.comment?.length ?? 0 }
                  ),
              },
              layout: {
                spacing: { mt: { xs: 0.5 } },
                textAlign: { xs: 'right' },
              },
            }),
            buildButton({
              props: {
                label: control =>
                  control.context.t(globalBookingMessages.buttons.continue),
                onClick: control => {
                  const transferInfo = initialData?.transferAvailability?.find(
                    item =>
                      item.time === control.screen.state?.estimatedArrival &&
                      item.productId === control.flow.state.type.selectedArrival
                  )
                  control.screen.setState({
                    transferInfo,
                  })
                  control.nextScreen()
                },
              },
              layout: {
                width: { xs: `calc(100% + ${theme.spacing[2]})` },
                spacing: {
                  mt: { xs: 2 },
                  ml: { xs: -1 },
                  mr: { xs: -1 },
                },
              },
            }),
          ],
        },
      }),
      buildScreenWithImageLayout({
        id: 'departure',
        subType: 'form',
        layoutProps: imageLayoutProps,
        breadcrumb: control => {
          return {
            title: control.context.t(
              privateTransferMessages.info.departureBreadcrumb
            ),
          }
        },
        condition: control =>
          !!control.flow.stateRef?.current?.type?.selectedDeparture,
        fields: {
          main: [
            ...buildHeading({
              title: control =>
                control.context?.t(privateTransferMessages.info.departureTitle),
              subTitle: control =>
                control.context.t(
                  privateTransferMessages.info.departureSubtitle,
                  {
                    property: initialData?.bookingInfo?.entryDate
                      ? control.context.t(
                          globalBookingMessages.labels.BlueLagoon
                        )
                      : control.context.t(
                          globalBookingMessages.labels?.[
                            initialData?.bookingInfo?.property.toUpperCase()
                          ]
                        ),
                    departureDate: formatDateInUTC(
                      new Date(
                        initialData?.bookingInfo?.entryDate ??
                          initialData?.bookingInfo?.hotelDates?.departureDate
                      ),
                      'dd MMM'
                    ),
                  }
                ),
              includeBreadcrumb: true,
            }),
            buildDisclaimerField({
              props: {
                value: control =>
                  control.context.t(
                    privateTransferMessages.info.departureDisclaimer
                  ),
              },
              layout: {
                spacing: {
                  mb: {
                    xs: 1,
                  },
                  mt: {
                    md: -1,
                  },
                },
              },
            }),
            buildSelectField({
              id: 'estimatedDeparture',
              props: {
                label: control =>
                  control.context.t(
                    privateTransferMessages.info
                      .estimatedDepartureFlightTimeLabel
                  ),
                required: true,
                options: control => {
                  return initialData?.transferAvailability
                    ?.filter(
                      item =>
                        item.productId ===
                          control.flow.stateRef?.current?.type
                            ?.selectedDeparture &&
                        formatDateInUTC(item.time) ===
                          formatDateInUTC(
                            initialData?.bookingInfo?.entryDate ??
                              initialData?.bookingInfo?.hotelDates
                                ?.departureDate
                          )
                    )
                    .sort((a, b) => {
                      return a.time < b.time ? -1 : a.time > b.time ? 1 : 0
                    })
                    .map(option => {
                      return {
                        label: formatDateInUTC(option.time, 'HH:mm'),
                        value: option.time,
                      }
                    }) as SelectProps
                },
              },
            }),
            buildSelectField({
              id: 'pickupTime',
              props: {
                label: control =>
                  control.context.t(privateTransferMessages.info.pickupLabel),
                required: true,
                options: control => {
                  return initialData?.transferAvailability
                    ?.filter(
                      item =>
                        item.productId ===
                          control.flow.stateRef?.current?.type
                            ?.selectedDeparture &&
                        new Date(item.time) <
                          subtractHours(
                            new Date(
                              control.screen?.stateRef?.current
                                ?.estimatedDeparture
                            ),
                            2
                          ) &&
                        formatDateInUTC(item.time) ===
                          formatDateInUTC(
                            initialData?.bookingInfo?.entryDate ??
                              initialData?.bookingInfo?.hotelDates
                                ?.departureDate
                          )
                    )
                    .sort((a, b) => {
                      return a.time < b.time ? -1 : a.time > b.time ? 1 : 0
                    })
                    .map(option => {
                      return {
                        label: formatDateInUTC(option.time, 'HH:mm'),
                        value: option.time,
                      }
                    }) as SelectProps
                },
              },
              layout: {
                spacing: {
                  mt: { xs: 1.5 },
                },
              },
            }),
            buildInputText({
              id: 'flightNumber',
              props: {
                label: control =>
                  control.context.t(privateTransferMessages.info.flightNrLabel),
                placeholder: control =>
                  control.context.t(
                    privateTransferMessages.info.flightNrPlaceholder
                  ),
                maxLength: 6,
                required: true,
              },
              layout: {
                spacing: {
                  mt: { xs: 1.5 },
                },
              },
            }),
            buildTextAreaField({
              id: 'comment',
              props: {
                label: control =>
                  control.context.t(privateTransferMessages.info.commentLabel),
                placeholder: control =>
                  control.context.t(
                    privateTransferMessages.info.commentPlaceholder
                  ),
                rows: 2,
                maxLength: 55,
              },
              layout: {
                spacing: {
                  mt: { xs: 1.5 },
                },
              },
            }),
            buildText({
              props: {
                type: 'paragraph',
                value: control =>
                  control.context.t(
                    privateTransferMessages.info.characterCount,
                    { count: control.screen.state?.comment?.length ?? 0 }
                  ),
              },
              layout: {
                spacing: { mt: { xs: 0.5 } },
                textAlign: { xs: 'right' },
              },
            }),
            buildButton({
              props: {
                label: control =>
                  control.context.t(globalBookingMessages.buttons.continue),
                onClick: control => {
                  const transferInfo = initialData?.transferAvailability.find(
                    item =>
                      item.time === control.screen.state?.pickupTime &&
                      item.productId ===
                        control.flow.state.type.selectedDeparture
                  )
                  control.screen.setState({
                    transferInfo,
                  })
                  control.nextScreen()
                },
              },
              layout: {
                width: { xs: `calc(100% + ${theme.spacing[2]})` },
                spacing: {
                  mt: { xs: 2 },
                  ml: { xs: -1 },
                  mr: { xs: -1 },
                },
              },
            }),
          ],
        },
      }),
      buildScreenWithImageLayout({
        id: 'overview',
        subType: 'form',
        columnStyle: true,
        breadcrumb: control => {
          return {
            title: control.context.t(
              globalBookingMessages.breadcrumbs.overview
            ),
          }
        },
        layoutProps: imageLayoutProps,
        fields: {
          main: [
            ...buildHeading({
              title: control =>
                control.context.t(globalBookingMessages.text.overviewTitle),
              includeBreadcrumb: true,
            }),
            buildOverviewField({
              defaultValue: null,
              props: {
                items: control => getItemsForPrivateTransferOverview(control),
                isHotelBooking: !!initialData?.bookingInfo?.hotelDates,
                isEditing: control => control.flow.setupHook?.isEditing,
                totalPrice: control =>
                  ((control.flow.state?.type?.selectedArrival &&
                    (control.flow.state?.arrival?.transferInfo
                      ?.price as number)) ??
                    0) +
                  ((control.flow.state?.type?.selectedDeparture &&
                    (control.flow.state?.departure?.transferInfo
                      ?.price as number)) ??
                    0),
              },
            }),
            buildCheckboxInputField({
              id: 'agreeToTerms',
              condition: () => !initialData?.isHBE,
              props: {
                label: control =>
                  formatHtmlText(
                    control.context.t(
                      globalBookingMessages.labels.agreeToTermsAndPolicies
                    )
                  ),
                required: true,
              },
              layout: {
                marginTopAuto: { xs: true },
                spacing: {
                  mb: { xs: 1 },
                },
              },
              validation: {
                renderErrorInFieldRenderer: false,
                validator: !initialData?.isHBE && defaultRequiredValidator,
              },
            }),
            buildProgressButton({
              props: {
                label: control =>
                  control.context.t(
                    privateTransferMessages.info.overviewConfirmButton
                  ),
                isDisabled: control => control.screen.state.isAddingToCart,
                onClick: async control => {
                  const { hasErrors } = control.validateAndSetScreenErrors()

                  if (hasErrors) {
                    return false
                  }

                  control.screen.setState({
                    isAddingToCart: true,
                  })

                  const success = await onAddToCart(
                    {
                      title: 'Private transfer reservation',
                    },
                    control
                  )

                  control.screen.setState({
                    isAddingToCart: false,
                  })

                  if (!success) {
                    control.screenErrors.setState({
                      error: control.context.t(
                        privateTransferMessages.errors.bookingFailedError
                      ),
                    })
                    return false
                  }

                  return true
                },
                onComplete: control => {
                  if (initialData?.isHBE) {
                    onClose(control)
                  } else {
                    control.nextScreen()
                  }
                },
              },
              layout: {
                ...(initialData?.isHBE && {
                  marginTopAuto: { xs: true },
                  width: { xs: `calc(100% + ${theme.spacing[2]})` },
                  spacing: {
                    ml: { xs: -1 },
                    mr: { xs: -1 },
                  },
                }),
              },
            }),
            buildText({
              condition: () => !initialData.isHBE,
              props: {
                type: 'text',
                preset: 'textSmall',
                value: control =>
                  control.context.t(
                    globalBookingMessages.labels.requiredAsterikExplanation
                  ),
              },
              layout: {
                spacing: {
                  mt: { xs: 0.5 },
                },
              },
            }),
            buildScreenErrorField({
              condition: control => !!control.screenErrors.state?.error,
              props: {
                fieldIdsToRender: ['error'],
              },
              layout: {
                spacing: {
                  mt: { xs: 1 },
                },
              },
            }),
          ],
        },
      }),
      buildScreenWithImageLayout({
        id: 'confirmation',
        subType: 'form',
        layoutProps: imageLayoutProps,
        breadcrumb: false,
        columnStyle: true,
        theme: 'blue',
        fields: {
          main: [
            ...buildHeading({
              title: control => {
                return initialData?.isHBE
                  ? control.context.t(
                      privateTransferMessages.info.confirmationTitleHBE
                    )
                  : control.context.t(
                      privateTransferMessages.info.confirmationTitleMYB
                    )
              },
            }),
            buildConfirmationSummaryField({
              id: 'confirmationSummary',
              defaultValue: null,
              props: {
                type: 'privateTransfer',
                privateTransfer: control => {
                  const { selectedArrival, selectedDeparture } =
                    control.flow.state?.type || {}

                  const arrival = selectedArrival
                    ? {
                        productId: selectedArrival,
                        time: control.flow.state?.arrival?.estimatedArrival,
                      }
                    : null

                  const departure = selectedDeparture
                    ? {
                        productId: selectedDeparture,
                        time: control.flow.state?.departure?.pickupTime,
                      }
                    : null

                  return {
                    arrival,
                    departure,
                  }
                },
              },
            }),
            buildButton({
              props: {
                label: control =>
                  control.context.t(globalBookingMessages.buttons.closeWindow),
                onClick: control => onClose(control),
              },
              layout: {
                marginTopAuto: { xs: true },
                width: { xs: `calc(100% + ${theme.spacing[2]})` },
                spacing: {
                  ml: { xs: -1 },
                  mr: { xs: -1 },
                },
              },
            }),
          ],
        },
      }),
    ],
  })
