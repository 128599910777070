import { useContext, useEffect, useState } from 'react'
import { useTheme } from 'styled-components'

import { CurrencyContext } from 'bl-common/src/context/Currency/CurrencyProvider'
import { FlowCart } from 'bl-common/src/flows/FlowCart/FlowCart'
import { useAmount } from 'bl-common/src/hooks/useAmount'
import { useCartContext } from 'bl-common/src/hooks/useCartContext'
import type { FlowComponent, FlowValue } from 'bl-flows-core'
import { getFlowValue } from 'bl-flows-core/src/utils'
import { CartItemType, CartType } from 'bl-graphql'
import { triggerEvent } from 'bl-utils/src/analytics/events'
import { calcPrice } from 'bl-utils/src/currency/calcPrice'
import { formatDateInUTC } from 'bl-utils/src/formatting/formatDate'
import { HB_PRODUCT_IDS, PRODUCT_IDS } from 'bl-utils/src/ProductIds'
import {
  KEFLAVIK_AIRPORT_ID,
  REYKJAVIK_TERMINAL_ID,
} from 'bl-utils/src/productUtils'

import { orderRooms } from '../flows/hotel/utils/ordered-rooms'
import { orderAdmissions } from '../flows/spa/utils/ordered-admissions'
import { globalBookingMessages } from '../messages'
import { cartMessages } from '../messages/cart'
import { formatCartItem } from '../utils'
import {
  getHotelBookingItems,
  getSpaBookingItems,
} from '../utils/get-analytics-items'
import { busStops } from './LocationPicker/busStops'

interface CartProps {
  title: FlowValue<string>
  subtitle: FlowValue<string>
  disclaimer: FlowValue<string>
  displayFractionalPrices?: boolean
  hideCurrencyChange?: boolean
}

export const Cart: React.FC<FlowComponent & CartProps> = ({
  control,
  title,
  subtitle,
  screenTheme,
  disclaimer,
  displayFractionalPrices,
  hideCurrencyChange,
}) => {
  const {
    cart,
    cartType,
    loading: isCartLoading,
    cartLastUpdated,
    removeItemFromCart,
  } = useCartContext()

  const { exchangeRates } = useContext(CurrencyContext)
  const [hasViewCartTriggered, setHasViewCartTriggered] = useState(false)

  const [isDeletingItem, setIsDeletingItem] = useState(false)
  const theme = useTheme()
  const themeStyle = theme?.bookingEngine?.[screenTheme]?.cart

  const orderedCartItems =
    cartType === CartType.Hotel ? orderRooms(cart) : orderAdmissions(cart)
  const analyticsItems =
    cartType === CartType.Hotel
      ? getHotelBookingItems(
          orderedCartItems,
          exchangeRates,
          control.flow.state
        )
      : getSpaBookingItems(
          orderedCartItems,
          exchangeRates,
          cart?.promoCode ?? ''
        )

  useEffect(() => {
    setHasViewCartTriggered(false)
  }, [cartLastUpdated])

  useEffect(() => {
    if (
      !hasViewCartTriggered &&
      analyticsItems?.[0]?.item_id &&
      !isCartLoading
    ) {
      setHasViewCartTriggered(true)
      triggerEvent({
        event: 'view_cart',
        ecommerce: {
          currency: 'EUR',
          value: calcPrice(cart?.paymentAmount, exchangeRates?.EUR),
          items: analyticsItems,
        },
      })
    }
  }, [analyticsItems, hasViewCartTriggered, isCartLoading])

  const totalCartAmount = useAmount({
    value: cart?.paymentAmount ?? 0,
    displayFractionalPrices,
  })

  const totalGiftCardsAmount = (cart?.giftCards || []).reduce(
    (a, b) => a + b.chargeAmount,
    0
  )

  const giftCardsAmountFormatted = useAmount({
    value: totalGiftCardsAmount,
    displayFractionalPrices,
  })

  const giftCardsText =
    totalGiftCardsAmount > 0
      ? `${control.context.t(
          globalBookingMessages.labels.giftCard
        )} ${giftCardsAmountFormatted}`
      : undefined

  const activityMessages =
    cartType === CartType.Hotel
      ? {
          [PRODUCT_IDS.Massage30]: {
            title: control.context.t(cartMessages.info.massageCartTitle),
            description: control.context.t(
              cartMessages.info.massage30CartSubtitle
            ),
            guests: control.context.t(cartMessages.info.cartGuests),
          },
          [PRODUCT_IDS.Massage60]: {
            title: control.context.t(cartMessages.info.massageCartTitle),
            description: control.context.t(
              cartMessages.info.massage60CartSubtitle
            ),
            guests: control.context.t(cartMessages.info.cartGuests),
          },
          [PRODUCT_IDS.Massage120]: {
            title: control.context.t(
              cartMessages.info.signatureMassageCartTitle
            ),
            description: control.context.t(
              cartMessages.info.massage120CartSubtitle
            ),
            guests: control.context.t(cartMessages.info.cartGuests),
          },
          [PRODUCT_IDS.MassageFloatingOne]: {
            title: control.context.t(cartMessages.info.singleFloatCartTitle),
          },
          [PRODUCT_IDS.MassageFloatingTwo]: {
            title: control.context.t(cartMessages.info.couplesFloatCartTitle),
            guests: control.context.t(cartMessages.info.cartGuests),
          },
          [PRODUCT_IDS.MassageFloatingGroup]: {
            title: control.context.t(cartMessages.info.groupFloatCartTitle),
            guests: control.context.t(cartMessages.info.cartGuests),
          },
          [PRODUCT_IDS.RestaurantLava]: {
            title: control.context.t(cartMessages.info.lavaCartTitle),
          },
          [PRODUCT_IDS.SpaRestaurant]: {
            title: control.context.t(cartMessages.info.spaRestaurantCartTitle),
          },
          [HB_PRODUCT_IDS.HighlandBaseRestaurant]: {
            title: control.context.t(
              cartMessages.info.highlandBaseRestaurantCartTitle
            ),
          },
          [PRODUCT_IDS.PrivateTransferArrivalSmall]: {
            title: control.context.t(
              cartMessages.info.privateTransferSmallTitle
            ),
          },
          [PRODUCT_IDS.PrivateTransferArrivalLarge]: {
            title: control.context.t(
              cartMessages.info.privateTransferLargeTitle
            ),
          },
          [PRODUCT_IDS.PrivateTransferDepartureSmall]: {
            title: control.context.t(
              cartMessages.info.privateTransferSmallTitle
            ),
          },
          [PRODUCT_IDS.PrivateTransferDepartureLarge]: {
            title: control.context.t(
              cartMessages.info.privateTransferLargeTitle
            ),
          },
          [PRODUCT_IDS.PrivateTransferArrivalServioSmall]: {
            title: control.context.t(
              cartMessages.info.privateTransferServioSmallTitle
            ),
          },
          [PRODUCT_IDS.PrivateTransferArrivalServioMedium]: {
            title: control.context.t(
              cartMessages.info.privateTransferServioMediumTitle
            ),
          },
          [PRODUCT_IDS.PrivateTransferArrivalServioLarge]: {
            title: control.context.t(
              cartMessages.info.privateTransferServioLargeTitle
            ),
          },
          [PRODUCT_IDS.PrivateTransferDepartureServioSmall]: {
            title: control.context.t(
              cartMessages.info.privateTransferServioSmallTitle
            ),
          },
          [PRODUCT_IDS.PrivateTransferDepartureServioMedium]: {
            title: control.context.t(
              cartMessages.info.privateTransferServioMediumTitle
            ),
          },
          [PRODUCT_IDS.PrivateTransferDepartureServioLarge]: {
            title: control.context.t(
              cartMessages.info.privateTransferServioLargeTitle
            ),
          },
          [HB_PRODUCT_IDS.TransferRvkHb]: {
            title: control.context.t(
              cartMessages.info.transportationReykjavikToHighlandBase
            ),
          },
          [HB_PRODUCT_IDS.TransferHbRvk]: {
            title: control.context.t(
              cartMessages.info.transportationHighlandBaseToReykjavik
            ),
          },
          [HB_PRODUCT_IDS.TransferSkjolHb]: {
            title: control.context.t(
              cartMessages.info.transportationSkjolToHighlandBase
            ),
          },
          [HB_PRODUCT_IDS.TransferHbSkjol]: {
            title: control.context.t(
              cartMessages.info.transportationHighlandBaseToSkjol
            ),
          },
          [HB_PRODUCT_IDS.BuggySingleRide]: {
            title: control.context.t(cartMessages.info.buggySingleRide),
          },
          [HB_PRODUCT_IDS.BuggyDoubleRide]: {
            title: control.context.t(cartMessages.info.buggyDoubleRide),
          },
          [PRODUCT_IDS.RestaurantMoss]: {
            title: control.context.t(cartMessages.info.mossCartTitle),
          },
        }
      : {
          [PRODUCT_IDS.Massage30]: {
            title: control.context.t(cartMessages.info.massageCartTitle),
            description: control.context.t(
              cartMessages.info.massage30CartSubtitle
            ),
            guests: control.context.t(cartMessages.info.cartGuests),
          },
          [PRODUCT_IDS.Massage60]: {
            title: control.context.t(cartMessages.info.massageCartTitle),
            description: control.context.t(
              cartMessages.info.massage60CartSubtitle
            ),
            guests: control.context.t(cartMessages.info.cartGuests),
          },
          [PRODUCT_IDS.Massage120]: {
            title: control.context.t(
              cartMessages.info.signatureMassageCartTitle
            ),
            description: control.context.t(
              cartMessages.info.massage120CartSubtitle
            ),
            guests: control.context.t(cartMessages.info.cartGuests),
          },
          [PRODUCT_IDS.MassageFloatingOne]: {
            title: control.context.t(cartMessages.info.singleFloatCartTitle),
          },
          [PRODUCT_IDS.MassageFloatingTwo]: {
            title: control.context.t(cartMessages.info.couplesFloatCartTitle),
            description: control.context.t(
              cartMessages.info.couplesFloatCartSubtitle
            ),
            guests: control.context.t(cartMessages.info.cartGuests),
          },
          [PRODUCT_IDS.MassageFloatingGroup]: {
            title: control.context.t(cartMessages.info.groupFloatCartTitle),
            guests: control.context.t(cartMessages.info.cartGuests),
          },
          [PRODUCT_IDS.RestaurantLava]: {
            title: control.context.t(cartMessages.info.lavaCartTitle),
          },
          [PRODUCT_IDS.SpaRestaurant]: {
            title: control.context.t(cartMessages.info.spaRestaurantCartTitle),
          },
          [PRODUCT_IDS.PrivateTransferArrivalSmall]: {
            title: control.context.t(
              cartMessages.info.privateTransferSmallTitle
            ),
          },
          [PRODUCT_IDS.PrivateTransferArrivalLarge]: {
            title: control.context.t(
              cartMessages.info.privateTransferLargeTitle
            ),
          },
          [PRODUCT_IDS.PrivateTransferDepartureSmall]: {
            title: control.context.t(
              cartMessages.info.privateTransferSmallTitle
            ),
          },
          [PRODUCT_IDS.PrivateTransferDepartureLarge]: {
            title: control.context.t(
              cartMessages.info.privateTransferLargeTitle
            ),
          },
          [PRODUCT_IDS.TransferTwoWay]: {
            title: control.context.t(cartMessages.info.transportationTitle),
          },

          [HB_PRODUCT_IDS.BuggySingleRide]: {
            title: control.context.t(cartMessages.info.buggySingleRide),
          },
          [HB_PRODUCT_IDS.BuggyDoubleRide]: {
            title: control.context.t(cartMessages.info.buggyDoubleRide),
          },
        }

  const orderedCartItemsWithMessages = orderedCartItems.map(item => {
    const updatedItem = {
      ...item,
      ...(activityMessages[item.productId] && {
        messages: activityMessages[item.productId],
      }),
    }

    if (item?.type !== CartItemType.Transfer) {
      return updatedItem
    }

    // Get pickup and dropoff location names for bus transfers
    const isPickup = item?.meta?.direction === 'pickup'
    const locationId = isPickup
      ? item?.meta?.inboundPickupLocationId
      : item?.meta?.outboundDropoffLocationId
    const locationName = isPickup
      ? control.flow.state?.extrasTransportation?.pickupLocationName
      : control.flow.state?.extrasTransportation?.dropoffLocationName

    const isTerminalOrAirport =
      locationId === REYKJAVIK_TERMINAL_ID || locationId === KEFLAVIK_AIRPORT_ID

    // Find the bus stop location if there is one
    const busStop = Object.values(busStops).find(
      busStop => busStop.id === locationId
    )

    return {
      ...updatedItem,
      ...(isPickup
        ? { pickupLocationName: busStop?.name || locationName }
        : { dropoffLocationName: busStop?.name || locationName }),
      ...(!isTerminalOrAirport &&
        isPickup && {
          disclaimer: control.context.t(
            cartMessages.disclaimers.busPickupDisclaimer
          ),
        }),
    }
  })

  const items =
    orderedCartItemsWithMessages.map((item, i) => ({
      key:
        // both transportation items have the same id, which is causing console errors,
        // adding inbound or outbound ID to the key fixes that
        item.productId === PRODUCT_IDS.TransferTwoWay
          ? `${item.id}-${
              item.meta?.inboundComponentId || item.meta?.outboundComponentId
            }`
          : item.id,
      ...formatCartItem(item, cartType, control.context.t),
      editing: false,
      time:
        cartType === CartType.Dayspa && item.meta?.arrivalTime
          ? formatDateInUTC(item.meta?.arrivalTime, 'HH:mm')
          : undefined,
      canDelete:
        item.type === CartItemType.Item ||
        item.type === CartItemType.Massage ||
        item.type === CartItemType.PrivateTransfer ||
        item.type === CartItemType.Transfer ||
        item.type === CartItemType.Activity ||
        item.type === CartItemType.Restaurant,
      onDelete:
        item.type === CartItemType.Item ||
        item.type === CartItemType.Massage ||
        item.type === CartItemType.PrivateTransfer ||
        item.type === CartItemType.Transfer ||
        item.type === CartItemType.Activity ||
        item.type === CartItemType.Restaurant
          ? async () => {
              // TODO implement delete hotel room item
              setIsDeletingItem(true)
              let itemsToRemove = [item]

              const allPrivateTransfers = orderedCartItems.filter(
                item => item.type === CartItemType.PrivateTransfer
              )

              if (
                item.type === CartItemType.PrivateTransfer &&
                allPrivateTransfers.length === 1
              ) {
                control?.flow?.setState({
                  activities: { privateTransfer: false },
                })
              }

              // Need to check if we have an item with qty > 1, then we need to remove all the items from the cart that are grouped together
              if (
                item.qty &&
                item.qty > 1 &&
                //  bus transfer works differently to other items, so it doesn't pass that filter
                //  because it doesn't have arrivalTime and doesn't get deleted
                item.productId !== PRODUCT_IDS.TransferTwoWay
              ) {
                itemsToRemove = cart.items.filter(
                  it =>
                    it.type === item.type &&
                    it.productId === item.productId &&
                    (('arrivalTime' in it.meta &&
                      it.meta.arrivalTime === item.meta.arrivalTime) ||
                      it.type === CartItemType.Item)
                )
              }

              // We need to remove the Moss 7 course menu if we are removing the Moss restaurant reservation
              if (item.productId === PRODUCT_IDS.RestaurantMoss) {
                const moss7CourseMenu = cart.items.find(
                  it =>
                    it.type === CartItemType.Item &&
                    it.productId === PRODUCT_IDS.Moss7CourseMenu
                )
                // Push based on the item qty
                if (moss7CourseMenu) {
                  itemsToRemove.push(moss7CourseMenu)
                }
              }

              const analyticsItem = analyticsItems?.[i]

              try {
                for (let x = 0; x < itemsToRemove.length; x++) {
                  const isLastItem = x === itemsToRemove.length - 1
                  await removeItemFromCart(itemsToRemove[x].id, !isLastItem)
                  triggerEvent({
                    event: 'remove_from_cart',
                    ecommerce: {
                      currency: 'EUR',
                      value: calcPrice(
                        item?.linePrice ?? item?.price,
                        control.flow.setupHook?.exchangeRates?.EUR
                      ),
                      items: analyticsItems ? [analyticsItem] : [],
                    },
                  })
                }
              } catch (err) {
                console.error(err)
              }

              setIsDeletingItem(false)
            }
          : undefined,
    })) ?? []

  return (
    <FlowCart
      items={items}
      totalText={totalCartAmount}
      giftCardsText={giftCardsText}
      iskDisclaimer={getFlowValue(disclaimer, control)}
      title={getFlowValue(title, control)}
      subTitle={getFlowValue(subtitle, control)}
      editCart={control.context.t(globalBookingMessages.text.editCart)}
      control={control}
      isDeletingItem={isDeletingItem}
      themeStyle={themeStyle}
      isSpaBooking={cartType === CartType.Dayspa}
      deleteLabel={control.context.t(globalBookingMessages.text.removeFromCart)}
      displayFractionalPrices={displayFractionalPrices}
      hideCurrencyChange={hideCurrencyChange}
    />
  )
}
