import { useCallback, useState } from 'react'
import { addDays } from 'date-fns/addDays'

import { colors } from 'bl-common/src/constants/colors'
import { Amount } from 'bl-common/src/elements/Amount'
import { Button } from 'bl-common/src/elements/Button/Button'
import { Caret } from 'bl-common/src/elements/Icons/Caret'
import { Spinner } from 'bl-common/src/elements/Spinner'
import { Type } from 'bl-common/src/elements/Typography/Typography'
import { SubtleErrorMessage } from 'bl-common/src/form/SubtleErrorMessage'
import {
  type Package,
  type PackageSlot,
  usePackageAvailabilityQuery,
} from 'bl-graphql'
import { displayDate } from 'bl-utils/src/formatting/formatDate'
import { formatDateInUTC } from 'bl-utils/src/formatting/formatDate'

import type { FlowComponent, FlowSpaPackageTableField } from '../../types'
import { getFlowValue } from '../../utils'
import * as styles from './styles'

type SpaPackageTableFieldProps = FlowComponent &
  FlowSpaPackageTableField<Package>['props'] & {
    id: string
  }

export const SpaPackageTableField = (props: SpaPackageTableFieldProps) => {
  const arrivalDate = getFlowValue(props.arrivalDate, props.control)
  const [selectedDate, setSelectedDate] = useState(arrivalDate)
  const selectedPackage =
    getFlowValue(props.value, props.control) ??
    props.control.screen.state[props.id]

  const queryOptions = {
    variables: {
      date: selectedDate ? formatDateInUTC(selectedDate, 'yyyy-MM-dd') : null,
      subscription: '',
    },
    notifyOnNetworkStatusChange: true,
  }

  const { loading, error, data, refetch } =
    usePackageAvailabilityQuery(queryOptions)

  const onSelectSlot = useCallback(
    (selectedPackage: PackageSlot, selectedSlot: Package) => {
      const v = {
        package: selectedSlot.type,
        time: selectedPackage.time,
        price: selectedSlot.price,
      }

      const [hours, minutes] = selectedPackage.time
        .split(':')
        .map(s => Number(s))
      const arrival = new Date(selectedDate)
      arrival.setHours(hours)
      arrival.setMinutes(minutes)

      props.onClick(v, props.control)
    },
    [props, selectedDate]
  )

  if (!selectedDate) {
    return (
      <>
        <SubtleErrorMessage>No date selected</SubtleErrorMessage>
      </>
    )
  }

  const moveDate = (newDate: Date) => {
    props.onMoveArrivalDate(newDate, props.control)
    setSelectedDate(newDate)
  }

  const now = new Date()

  return (
    <>
      <styles.DateControls>
        <styles.ControlButton
          type="button"
          style={{
            justifyContent: 'flex-start',
          }}
          disabled={selectedDate <= now}
          onClick={() => {
            moveDate(addDays(selectedDate, -1))
          }}
        >
          <Caret
            color={colors.deepBlue}
            height="10px"
            style={{ transform: 'rotate(90deg)' }}
          />
        </styles.ControlButton>
        <Type as="h4" aria-live="polite" case="uppercase" preset="label">
          <styles.Weekday aria-hidden="true">
            {displayDate(selectedDate, {
              locale: props.control.context.locale,
              withDayOfMonth: false,
              withMonth: false,
              withWeekday: true,
              withYear: false,
              displayLongWeekday: true,
            })}
          </styles.Weekday>
          <styles.DayOfTheMonth aria-hidden="true">
            {displayDate(selectedDate, {
              locale: props.control.context.locale,
              withDayOfMonth: true,
              withMonth: true,
              withWeekday: false,
              withYear: false,
              displayLongMonth: false,
            })}
          </styles.DayOfTheMonth>
        </Type>
        <styles.ControlButton
          type="button"
          style={{
            justifyContent: 'flex-end',
          }}
          onClick={() => {
            moveDate(addDays(selectedDate, 1))
          }}
        >
          <Caret
            color={colors.deepBlue}
            height="10px"
            style={{ transform: 'rotate(-90deg)' }}
          />
        </styles.ControlButton>
      </styles.DateControls>
      <styles.Table data-test="time-slots">
        <styles.TableHeader>
          <styles.ColumnLabels>
            <styles.DayPartLabel>
              <Type size={{ xs: 10, md: 12 }} weight="bold" case="uppercase">
                Time
              </Type>
            </styles.DayPartLabel>
            <styles.PackageTypeHeader>
              <Type size={{ xs: 10, md: 12 }} weight="bold" case="uppercase">
                Comfort
              </Type>
            </styles.PackageTypeHeader>
            <styles.PackageTypeHeader>
              <Type size={{ xs: 10, md: 12 }} weight="bold" case="uppercase">
                Premium
              </Type>
            </styles.PackageTypeHeader>
            <styles.PackageTypeHeader>
              <Type size={{ xs: 10, md: 12 }} weight="bold" case="uppercase">
                Retreat
              </Type>
            </styles.PackageTypeHeader>
          </styles.ColumnLabels>
        </styles.TableHeader>
        <styles.TableBody>
          {loading ? (
            <styles.SpinnerWrapper>
              <Spinner shouldAnimate />
            </styles.SpinnerWrapper>
          ) : data ? (
            data.packageAvailability.slots.map(slot => (
              <styles.TimeSlotContainer key={slot.time}>
                <styles.TimeSlotCell>{slot.time}</styles.TimeSlotCell>
                {slot.packages?.map(currentPackage => (
                  <styles.TimeSlotCell
                    key={`${slot.time}_${currentPackage.type}`}
                    isActive={
                      arrivalDate.getTime() === selectedDate.getTime() &&
                      selectedPackage?.package === currentPackage.type &&
                      selectedPackage?.time === slot.time
                    }
                    onClick={() => {
                      onSelectSlot(slot, currentPackage)
                    }}
                  >
                    {currentPackage.available > 0 ? (
                      <Type size={{ xs: 12, md: 18 }} duration={200}>
                        <Amount
                          aria-hidden
                          value={currentPackage.price}
                          useSymbol
                        />
                      </Type>
                    ) : (
                      <Type size={{ xs: 11, md: 14 }} duration={200}>
                        Not available
                      </Type>
                    )}
                  </styles.TimeSlotCell>
                ))}
              </styles.TimeSlotContainer>
            ))
          ) : error ? (
            // TODO: revisit this temporary solution
            <>
              <p>There was an error</p>
              <Button onClick={() => refetch()}>Refetch</Button>
            </>
          ) : (
            'No data'
          )}
        </styles.TableBody>
      </styles.Table>
    </>
  )
}
