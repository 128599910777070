import { useTheme } from 'styled-components'

import { colors } from 'bl-common/src/constants/colors'
import type { ButtonPreset } from 'bl-common/src/elements/Button/Button'
import { Button } from 'bl-common/src/elements/Button/Button'

import type { FlowButtonField, FlowComponent } from '../../types'

type ButtonProps = FlowComponent & FlowButtonField['props']

const themeToPresetsMap = {
  default: 'blue',
  blue: 'lightGrey',
  highlandDefault: 'highlandGreen',
  highlandGreen: 'highlandWhite',
}

export const ButtonField = ({
  control,
  screenTheme,
  label,
  preset,
  isDisabled = false,
  isSubmit = false,
  paddingSize = 'medium',
  onClick,
}: ButtonProps) => {
  const theme = useTheme()
  const themeStyle = theme?.bookingEngine?.[screenTheme]?.buttonField

  const colorStyle: { color?: string } = {}
  const buttonPreset =
    preset ?? (themeToPresetsMap[screenTheme] as ButtonPreset)
  // For this scenario, we want to use the lightGrey preset for the button but change the color to the screenTheme color
  if (
    (screenTheme === 'blue' && buttonPreset === 'lightGrey') ||
    buttonPreset === 'white'
  ) {
    colorStyle.color = colors.deepBlue
  }
  if (screenTheme === 'retreat' && buttonPreset === 'white') {
    colorStyle.color = colors.darkmode
  }

  return (
    <>
      <Button
        type={isSubmit === true ? 'submit' : 'button'}
        onClick={() => onClick?.(control)}
        preset={buttonPreset as ButtonPreset}
        paddingSize={paddingSize}
        style={{
          width: '100%',
          ...themeStyle?.button,
          ...colorStyle,
        }}
        disabled={
          typeof isDisabled === 'function'
            ? isDisabled(control)
            : isDisabled === true
        }
      >
        {typeof label === 'function' ? label(control) : label}
      </Button>
    </>
  )
}
