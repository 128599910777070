import { useEffect, useState } from 'react'
import { css } from 'styled-components'

import { colors } from 'bl-common/src/constants/colors'
import { useBreakpoints } from 'bl-common/src/hooks/useBreakpoints'
import { useCartContext } from 'bl-common/src/hooks/useCartContext'
import { useMailListSignup } from 'bl-common/src/hooks/useMailListSignup'
import { theme } from 'bl-common/src/styles/theme'
import type { ScreenTheme } from 'bl-common/src/styles/types'
import {
  buildCheckboxInputField,
  buildCustomField,
  buildDiscountAccordionField,
  buildHeading,
  buildInputText,
  buildPhoneInputField,
  buildProgressButton,
  buildScreenWithSidebarLayout,
  buildSelectField,
  buildText,
  type FlowControl,
  type FlowSidebarLayout,
} from 'bl-flows-core'
import type { AtLeastOne } from 'bl-flows-core/src/types/typeUtils'
import type { Cart } from 'bl-graphql'
import { triggerEvent } from 'bl-utils/src/analytics/events'
import { calcPrice } from 'bl-utils/src/currency/calcPrice'
import { displayDate } from 'bl-utils/src/formatting/formatDate'
import { formatHtmlText } from 'bl-utils/src/formatting/formatHtmlText'
import { sortedNationalities } from 'bl-utils/src/nationalities/sortedNationalities'
import { validateEmail } from 'bl-utils/src/validation/validateEmail'

import { globalBookingMessages } from '../../../messages'
import { createRequiredValidator } from '../../../utils'
import { getSpaBookingItems } from '../../../utils/get-analytics-items'
import { defaultRequiredValidator } from '../../../utils/validation'
import { SpaCart } from '../components/SpaCart'
import { bookingDetailsMessages } from '../messages/bookingDetails'
import {
  completeAndNavigate,
  type ConfirmationPaths,
  extractCustomerData,
  finalizeBookingWithoutPayment,
  getSpaFlowSettingsByAdmissionType,
  getSpaNewsletterId,
  orderAdmissions,
} from '../utils'
import { triggerAddShippingEvent } from '../utils/triggerAddShippingSpaEvent'

const cartLayoutProps: FlowSidebarLayout['props'] = {
  id: 'confirm',
}

const triggerBeginCheckoutEvent = (control: FlowControl) => {
  const exchangeRates = control.flow.setupHook?.exchangeRates
  const cart = control.flow.setupHook?.cart as Cart
  const orderedAdmissions = orderAdmissions(cart)
  const analyticsItems = getSpaBookingItems(
    orderedAdmissions,
    exchangeRates,
    cart?.promoCode ?? ''
  )

  triggerEvent({
    event: 'begin_checkout',
    ecommerce: {
      currency: 'EUR',
      value: calcPrice(cart?.paymentAmount, exchangeRates?.EUR),
      coupon: cart?.promoCode ?? '',
      items: analyticsItems,
    },
  })
}

export const bookingDetailsScreen = ({
  screenTheme,
  confirmationPaths,
}: {
  screenTheme: ScreenTheme
  confirmationPaths: ConfirmationPaths
}) => {
  return buildScreenWithSidebarLayout({
    id: 'information',
    subType: 'form',
    route: control => {
      const selectedPackage =
        control?.flow?.setupHook?.selectedPackage || 'comfort'
      return {
        en:
          selectedPackage === 'retreat'
            ? 'information'
            : `${selectedPackage}/information`,
        is:
          selectedPackage === 'retreat'
            ? 'upplysingar'
            : `${selectedPackage}/upplysingar`,
      }
    },
    theme: screenTheme,
    setupHook: control => {
      const [
        hasTriggeredBeginCheckoutEvent,
        setHasTriggeredBeginCheckoutEvent,
      ] = useState(false)
      const { mediaSmd } = useBreakpoints()
      const { loading: isCartLoading } = useCartContext()

      const selectedPackage =
        control?.flow?.setupHook?.selectedPackage || 'comfort'
      // Get the locale
      const locale = control.context.locale

      // Get spa flow settings for the admission type
      const spaFlowSettings = getSpaFlowSettingsByAdmissionType(
        selectedPackage,
        control.context.spaFlowSettings
      )

      const newsletterId = getSpaNewsletterId(spaFlowSettings, locale)

      const { signUp } = useMailListSignup({
        klaviyoListId: newsletterId,
      })

      useEffect(() => {
        if (!isCartLoading && !hasTriggeredBeginCheckoutEvent) {
          setHasTriggeredBeginCheckoutEvent(true)
          triggerBeginCheckoutEvent(control)
        }
      }, [isCartLoading, hasTriggeredBeginCheckoutEvent])

      return {
        nationalities: sortedNationalities(),
        isMobile: !mediaSmd,
        signUp,
        newsletterId,
      }
    },
    layoutProps: cartLayoutProps,
    breadcrumb: control => ({
      title: control.context.t(bookingDetailsMessages.info.breadcrumbTitle),
    }),
    fields: {
      main: [
        ...buildHeading({
          title: control =>
            control.context.t(bookingDetailsMessages.info.title),
          subTitle: control => {
            const localeDate = displayDate(
              control.flow.state.calendar?.arrivalDate,
              {
                locale: control.context.locale,
              }
            )
            // for icelandic, we need to remove the dot at the end of the date e.g. '5. des.' => '5. des'
            const formattedDate =
              control.context.locale === 'is' && localeDate?.endsWith('.')
                ? localeDate?.slice(0, -1)
                : localeDate

            return control.context.t(bookingDetailsMessages.info.subtitle, {
              formattedDate,
              formattedPrice: `<span style="color: ${
                screenTheme === 'retreat' ? colors.blueOnDark : colors.deepBlue
              }; font-weight: bold;">${
                control.flow.setupHook?.formattedPrice
              }</span>`,
            })
          },
          includeBreadcrumb: true,
          showMobileCart: true,
        }),
        buildInputText({
          id: 'primaryGuest.firstName',
          props: {
            label: control =>
              control.context.t(globalBookingMessages.labels.firstName),
            placeholder: control =>
              control.context.t(globalBookingMessages.placeholders.firstName),
            initialValue: control =>
              control.flow.state?.information?.primaryGuest?.firstName,
            required: true,
            maxLength: 40,
          },
          layout: {
            width: { smd: 'calc((100% / 2) - 10px)' },
          },
          validation: {
            validator: (value, _, control) => {
              const requiredError = createRequiredValidator(
                globalBookingMessages.errors.firstNameRequired
              )(value, _, control)

              if (requiredError) {
                return requiredError
              }
            },
          },
        }),
        buildInputText({
          id: 'primaryGuest.lastName',
          props: {
            label: control =>
              control.context.t(globalBookingMessages.labels.lastName),
            placeholder: control =>
              control.context.t(globalBookingMessages.placeholders.lastName),
            initialValue: control =>
              control.flow.state?.information?.primaryGuest?.lastName,
            required: true,
            maxLength: 40,
          },
          layout: {
            width: { smd: 'calc((100% / 2) - 10px)' },
            spacing: {
              mt: { xs: 2, smd: 0 },
            },
          },
          validation: {
            validator: (value, _, control) => {
              const requiredError = createRequiredValidator(
                globalBookingMessages.errors.lastNameRequired
              )(value, _, control)

              if (requiredError) {
                return requiredError
              }
            },
          },
        }),
        buildInputText({
          id: 'primaryGuest.email',

          props: {
            type: 'email',
            label: control =>
              control.context.t(globalBookingMessages.labels.email),
            placeholder: control =>
              control.context.t(globalBookingMessages.placeholders.email),
            initialValue: control =>
              control.flow.state?.information?.primaryGuest?.email,
            maxLength: 80,
            required: true,
          },
          layout: {
            spacing: {
              mt: { xs: 2 },
            },
          },
          validation: {
            validator: (value, _, control) => {
              const requiredError = createRequiredValidator(
                globalBookingMessages.errors.emailRequired
              )(value, _, control)

              if (requiredError) {
                return requiredError
              }

              if (typeof value !== 'string' || !validateEmail(value)) {
                return control.context.t(
                  globalBookingMessages.errors.invalidEmail
                )
              }
            },
          },
        }),
        buildSelectField({
          id: 'nationality',
          defaultValue: control => control.context.detectedCC,
          props: {
            label: control =>
              control.context.t(globalBookingMessages.labels.nationality),
            onChange: (_, control) => {
              // Side effect here is when the nationality is
              // changed the phone number is reset
              control.screen.setState({
                ...control.screen.stateRef.current,
                primaryGuest: {
                  ...control.screen.stateRef.current.primaryGuest,
                  phone: '',
                },
              })
            },
            isDisabled: control => !control.screen.setupHook?.nationalities,
            options: control =>
              (control.screen.setupHook?.nationalities as AtLeastOne<{
                label: string
                value: string
              }>) ?? [
                {
                  label: control.context.t(
                    globalBookingMessages.labels.loading
                  ),
                  value: '',
                },
              ],
          },
          layout: {
            spacing: {
              mt: { xs: 2 },
            },
            width: { smd: 'calc((40%) - 16px)' },
          },
          validation: {
            validator: defaultRequiredValidator,
          },
        }),
        buildPhoneInputField({
          id: 'primaryGuest.phone',
          props: {
            label: control =>
              control.context.t(globalBookingMessages.labels.phoneNumber),
            countryCode: control => control.screen.state.nationality,
          },
          layout: {
            spacing: {
              mt: { xs: 2 },
            },
            width: { smd: 'calc((60%) - 16px)' },
          },
        }),
        buildDiscountAccordionField({
          id: 'promoCode',
          layout: {
            spacing: {
              mt: { xs: 2 },
            },
          },
          props: {
            label: control =>
              control.context.t(globalBookingMessages.labels.promoCode),
            placeholder: control =>
              control.context.t(globalBookingMessages.labels.promoCode),
            activePromoCode: control => control.flow.setupHook?.cart?.promoCode,
            activeGiftCards: control => control.flow.setupHook?.cart?.giftCards,
            discountedAmount: control =>
              control.flow.setupHook?.cart?.discountedAmount,
            giftCardAmount: control =>
              control.flow.setupHook?.cart?.giftCardAmount,
            addPromoCode: (promoCode, control) =>
              control.flow.setupHook?.addPromoCode(promoCode),
            addGiftCard: (giftCard, control) =>
              control.flow.setupHook?.addGiftCard(giftCard),
            removeGiftCard: (giftCard, control) =>
              control.flow.setupHook?.removeGiftCard(giftCard),
          },
        }),
        buildCheckboxInputField({
          id: 'agreeToTerms',
          props: {
            label: control => {
              const isRetreat = screenTheme === 'retreat'

              const property =
                control.flow.state?.roomDetails?.property?.toLowerCase()

              // Default terms
              let termsText =
                globalBookingMessages.labels.agreeToTermsAndPolicies

              if (property === 'silica') {
                termsText =
                  globalBookingMessages.labels.agreeToTermsAndPoliciesSilica
              } else if (property === 'retreat') {
                termsText =
                  globalBookingMessages.labels.agreeToTermsAndPoliciesRetreat
              }

              return isRetreat
                ? formatHtmlText(
                    control.context.t(termsText),
                    css`
                      font-weight: 500;
                      color: #91d6e5;
                    `
                  )
                : formatHtmlText(control.context.t(termsText))
            },
            required: true,
          },
          layout: {
            spacing: {
              mt: { xs: 2 },
            },
          },
          validation: {
            validator: (value, _, control) => {
              const requiredError = createRequiredValidator(
                globalBookingMessages.errors.termsRequired
              )(value, _, control)

              if (requiredError) {
                return requiredError
              }
            },
          },
        }),
        buildCheckboxInputField({
          id: 'receiveNewsLetter',
          props: {
            label: control =>
              control.context.t(
                globalBookingMessages.labels.wishToReceiveNewsletter
              ),
          },
          layout: {
            spacing: {
              mt: { xs: 2 },
            },
          },
        }),
        buildProgressButton({
          condition: control => {
            const cart = control.flow.setupHook?.cart

            return (
              Boolean(cart?.paymentRequired) && Boolean(cart?.paymentAmount > 0)
            )
          },
          props: {
            label: control =>
              control.context.t(bookingDetailsMessages.info.buttonContinue),
            onClick: async control => {
              const validation = control.validateAndSetScreenErrors()
              if (!validation?.hasErrors) {
                control.flow.setState({
                  information: control.screen.state,
                })

                const flowControl = control.flow
                const flowState =
                  flowControl.stateRef.current || flowControl.state
                const customer = extractCustomerData(flowState)

                const result = await flowControl.setupHook?.addSpaCustomerInfo({
                  customer,
                })

                if (result?.data?.addSpaCustomerInfo?.id) {
                  triggerAddShippingEvent(control)
                  return true
                }
              }
            },
            onComplete: control => {
              control.nextScreen()
            },
          },
          layout: {
            width: { xs: `calc(100% + ${theme.spacing[2]})` },
            spacing: {
              ml: { xs: -1 },
              mr: { xs: -1 },
              mt: { xs: 2 },
            },
          },
        }),
        buildProgressButton({
          condition: control => {
            const cart = control.flow.setupHook?.cart

            return (
              Boolean(!cart?.paymentRequired) &&
              Boolean(cart?.paymentAmount === 0)
            )
          },
          props: {
            label: control =>
              control.context.t(
                bookingDetailsMessages.info.buttonContinueNoPaymentRequired
              ),
            onClick: async control => {
              const validation = control.validateAndSetScreenErrors()
              if (!validation?.hasErrors) {
                control.flow.setState({
                  information: control.screen.state,
                })

                return await finalizeBookingWithoutPayment({
                  control,
                  newsletterSignup:
                    control.screen.setupHook?.newsletterId &&
                    control.screen.state?.receiveNewsLetter &&
                    control.screen.setupHook?.signUp,
                })
              }
            },
            onComplete: control => {
              completeAndNavigate(control, confirmationPaths)
            },
          },
          layout: {
            width: { xs: `calc(100% + ${theme.spacing[2]})` },
            spacing: {
              ml: { xs: -1 },
              mr: { xs: -1 },
              mt: { xs: 2 },
            },
          },
        }),
        buildText({
          props: {
            type: 'text',
            value: control =>
              control.context.t(
                globalBookingMessages.labels.requiredAsterikExplanation
              ),
          },
          layout: {
            spacing: {
              mt: { xs: 0.5 },
              mb: { xs: 5, md: 4, bmd: 0 },
            },
          },
        }),
      ],
      right: [
        buildCustomField({
          defaultValue: null,
          props: {
            component: SpaCart,
          },
        }),
      ],
    },
  })
}
