import styled from 'styled-components'

import { Type } from 'bl-common/src/elements/Typography/Typography'
import { theme } from 'bl-common/src/styles/theme'
import {
  globalBookingMessages,
  productIdsMessages,
} from 'bl-flows/src/messages'
import { privateTransferMessages } from 'bl-flows/src/subflows/privateTransfer/messages/privateTransferMessages'
import { calculateNumberOfNights } from 'bl-flows/src/utils'
import { getTimeFromDateTimeString } from 'bl-utils/src/date'
import { formatDateInUTC } from 'bl-utils/src/formatting/formatDate'
import { isElementIncludedInArray } from 'bl-utils/src/isElementIncludedInArray'
import {
  HB_PRODUCT_IDS,
  massageAndFloatProductIds,
  massageProductIds,
  privateTransferArrivalIds,
  type PrivateTransferArrivalProductIds,
  privateTransferServioIds,
  PRODUCT_IDS,
} from 'bl-utils/src/ProductIds'

import type {
  ConfirmationSummaryActivity,
  ConfirmationSummaryPrivateTransferItem,
  ConfirmationSummaryProduct,
  ConfirmationSummaryRoom,
  ConfirmationSummarySpaItem,
  FlowComponent,
  FlowConfirmationSummaryField,
  FlowControl,
} from '../../types'
import { getFlowValue } from '../../utils'

type ConfirmationSummaryFieldProps = FlowComponent &
  FlowConfirmationSummaryField['props']

const Wrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
})

const Room = styled.div({
  marginBottom: theme.spacing[1.5],
})

const BusWrapper = styled.div({
  marginTop: theme.spacing[1.5],
})

const ProductsWrapper = styled.div({
  marginBottom: theme.spacing[1.5],
})

const ActivityWrapper = styled.div({
  marginBottom: theme.spacing[1.5],
})

const PrivateTransferWrapper = styled.div({
  marginBottom: theme.spacing[1.5],
})

const getRoomAddonsString = (
  roomAddons: ConfirmationSummaryProduct[],
  control: FlowControl
) => {
  return roomAddons
    .map(addon => {
      return (
        (productIdsMessages?.[addon?.productId]
          ? control.context.t(productIdsMessages?.[addon?.productId])
          : addon?.title) ?? ''
      )
    })
    .join(', ')
}

const combineProducts = (products: ConfirmationSummaryProduct[]) => {
  const combinedProducts = []

  products.forEach(product => {
    const isDuplicate = combinedProducts.find(
      element => element.productId === product.productId
    )

    if (!isDuplicate) {
      combinedProducts.push({ ...product })
    } else {
      isDuplicate.qty += product.qty
    }
  })

  return combinedProducts
}

const renderHotel = (
  control: FlowControl,
  rooms: ConfirmationSummaryRoom[]
) => {
  const nights =
    rooms?.length &&
    calculateNumberOfNights(
      new Date(rooms[0].arrivalDate),
      new Date(rooms[0].departureDate)
    )

  const privateTransfers =
    rooms?.length &&
    rooms
      .flatMap(room => room.privateTransfer ?? [])
      .sort((a, b) => {
        return (
          new Date(a.reservationDate).getTime() -
          new Date(b.reservationDate).getTime()
        )
      })

  const highlandTransfers =
    rooms?.length &&
    rooms
      .flatMap(room => room.highlandBaseTransferItems ?? [])
      .sort((a, b) => {
        return (
          new Date(a.reservationDate).getTime() -
          new Date(b.reservationDate).getTime()
        )
      })

  return (
    <Wrapper>
      <Type preset="text" weight="bold" case="uppercase" bottom={1}>
        {control.context.t(globalBookingMessages.labels.summary)}
      </Type>
      {rooms?.map((room, index) => (
        <Room key={`room-${index}`}>
          <Type preset="text">
            <span style={{ fontWeight: 700, marginRight: 8 }}>
              {control.context.t(globalBookingMessages.labels.package)}:
            </span>
            {room?.packageTitle}
          </Type>
          <Type preset="text">
            <span style={{ fontWeight: 700, marginRight: 8 }}>
              {control.context.t(globalBookingMessages.labels.room)}:
            </span>
            {room?.roomTitle}
          </Type>
          {!!room.products?.length && (
            <Type preset="text">
              <span style={{ fontWeight: 700, marginRight: 8 }}>
                {control.context.t(globalBookingMessages.labels.roomAddons)}:
              </span>
              {getRoomAddonsString(room.products, control)}
            </Type>
          )}
          <Type preset="text">
            <span style={{ fontWeight: 700, marginRight: 8 }}>
              {control.context.t(globalBookingMessages.labels.dates)}:
            </span>
            {`${formatDateInUTC(
              room.arrivalDate,
              'dd MMM yy',
              control.context.locale
            )} - ${formatDateInUTC(
              room.departureDate,
              'dd MMM yy',
              control.context.locale
            )} (${control.context.t(globalBookingMessages.text.nights, {
              nights,
            })})`}
          </Type>
          <Type preset="text">
            <span style={{ fontWeight: 700, marginRight: 8 }}>
              {control.context.t(globalBookingMessages.labels.guests)}:
            </span>
            {control.context.t(globalBookingMessages.text.adults, {
              adults: room.adults,
            })}
            {!!room.children &&
              `, ${control.context.t(globalBookingMessages.text.children, {
                children: room.children,
              })}`}
            {!!room.infants &&
              `, ${control.context.t(globalBookingMessages.text.infants, {
                infants: room.infants,
              })}`}
          </Type>
          {!!room.activities?.length &&
            room.activities.map((activity, index) => (
              <Type
                preset="text"
                key={`${activity.productId}-${index}`}
                style={{
                  ...(activity.notAvailable && {
                    textDecoration: 'line-through',
                  }),
                }}
              >
                <span style={{ fontWeight: 700, marginRight: 8 }}>
                  {activity.qty && activity.qty > 0 && <>{activity.qty}x </>}
                  {productIdsMessages?.[activity?.productId]
                    ? control.context.t(
                        productIdsMessages?.[activity?.productId]
                      )
                    : (activity?.title ?? '')}
                  :
                </span>
                {activity?.notAvailable
                  ? control.context.t(globalBookingMessages.text.notAvailable)
                  : activity?.pending
                    ? control.context.t(globalBookingMessages.text.pending)
                    : control.context.t(globalBookingMessages.text.dateAtTime, {
                        date: formatDateInUTC(
                          activity.reservationDate,
                          'dd MMM yy',
                          control.context.locale
                        ),
                        time: formatDateInUTC(
                          activity.reservationDate,
                          'HH:mm',
                          control.context.locale
                        ),
                      })}
              </Type>
            ))}
        </Room>
      ))}
      {!!privateTransfers?.length &&
        privateTransfers.map((transfer, index) => (
          <Type
            preset="text"
            key={`${transfer.productId}-${index}`}
            style={{
              ...(transfer.notAvailable && {
                textDecoration: 'line-through',
              }),
            }}
          >
            <span style={{ fontWeight: 700, marginRight: 8 }}>
              {privateTransferArrivalIds.includes(
                transfer?.productId as PrivateTransferArrivalProductIds
              )
                ? control.context.t(globalBookingMessages.labels.airportPickup)
                : control.context.t(
                    globalBookingMessages.labels.airportDropoff
                  )}
              :
            </span>
            {transfer?.notAvailable
              ? control.context.t(globalBookingMessages.text.notAvailable)
              : transfer?.pending
                ? control.context.t(globalBookingMessages.text.pending)
                : control.context.t(globalBookingMessages.text.dateAtTime, {
                    date: formatDateInUTC(
                      transfer.reservationDate,
                      'dd MMM yy',
                      control.context.locale
                    ),
                    time: formatDateInUTC(
                      transfer.reservationDate,
                      'HH:mm',
                      control.context.locale
                    ),
                  })}{' '}
            {transfer?.notAvailable || transfer?.pending
              ? ''
              : transfer?.productId ===
                    PRODUCT_IDS.PrivateTransferArrivalSmall ||
                  transfer?.productId ===
                    PRODUCT_IDS.PrivateTransferDepartureSmall
                ? control.context.t(
                    globalBookingMessages.text.privateTransferSmallForGuests
                  )
                : transfer?.productId ===
                      PRODUCT_IDS.PrivateTransferArrivalLarge ||
                    transfer?.productId ===
                      PRODUCT_IDS.PrivateTransferDepartureLarge
                  ? control.context.t(
                      globalBookingMessages.text.privateTransferLargeForGuests
                    )
                  : transfer?.productId ===
                        PRODUCT_IDS.PrivateTransferArrivalServioSmall ||
                      transfer?.productId ===
                        PRODUCT_IDS.PrivateTransferDepartureServioSmall
                    ? control.context.t(
                        globalBookingMessages.text
                          .privateTransferSmallServioForGuests
                      )
                    : transfer?.productId ===
                          PRODUCT_IDS.PrivateTransferArrivalServioMedium ||
                        transfer?.productId ===
                          PRODUCT_IDS.PrivateTransferDepartureServioMedium
                      ? control.context.t(
                          globalBookingMessages.text
                            .privateTransferMediumServioForGuests
                        )
                      : control.context.t(
                          globalBookingMessages.text
                            .privateTransferLargeServioForGuests
                        )}
          </Type>
        ))}
      {!!highlandTransfers?.length &&
        highlandTransfers.map((transfer, index) => (
          <Type preset="text" key={`${transfer.productId}-${index}`}>
            <span style={{ fontWeight: 700, marginRight: 8 }}>
              {transfer?.productId === HB_PRODUCT_IDS.TransferRvkHb
                ? control.context.t(
                    globalBookingMessages.text.transferFromRvkToHb
                  )
                : transfer?.productId === HB_PRODUCT_IDS.TransferSkjolHb
                  ? control.context.t(
                      globalBookingMessages.text.transferFromSkjolToHb
                    )
                  : transfer?.productId === HB_PRODUCT_IDS.TransferHbRvk
                    ? control.context.t(
                        globalBookingMessages.text.transferFromHbToRvk
                      )
                    : transfer?.productId === HB_PRODUCT_IDS.TransferHbSkjol
                      ? control.context.t(
                          globalBookingMessages.text.transferFromHbToSkjol
                        )
                      : transfer.productId}
              :
            </span>
            {control.context.t(globalBookingMessages.text.dateAtTime, {
              date: formatDateInUTC(
                transfer.reservationDate,
                'dd MMM yy',
                control.context.locale
              ),
              time: formatDateInUTC(
                transfer.reservationDate,
                'HH:mm',
                control.context.locale
              ),
            })}{' '}
            {control.context.t(globalBookingMessages.text.forGuests, {
              count: transfer.qty,
            })}
          </Type>
        ))}
    </Wrapper>
  )
}

const renderSpa = (control: FlowControl, spa: ConfirmationSummarySpaItem) => {
  return (
    <Wrapper>
      <Type preset="text" weight="bold" case="uppercase" bottom={1}>
        {control.context.t(globalBookingMessages.labels.summary)}
      </Type>
      <Type preset="text">
        <span style={{ fontWeight: 700, marginRight: 8 }}>
          {control.context.t(globalBookingMessages.labels.admission)}:
        </span>
        {`${spa.packageTitle}`}
      </Type>
      <Type preset="text">
        <span style={{ fontWeight: 700, marginRight: 8 }}>
          {control.context.t(globalBookingMessages.labels.dateAndTime)}:
        </span>
        {`${control.context.t(globalBookingMessages.text.dateAtTime, {
          date: formatDateInUTC(spa.date, 'dd MMM yy', control.context.locale),
          time: getTimeFromDateTimeString(spa.time),
        })}`}
      </Type>
      <Type preset="text">
        <span style={{ fontWeight: 700, marginRight: 8 }}>
          {control.context.t(globalBookingMessages.labels.guests)}:
        </span>
        {`${control.context.t(globalBookingMessages.text.adults, {
          adults: spa.adults,
        })}`}
        {!!spa.youngAdults &&
          `, ${control.context.t(globalBookingMessages.text.youngAdults, {
            youngAdults: spa.youngAdults,
          })}`}
        {!!spa.children &&
          `, ${control.context.t(globalBookingMessages.text.children, {
            children: spa.children,
          })}`}
      </Type>
      {!!spa.products?.length && (
        <Type preset="text">
          <span style={{ fontWeight: 700, marginRight: 8 }}>
            {control.context.t(globalBookingMessages.labels.products)}:
          </span>
          {combineProducts(spa.products).map(product => (
            <span key={product.title}>
              {product.title} x {product.qty}
            </span>
          ))}
        </Type>
      )}
      {!!spa.activities?.length &&
        spa.activities.map((activity, index) => (
          <Type preset="text" key={`${activity.productId}-${index}`}>
            <span style={{ fontWeight: 700, marginRight: 8 }}>
              {productIdsMessages?.[activity?.productId]
                ? control.context.t(productIdsMessages?.[activity?.productId])
                : (activity?.title ?? '')}
              :
            </span>
            {control.context.t(globalBookingMessages.text.dateAtTime, {
              date: formatDateInUTC(
                activity.reservationDate,
                'dd MMM yy',
                control.context.locale
              ),
              time: getTimeFromDateTimeString(activity.reservationDate),
            })}
          </Type>
        ))}
      {!!spa.busTransfer && (
        <BusWrapper>
          <Type preset="text">
            <span style={{ fontWeight: 700, marginRight: 8 }}>
              {control.context.t(globalBookingMessages.labels.pickup)}:
            </span>
            {spa.busTransfer.pickupLocationName}
            {', '}
            {control.context.t(globalBookingMessages.text.dateAtTime, {
              date: formatDateInUTC(
                spa.busTransfer.pickupDate,
                'dd MMM yy',
                control.context.locale
              ),
              time: spa.busTransfer.pickupTime,
            })}
          </Type>
          <Type preset="text">
            <span style={{ fontWeight: 700, marginRight: 8 }}>
              {control.context.t(globalBookingMessages.labels.dropoff)}:
            </span>
            {spa.busTransfer.dropoffLocationName}
            {', '}
            {control.context.t(globalBookingMessages.text.dateAtTime, {
              date: formatDateInUTC(
                spa.busTransfer.dropoffDate,
                'dd MMM yy',
                control.context.locale
              ),
              time: spa.busTransfer.dropoffTime,
            })}
          </Type>
        </BusWrapper>
      )}
    </Wrapper>
  )
}

const renderProducts = (
  control: FlowControl,
  products: ConfirmationSummaryProduct[]
) => {
  return (
    <Wrapper>
      <Type preset="text" weight="bold" case="uppercase" bottom={1}>
        {control.context.t(globalBookingMessages.labels.summary)}
      </Type>
      {products.map(product => (
        <ProductsWrapper key={product.productId}>
          <Type preset="text">
            <span style={{ fontWeight: 700, marginRight: 8 }}>
              {control.context.t(globalBookingMessages.labels.product)}:
            </span>
            {product.title}
          </Type>
          <Type preset="text">
            <span style={{ fontWeight: 700, marginRight: 8 }}>
              {control.context.t(globalBookingMessages.labels.quantity)}:
            </span>
            {product.qty}
          </Type>
        </ProductsWrapper>
      ))}
    </Wrapper>
  )
}

const renderPrivateTransferServio = (
  control: FlowControl,
  transfer: ConfirmationSummaryPrivateTransferItem
) => {
  return (
    <Wrapper>
      <Type preset="text" weight="bold" case="uppercase" bottom={1}>
        {control.context.t(globalBookingMessages.labels.summary)}
      </Type>
      {transfer.arrival?.productId && (
        <PrivateTransferWrapper>
          <Type preset="text">
            {transfer.arrival.productId ===
            PRODUCT_IDS.PrivateTransferArrivalServioSmall
              ? control.context.t(
                  privateTransferMessages.info.arrivalServioSmall
                )
              : transfer.arrival.productId ===
                  PRODUCT_IDS.PrivateTransferArrivalServioMedium
                ? control.context.t(
                    privateTransferMessages.info.arrivalServioMedium
                  )
                : control.context.t(
                    privateTransferMessages.info.arrivalServioLargeOverview
                  )}
          </Type>
          <Type preset="text">
            <span style={{ fontWeight: 700, marginRight: 8 }}>
              {control.context.t(privateTransferMessages.info.pickupLocation)}:
            </span>
            {transfer.arrival.productId ===
              PRODUCT_IDS.PrivateTransferArrivalServioSmall ||
            transfer.arrival.productId ===
              PRODUCT_IDS.PrivateTransferArrivalServioMedium ||
            transfer.arrival.productId ===
              PRODUCT_IDS.PrivateTransferArrivalServioLarge
              ? control.context.t(globalBookingMessages.labels.airportPickup)
              : control.context.t(globalBookingMessages.labels.airportDropoff)}
          </Type>
          <Type preset="text">
            <span style={{ fontWeight: 700, marginRight: 8 }}>
              {control.context.t(privateTransferMessages.info.pickupTime)}:
            </span>
            {control.context.t(globalBookingMessages.text.dateAtTime, {
              date: formatDateInUTC(
                transfer.arrival.time,
                'dd MMM yy',
                control.context.locale
              ),
              time: formatDateInUTC(
                transfer.arrival.time,
                'HH:mm',
                control.context.locale
              ),
            })}
          </Type>
        </PrivateTransferWrapper>
      )}

      {transfer.departure?.productId && (
        <PrivateTransferWrapper>
          <Type preset="text">
            {transfer.departure.productId ===
            PRODUCT_IDS.PrivateTransferDepartureServioSmall
              ? control.context.t(
                  privateTransferMessages.info.departureServioSmall
                )
              : transfer.departure.productId ===
                  PRODUCT_IDS.PrivateTransferDepartureServioMedium
                ? control.context.t(
                    privateTransferMessages.info.departureServioMedium
                  )
                : control.context.t(
                    privateTransferMessages.info.departureServioLargeOverview
                  )}
          </Type>
          <Type preset="text">
            <span style={{ fontWeight: 700, marginRight: 8 }}>
              {control.context.t(privateTransferMessages.info.dropOffLocation)}:
            </span>
            {transfer.departure.productId ===
              PRODUCT_IDS.PrivateTransferArrivalServioSmall ||
            transfer.departure.productId ===
              PRODUCT_IDS.PrivateTransferArrivalServioMedium ||
            transfer.departure.productId ===
              PRODUCT_IDS.PrivateTransferArrivalServioLarge
              ? control.context.t(globalBookingMessages.labels.airportPickup)
              : control.context.t(globalBookingMessages.labels.airportDropoff)}
          </Type>
          <Type preset="text">
            <span style={{ fontWeight: 700, marginRight: 8 }}>
              {control.context.t(privateTransferMessages.info.pickupTime)}:
            </span>
            {control.context.t(globalBookingMessages.text.dateAtTime, {
              date: formatDateInUTC(
                transfer.departure.time,
                'dd MMM yy',
                control.context.locale
              ),
              time: formatDateInUTC(
                transfer.departure.time,
                'HH:mm',
                control.context.locale
              ),
            })}
          </Type>
        </PrivateTransferWrapper>
      )}
    </Wrapper>
  )
}

const renderPrivateTransfer = (
  control: FlowControl,
  transfer: ConfirmationSummaryPrivateTransferItem
) => {
  return (
    <Wrapper>
      <Type preset="text" weight="bold" case="uppercase" bottom={1}>
        {control.context.t(globalBookingMessages.labels.summary)}
      </Type>
      {transfer.arrival?.productId && (
        <PrivateTransferWrapper>
          <Type preset="text">
            {transfer.arrival.productId ===
            PRODUCT_IDS.PrivateTransferArrivalSmall
              ? control.context.t(
                  privateTransferMessages.info.arrivalSmallOverview
                )
              : control.context.t(
                  privateTransferMessages.info.arrivalLargeOverview
                )}
          </Type>
          <Type preset="text">
            <span style={{ fontWeight: 700, marginRight: 8 }}>
              {control.context.t(privateTransferMessages.info.pickupLocation)}:
            </span>
            {transfer.arrival.productId ===
              PRODUCT_IDS.PrivateTransferArrivalSmall ||
            transfer.arrival.productId ===
              PRODUCT_IDS.PrivateTransferArrivalLarge
              ? control.context.t(globalBookingMessages.labels.airportPickup)
              : control.context.t(globalBookingMessages.labels.airportDropoff)}
          </Type>
          <Type preset="text">
            <span style={{ fontWeight: 700, marginRight: 8 }}>
              {control.context.t(privateTransferMessages.info.pickupTime)}:
            </span>
            {control.context.t(globalBookingMessages.text.dateAtTime, {
              date: formatDateInUTC(
                transfer.arrival.time,
                'dd MMM yy',
                control.context.locale
              ),
              time: formatDateInUTC(
                transfer.arrival.time,
                'HH:mm',
                control.context.locale
              ),
            })}
          </Type>
        </PrivateTransferWrapper>
      )}
      {transfer.departure?.productId && (
        <PrivateTransferWrapper>
          <Type preset="text">
            {transfer.departure.productId ===
            PRODUCT_IDS.PrivateTransferDepartureSmall
              ? control.context.t(
                  privateTransferMessages.info.departureSmallOverview
                )
              : control.context.t(
                  privateTransferMessages.info.departureLargeOverview
                )}
          </Type>
          <Type preset="text">
            <span style={{ fontWeight: 700, marginRight: 8 }}>
              {control.context.t(privateTransferMessages.info.dropOffLocation)}:
            </span>
            {transfer.departure.productId ===
              PRODUCT_IDS.PrivateTransferArrivalSmall ||
            transfer.departure.productId ===
              PRODUCT_IDS.PrivateTransferArrivalLarge
              ? control.context.t(globalBookingMessages.labels.airportPickup)
              : control.context.t(globalBookingMessages.labels.airportDropoff)}
          </Type>
          <Type preset="text">
            <span style={{ fontWeight: 700, marginRight: 8 }}>
              {control.context.t(privateTransferMessages.info.pickupTime)}:
            </span>
            {control.context.t(globalBookingMessages.text.dateAtTime, {
              date: formatDateInUTC(
                transfer.departure.time,
                'dd MMM yy',
                control.context.locale
              ),
              time: formatDateInUTC(
                transfer.departure.time,
                'HH:mm',
                control.context.locale
              ),
            })}
          </Type>
        </PrivateTransferWrapper>
      )}
    </Wrapper>
  )
}

const renderActivities = (
  control: FlowControl,
  activities: ConfirmationSummaryActivity[]
) => {
  return (
    <Wrapper>
      <Type preset="text" weight="bold" case="uppercase" bottom={1}>
        {control.context.t(globalBookingMessages.labels.summary)}
      </Type>
      {activities.map(activity => (
        <ActivityWrapper key={activity.productId}>
          {isElementIncludedInArray(
            massageAndFloatProductIds,
            activity.productId
          ) && (
            <Type preset="text">
              <span style={{ fontWeight: 700, marginRight: 8 }}>
                {control.context.t(
                  globalBookingMessages.labels.typeOfActivity,
                  {
                    activity: isElementIncludedInArray(
                      massageProductIds,
                      activity.productId
                    )
                      ? control.context.t(globalBookingMessages.labels.massage)
                      : control.context.t(
                          globalBookingMessages.labels.floatTherapy
                        ),
                  }
                )}
                :
              </span>
              {productIdsMessages?.[activity?.productId]
                ? control.context.t(productIdsMessages?.[activity?.productId])
                : activity?.title}
            </Type>
          )}
          <Type preset="text">
            <span style={{ fontWeight: 700, marginRight: 8 }}>
              {control.context.t(globalBookingMessages.labels.numberOfGuests)}:
            </span>
            {activity.numberOfPersons}
          </Type>
          <Type preset="text">
            <span style={{ fontWeight: 700, marginRight: 8 }}>
              {control.context.t(globalBookingMessages.labels.date)}:
            </span>
            {formatDateInUTC(
              activity.reservationDate,
              'dd MMM yy',
              control.context.locale
            )}
          </Type>
          <Type preset="text">
            <span style={{ fontWeight: 700, marginRight: 8 }}>
              {control.context.t(globalBookingMessages.labels.time)}:
            </span>
            {activity.time}
          </Type>
        </ActivityWrapper>
      ))}
    </Wrapper>
  )
}

export const ConfirmationSummaryField = ({
  control,
  ...props
}: ConfirmationSummaryFieldProps) => {
  const confirmationProps = getFlowValue(props, control)

  if (confirmationProps.type === 'hotel') {
    const rooms = getFlowValue(confirmationProps.rooms, control)
    return renderHotel(control, rooms)
  } else if (confirmationProps.type === 'spa') {
    const spa = getFlowValue(confirmationProps.spa, control)
    return renderSpa(control, spa)
  } else if (confirmationProps.type === 'products') {
    const products = getFlowValue(confirmationProps.products, control)
    return renderProducts(control, products)
  } else if (confirmationProps.type === 'activities') {
    const activities = getFlowValue(confirmationProps.activities, control)
    return renderActivities(control, activities)
  } else if (confirmationProps.type === 'privateTransfer') {
    const privateTransfer = getFlowValue(
      confirmationProps.privateTransfer,
      control
    )
    // Check if we are dealing with Servio private transfer
    if (
      privateTransferServioIds.includes(
        privateTransfer.arrival?.productId as any
      ) ||
      privateTransferServioIds.includes(
        privateTransfer.departure?.productId as any
      )
    ) {
      return renderPrivateTransferServio(control, privateTransfer)
    }
    return renderPrivateTransfer(control, privateTransfer)
  }
}
