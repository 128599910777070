import { add } from 'date-fns/add'

import type { SubFlowBookingInfo } from 'bl-flows-core'

// This function returns the list of dates that a booking covers for activities
// so if a booking is from 1. jan to 4. jan, this will return an array of 1., 2., 3. jan
// 4. jan would not be included because activities can't be booked on checkout days
export const getBookingDates = (bookingInfo: SubFlowBookingInfo) => {
  const dates = []
  if (bookingInfo?.hotelDates) {
    // Reset the hours because we do not care about check-in hours when constructing available dates
    let currentDate = bookingInfo?.hotelDates?.arrivalDate
    // Checkout day bookings are not allowed,
    // so we create and push dates up until the checkout date
    while (currentDate < bookingInfo?.hotelDates?.departureDate) {
      dates.push(currentDate)
      currentDate = add(currentDate, { days: 1 })
    }
  } else {
    dates.push(bookingInfo?.entryDate)
  }

  return dates
}
