import { useEffect } from 'react'
import Skeleton from 'react-loading-skeleton'
import { useRouter } from 'next/router'
import {
  createSerializer,
  parseAsInteger,
  parseAsString,
  useQueryStates,
} from 'nuqs'
import { useTheme } from 'styled-components'
import styled from 'styled-components'

import { Pagination } from 'bl-common/src/booking/Pagination/Pagination'
import { colors } from 'bl-common/src/constants/colors'
import { Amount } from 'bl-common/src/elements/Amount'
import { Type } from 'bl-common/src/elements/Typography/Typography'
import { useBreakpoints } from 'bl-common/src/hooks/useBreakpoints'
import { useCartContext } from 'bl-common/src/hooks/useCartContext'
import { theme } from 'bl-common/src/styles/theme'
import type { PartialBookingEngine } from 'bl-common/src/styles/types'
import { mediaObj } from 'bl-common/src/utils/media'
import { Property } from 'bl-graphql'

import { getInitialScreenIndex } from '../../renderers/FlowRenderer-utils'
import type { FlowBreadcrumbField, FlowComponent } from '../../types'
import { BackButton } from './styles'

type BreadcrumbFieldProps = FlowComponent & FlowBreadcrumbField['props']

const serialize = createSerializer({
  adults: parseAsInteger,
  arrivalDate: parseAsString,
  children: parseAsInteger,
  changingRooms: parseAsInteger,
})

export const TopBar = styled.div<{
  themeStyle?: PartialBookingEngine['breadcrumbField']['topBar']
}>(({ themeStyle }) => ({
  display: 'flex',
  justifyContent: 'space-between',

  ':has(button)': {
    borderBottom: `1px solid ${themeStyle?.borderColor ?? colors.lightGrey}`,
    marginBottom: theme.spacing[1],
    paddingBottom: theme.spacing[1],
    paddingTop: theme.spacing[0.5],
    background: themeStyle?.background ?? colors.backgroundWhite,

    [mediaObj.mlg]: {
      borderBottom: 'none',
      marginBottom: 0,
      paddingBottom: 0,
    },
  },

  marginLeft: theme.spacing[-2],
  marginRight: theme.spacing[-2],
  paddingLeft: theme.spacing[2],
  paddingRight: theme.spacing[2],
}))

const CartIcon = ({ color }) => (
  <svg viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="m18.202 9.322.767-5.971c.031-.335-.061-.7-.307-.944-.245-.244-.552-.427-.92-.427H7.611a.757.757 0 0 0-.767.762c0 .426.338.761.767.761h9.761l-.706 5.332H7.46L4.45.609A1.017 1.017 0 0 0 3.499 0H.767A.757.757 0 0 0 0 .762c0 .426.338.761.767.761h2.364l3.008 8.165v.06l.583 2.803.03.122c.246.64.86 1.066 1.536 1.066h9.116c.43 0 .767-.335.767-.761a.757.757 0 0 0-.767-.762H8.318c-.03 0-.061-.03-.092-.06l-.368-1.798h9.147c.583.03 1.105-.427 1.197-1.036ZM9.147 14.623c-.89 0-1.627.73-1.627 1.614 0 .884.737 1.615 1.627 1.615s1.627-.731 1.627-1.615c0-.883-.737-1.614-1.627-1.614Zm0 2.04a.405.405 0 0 1-.399-.395c0-.213.184-.396.399-.396.215 0 .399.183.399.396a.405.405 0 0 1-.399.396ZM15.808 14.623c-.89 0-1.627.73-1.627 1.614 0 .884.737 1.615 1.627 1.615s1.627-.731 1.627-1.615c0-.883-.737-1.614-1.627-1.614Zm0 2.04a.405.405 0 0 1-.4-.395c0-.213.185-.396.4-.396.215 0 .399.183.399.396a.405.405 0 0 1-.4.396Z"
      fill={color ?? colors.deepBlue}
    />
  </svg>
)

const CartButton = styled.button({
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  flexShrink: 0,
})

const CartWrapper = styled.div({
  height: theme.spacing[2],
  width: theme.spacing[2],
  marginRight: theme.spacing[1],

  svg: {
    height: theme.spacing[2],
    width: theme.spacing[2],
  },
})

const MobileTopBarCart = ({
  toggleCart,
  totalIsk,
  isCartLoading,
  themeStyle,
}: {
  toggleCart: () => void
  totalIsk?: number
  isCartLoading?: boolean
  themeStyle?: PartialBookingEngine['breadcrumbField']['mobileTopBarCart']
}) => {
  return (
    <CartButton type="button" onClick={toggleCart}>
      <CartWrapper>
        <CartIcon color={themeStyle?.iconColor} />
      </CartWrapper>

      {isCartLoading ? (
        <Type preset="textLarge">
          <Skeleton
            baseColor={themeStyle?.skeleton?.baseColor}
            highlightColor={themeStyle?.skeleton?.highlightColor}
            width={50}
          />
        </Type>
      ) : (
        <Type
          preset="textLarge"
          color={themeStyle?.textColor ?? colors.deepBlue}
          weight="bold"
        >
          <Amount value={totalIsk} />
        </Type>
      )}
    </CartButton>
  )
}

export const BreadcrumbField = ({
  control,
  showMobileCart,
  screenTheme,
  ...props
}: BreadcrumbFieldProps) => {
  const { push } = useRouter()
  const [{ adults, arrivalDate, changingRooms, children }] = useQueryStates({
    adults: parseAsInteger,
    arrivalDate: parseAsString,
    children: parseAsInteger,
    changingRooms: parseAsInteger,
  })

  const theme = useTheme()
  const themeStyle = theme?.bookingEngine?.[screenTheme]?.breadcrumbField

  const { mediaBmd } = useBreakpoints()

  const { loading: isCartLoading, cart } = useCartContext()
  const totalIsk = cart?.paymentAmount ?? cart?.totalAmount ?? 0

  useEffect(() => {
    if (!control.flow.state?.mobileCart?.isOpen) {
      control.flow.setState({
        mobileCart: {
          ...control.flow.state?.mobileCart,
          isOpen: false,
        },
      })
    }
  }, [])

  const toggleCart = () => {
    control.flow.setState({
      mobileCart: {
        ...control.flow.state?.mobileCart,
        isOpen: !control.flow.state?.mobileCart?.isOpen,
      },
    })
  }

  const initialScreenIndex = getInitialScreenIndex(control, 0)

  return (
    <>
      <TopBar themeStyle={themeStyle?.topBar}>
        {control.currentScreenIndex !== initialScreenIndex && (
          <BackButton
            color={themeStyle?.backButton?.color ?? colors.deepBlue}
            type="button"
            onClick={control.previousScreen}
          >
            <span aria-hidden="true">←</span>
          </BackButton>
        )}
        {/* Back button for package flow */}
        {control.currentScreenIndex === 0 &&
          control.flow.state?.previousPath && (
            <BackButton
              color={themeStyle?.backButton?.color ?? colors.deepBlue}
              type="button"
              onClick={() => {
                push({
                  pathname: control.flow.state?.previousPath,
                  search: serialize({
                    adults,
                    arrivalDate,
                    ...(children && { children }),
                    ...(changingRooms && { changingRooms }),
                  }),
                })
              }}
            >
              <span aria-hidden="true">←</span>
            </BackButton>
          )}
        {/* Back button for HBE */}
        {control.currentScreenIndex === 0 &&
          !!control.flow.setupHook?.hotelBookingFlow && (
            <BackButton
              color={themeStyle?.backButton?.color ?? colors.deepBlue}
              type="button"
              onClick={() => {
                const propertyName =
                  control.flow.state?.roomDetails?.property.toUpperCase()
                if (propertyName === Property.Kerlingarfjoll) {
                  if (control.context.locale === 'is') {
                    push('/gisting')
                  } else {
                    push('/accommodation')
                  }
                } else {
                  push(
                    propertyName === Property.Silica
                      ? '/accommodation/silica-hotel'
                      : '/accommodation/retreat-hotel'
                  )
                }
              }}
            >
              <span aria-hidden="true">←</span>
            </BackButton>
          )}

        {
          /* HACK: extremely ugly hack to display the top bar on the first step on mobile */
          control.currentScreenIndex === 0 && (
            <BackButton
              color={themeStyle?.backButton?.color ?? colors.deepBlue}
              type="button"
              style={{ cursor: 'default', pointerEvents: 'none' }}
              tabIndex={-1}
            >
              <span aria-hidden="true" style={{ opacity: 0 }}>
                ←
              </span>
            </BackButton>
          )
        }
        {!mediaBmd && showMobileCart && (
          <MobileTopBarCart
            toggleCart={toggleCart}
            totalIsk={totalIsk}
            isCartLoading={isCartLoading}
            themeStyle={themeStyle?.mobileTopBarCart}
          />
        )}
      </TopBar>
      <Pagination
        {...props}
        steps={Array.from(control.breadcrumbs.values())}
        currentRoute={control.currentScreenIndex.toString()}
        onClick={step => control.goToScreen(Number(step))}
        themeStyle={themeStyle?.pagination}
      />
    </>
  )
}
