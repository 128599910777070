import { type MutableRefObject, useRef, useState } from 'react'
import _set from 'lodash/set'

export const useStateRef = <T>(
  initialState: Record<string, T>
): [
  Record<string, T>,
  MutableRefObject<Record<string, T>>,
  (value: Record<string, T>, overwrite?: boolean) => void,
] => {
  const [state, setState] = useState<Record<string, T> | null>(initialState)
  const stateRef = useRef(state)

  const set = (value: Record<string, T>, overwrite = false) => {
    const updated = Object.keys(value).reduce(
      (arr, key) => {
        return _set(arr, key, value[key])
      },
      overwrite ? {} : stateRef.current
    )

    stateRef.current = updated

    setState({
      ...updated,
    })
  }

  return [state, stateRef, set]
}
