import {
  type AdmissionItem,
  CartItemType,
  type TransportationItem,
} from 'bl-graphql'
import { formatDateInUTC } from 'bl-utils/src/formatting/formatDate'
import { PRODUCT_IDS } from 'bl-utils/src/ProductIds'

const combineMassages = massages => {
  if (!massages) {
    return []
  }
  const combinedMassages = []
  massages.filter(m => {
    const isDuplicate = combinedMassages.find(
      element =>
        element.productId === m.productId &&
        element.meta.arrivalTime === m.meta.arrivalTime
    )

    if (!isDuplicate) {
      combinedMassages.push({ ...m, qty: 1 })

      return true
    } else {
      isDuplicate.qty += 1
      return false
    }
  })
  return combinedMassages
}

const getAdultQty = (item: AdmissionItem) => {
  if (item.productId === PRODUCT_IDS.SpaChild) {
    return 0
  }

  if (item.productId === PRODUCT_IDS.SpaRetreat) {
    return item.meta?.noOfPersons ?? 0
  }

  return item.qty
}

// We want to combine spa admissions so that we don't see children
// as a separete item in the cart
const combineSpaAdmissions = (admissions: AdmissionItem[]) => {
  if (!admissions) {
    return []
  }
  const combinedItems = []
  admissions.filter(m => {
    const duplicatedItem = combinedItems.find(
      element => element.meta.arrivalTime === m.meta.arrivalTime
    )

    if (!duplicatedItem) {
      combinedItems.push({
        ...m,
        qty: m.qty,
        adultQty: getAdultQty(m),
        childQty: m.productId === PRODUCT_IDS.SpaChild ? m.qty : 0,
        isSpaBooking: true,
        time: '10:00',
      })

      return true
    } else {
      if (m.productId === PRODUCT_IDS.SpaRetreat) {
        duplicatedItem.linePrice += m.linePrice
        duplicatedItem.totalLinePrice += m.totalLinePrice
      }
      duplicatedItem.qty += m.productId === PRODUCT_IDS.SpaChild ? 0 : m.qty
      duplicatedItem.adultQty += getAdultQty(m)
      duplicatedItem.childQty +=
        m.productId === PRODUCT_IDS.SpaChild ? m.qty : 0
      return false
    }
  })
  return combinedItems
}

export const orderAdmissions = cart => {
  const combinedAdmissions = combineSpaAdmissions(
    cart?.items.filter(item => item.type === CartItemType.Admission)
  )

  const productsInCart =
    cart?.items?.filter(item => item.type === CartItemType.Item) || []

  const transferInCart: TransportationItem = cart?.items?.find(
    item => item.type === CartItemType.Transfer
  )

  // We need to create seperate items for pickup and dropoff so it displays seperatly in the cart
  const transferPickup = transferInCart
    ? [
        {
          ...transferInCart,
          id: transferInCart?.id,
          meta: {
            // changing the name of inboundDepartureTime to arrivalTime so it gets sorted correctly in orderedItems
            arrivalTime: transferInCart?.meta?.inboundDepartureTime,
            inboundComponentId: transferInCart?.meta?.inboundComponentId,
            inboundPickupLocationId:
              transferInCart?.meta?.inboundPickupLocationId,
            direction: 'pickup',
          },
        },
      ]
    : []
  const transferDropoff = transferInCart
    ? [
        {
          ...transferInCart,
          id: transferInCart?.id,
          meta: {
            arrivalTime: transferInCart?.meta?.outboundDepartureTime,
            outboundComponentId: transferInCart?.meta?.outboundComponentId,
            outboundDropoffLocationId:
              transferInCart?.meta?.outboundDropoffLocationId,
            direction: 'dropoff',
          },
        },
      ]
    : []

  const lavaReservationsInCart =
    cart?.items
      ?.map(item => {
        const date =
          item?.meta?.arrivalTime &&
          formatDateInUTC(item?.meta?.arrivalTime, 'dd MMM')
        const time =
          item?.meta?.arrivalTime &&
          formatDateInUTC(item?.meta?.arrivalTime, 'HH:mm')
        return {
          ...item,
          roomId: item?.id?.split('-')[0],
          date,
          time,
        }
      })
      .filter(item => item.type === CartItemType.Restaurant) || []

  const combinedMassages = combineMassages(
    cart?.items.filter(item => item.type === CartItemType.Massage)
  )

  const massagesInCart =
    combinedMassages?.map(item => {
      const date =
        item?.meta?.arrivalTime &&
        formatDateInUTC(item?.meta?.arrivalTime, 'dd MMM')
      const time =
        item?.meta?.arrivalTime &&
        formatDateInUTC(item?.meta?.arrivalTime, 'HH:mm')

      return {
        ...item,
        date,
        time,
      }
    }) || []

  const privateTransfersInCart =
    cart?.items
      .filter(item => item.type === CartItemType.PrivateTransfer)
      ?.map(item => {
        const date =
          item?.meta?.arrivalTime &&
          formatDateInUTC(item?.meta?.arrivalTime, 'dd MMM')
        const time =
          item?.meta?.arrivalTime &&
          formatDateInUTC(item?.meta?.arrivalTime, 'HH:mm')

        return {
          ...item,
          date,
          time,
        }
      }) || []

  const orderedItems = combinedAdmissions.reduce((acc, admission) => {
    return [
      ...acc,
      admission,
      ...transferPickup,
      ...productsInCart,
      ...lavaReservationsInCart,
      ...massagesInCart,
      ...privateTransfersInCart,
      ...transferDropoff,
    ].sort((a, b) => {
      if (a?.meta?.arrivalTime === undefined) {
        return -1
      }
      if (b?.meta?.arrivalTime === undefined) {
        return 1
      }
      const aDate = new Date(a?.meta?.arrivalTime)
      const bDate = new Date(b?.meta?.arrivalTime)

      if (aDate < bDate) {
        return -1
      }
      if (aDate > bDate) {
        return 1
      }
      return 0
    })
  }, [])

  return orderedItems
}
