import styled, { css, useTheme } from 'styled-components'

import { Spinner } from 'bl-common/src/elements/Spinner'
import { SpinnerWrapper } from 'bl-common/src/elements/SpinnerWrapper'
import type {
  BookingEngineScreenThemes,
  ScreenTheme,
} from 'bl-common/src/styles/types'

import type { FlowControl, FlowField } from '../types'
import type { AtLeastOne } from '../types/typeUtils'
import { FieldRenderer } from './FieldRenderer'

export const Container = styled.div<{
  screentheme: ScreenTheme
  screenthemes: BookingEngineScreenThemes
  columnStyle?: boolean
}>`
  display: flex;
  flex-direction: ${props => (props.columnStyle ? 'column' : 'row')};
  flex-wrap: ${props => (props.columnStyle ? 'nowrap' : 'wrap')};
  align-items: ${props => (props.columnStyle ? 'center' : 'initial')};
  justify-content: ${props =>
    props.columnStyle ? 'flex-start' : 'space-between'};
  position: relative;
  min-height: ${props => props.columnStyle && '100%'};
  flex-shrink: ${props => props.columnStyle && 0};

  ${({ screentheme, screenthemes }) =>
    screentheme &&
    screenthemes[screentheme] &&
    css`
      background: ${screenthemes[screentheme].background};
      color: ${screenthemes[screentheme].color || 'inherit'};
    `};
`

type RenderFields = (
  fields: AtLeastOne<FlowField>,
  control: FlowControl,
  theme: ScreenTheme,
  renderContainer?: boolean,
  columnStyle?: boolean
) => React.ReactNode

export const renderFields: RenderFields = (
  fields,
  control,
  theme,
  renderContainer = true,
  columnStyle
) => {
  const workspaceTheme = useTheme()
  const screenThemes = workspaceTheme?.bookingEngineScreenThemes

  if (control.screen.setupHook?.isLoading) {
    return (
      <SpinnerWrapper verticalCenter>
        <Spinner shouldAnimate />
      </SpinnerWrapper>
    )
  }

  const fieldComponents = fields.map((field, index) => (
    <FieldRenderer
      key={index}
      control={control}
      item={field}
      screenTheme={theme}
      columnStyle={columnStyle}
    />
  ))

  return renderContainer ? (
    <Container
      screentheme={theme}
      screenthemes={screenThemes}
      columnStyle={columnStyle}
    >
      {fieldComponents}
    </Container>
  ) : (
    <Container
      screentheme={undefined}
      screenthemes={screenThemes}
      columnStyle={columnStyle}
    >
      {fieldComponents}
    </Container>
  )
}
