import { useTheme } from 'styled-components'

import { Drawer } from 'bl-common/src/units/Drawer'

import type { FlowComponent, FlowDrawerField } from '../../types'
import { getFlowValue } from '../../utils'

type DrawerFieldProps = FlowComponent &
  FlowDrawerField['props'] & { children: React.ReactNode }

export const DrawerField = ({ screenTheme, ...props }: DrawerFieldProps) => {
  const theme = useTheme()
  const themeSyle = theme?.bookingEngine?.[screenTheme]

  return (
    <Drawer
      show={getFlowValue(props.isOpen, props.control)}
      onDismiss={() => props?.onHide('', props.control)}
      themeStyle={themeSyle}
    >
      {props.children}
    </Drawer>
  )
}
