import { motion } from 'framer-motion'
import styled, { css } from 'styled-components'

import { media } from 'bl-common/src/utils/media'

import type { WithScreenTheme } from '../../types/typeUtils'

export const easing = [0.24, 0.28, 0.63, 1]

export const layoutAnimation = {
  animate: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 0.5,
      ease: easing,
    },
  },
  initial: { y: 15, opacity: 0 },
}

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  min-height: 100%;
  min-height: 100dvh;

  ${media.bmd(css`
    min-height: unset;
    height: 100%;
  `)}
`

interface InnerContainerProps {
  desktopwidth: number
}

interface LeftContainerProps extends InnerContainerProps, WithScreenTheme {}

export const LeftContainer = styled(motion.div)<LeftContainerProps>`
  ${props => css`
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    width: 100%;

    padding: ${({ theme }) =>
      `${theme.spacing[0.5]} ${theme.spacing[2]}  ${theme.spacing[3]}`};
    min-height: 100%;
    overflow-y: visible;

    ${media.bmd(css`
      width: ${props.desktopwidth}%;
      overflow-y: auto;
    `)}

    ${media.mlg(css`
      padding: ${({ theme }) =>
        `${theme.spacing[1.5]} ${theme.spacing[5]} ${theme.spacing[3]}`};
    `)}

  ${
    props.screentheme &&
    props.screenthemes[props.screentheme] &&
    css`
      background: ${
        props.screenthemes[props.screentheme].background || 'inherit'
      };
      color: ${props.screenthemes[props.screentheme].color || 'inherit'};
    `
  };
  `}
`

export const RightContainer = styled.div<InnerContainerProps>`
  ${props => css`
    display: none;

    ${media.bmd(css`
      position: relative;
      width: ${props.desktopwidth}%;
      min-height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: 'space-between';
      padding: ${({ theme }) =>
        `${theme.spacing[2]} ${theme.spacing[1]} ${theme.spacing[3]}`};
    `)}

    ${media.mlg(css`
      padding: ${({ theme }) =>
        `${theme.spacing[2]} ${theme.spacing[1.5]} ${theme.spacing[3]}`};
    `)}
  `}
`

export const ImageContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
`
