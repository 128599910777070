import type { MassageState } from '../subflows'

export const filterMassages = (massages: MassageState[]) => {
  const filteredMassages: MassageState[] = []
  massages.forEach(m => {
    const isDuplicate = filteredMassages.find(
      element =>
        element.productNo === m.productNo &&
        element.time === m.time &&
        element.date === m.date
    )

    if (!isDuplicate) {
      filteredMassages.push({ ...m, quantity: m.quantity || 1 })

      return true
    } else {
      isDuplicate.quantity += 1
      return false
    }
  })
  return filteredMassages
}
